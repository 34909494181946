import React, { useState } from "react"
import Divider from "@/components/Divider/index"
import ActionDialog from "@/components/ActionDialog/index"

import { Grid, InputLabel, TextField } from "@material-ui/core"
import { ClientProfileInfoData } from "@/protocols/clientCatalog"
import CustomFieldsList from "@/components/CustomFieldsList"
import { CustomFieldValue } from "@/pages/Admin/ClientCatalog/ClientProfileInfo/CustomFields"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ErrorType } from "@/hooks/useValidation"

type ClientCustomFieldsModalContentProps = {
	customFieldGroups: ClientProfileInfoData["customFieldGroups"]
	clientId: number
	onDataChange: (id: number, data: Partial<ClientProfileInfoData>) => void
}

const ClientCustomFieldsModalContent: React.FC<ClientCustomFieldsModalContentProps> = (props) => {
	const {
		customFieldGroups,
		clientId,
		onDataChange
	} = props

	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [editedCustomField, setEditedCustomField] = useState<CustomFieldValue>({} as CustomFieldValue)
	const [loading, setLoading] = useState(false)

	const handleEditCustomFieldValue = (value: CustomFieldValue) => {
		setOpenEditDialog(true)
		setEditedCustomField(value)
	}

	const handleCloseEditDialog = () => {
		setOpenEditDialog(false)
	}

	const handleChangeCustomFieldValue = async (value: string | number, field: keyof CustomFieldValue) => {
		setEditedCustomField(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const handleUpdateCustomFieldValue = async () => {
		setLoading(true)
		try {
			await ApiService.post(`/custom-fields/${editedCustomField.id}/client/${clientId}/custom-field-value`, {
				value: editedCustomField.value
			})
			onDataChange(clientId, {
				customFieldGroups: customFieldGroups.map((customFieldGroup) => {
					customFieldGroup.customFields = customFieldGroup.customFields.map((field) => {
						if (field.id === editedCustomField.id) {
							field.value = editedCustomField.value
						}
						return field
					})
					return customFieldGroup
				})
			})
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
		handleCloseEditDialog()
	}

	return (
		<>
			<CustomFieldsList customFieldGroups={customFieldGroups} handleEditCustomFieldValue={handleEditCustomFieldValue} />

			<ActionDialog
				onClose={handleCloseEditDialog}
				openDialog={openEditDialog}
				title={"ALTERAR VARIAVEL"}
				fullWidth
				saveText={"SALVAR"}
				cancelText="CANCELAR"
				onSave={handleUpdateCustomFieldValue}
				loading={loading}
			>
				<Grid container>
					<Grid xs={12}>
						<Grid
							item
							xs
						>
							<InputLabel>Nome da variável:</InputLabel>
							<Divider size={2} orientation="horizontal" />

							<TextField
								name="custom_field_value"
								variant="outlined"
								placeholder={"Digite o novo valor da variável"}
								fullWidth
								defaultValue={editedCustomField.value}
								value={editedCustomField.value}
								onChange={({ target }) => handleChangeCustomFieldValue(target.value, "value")}
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default ClientCustomFieldsModalContent
