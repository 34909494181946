import { Grid, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import React from "react"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)"
	},
	actions: {
		transform: "scale(1)"
	},
	title: {
		marginTop: theme.spacing(1)
	},
	notes: {
		marginTop: theme.spacing(1)
	},
	note: {
		transform: "scale(1)",
		marginTop: "10px",
		width: "100%"
	},
	spacing: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1)
	}
}))

const ClientCustomFieldsSkeleton: React.FC = () => {
	const classes = useStyles()

	return (
		<Grid container direction="column" className={classes.spacing}>
			<Grid container alignItems="center" justifyContent="space-between" className={classes.title}>
				<Grid item xs>
					<Skeleton className={classes.skeleton} width={160} height={20} />
				</Grid>
				<Skeleton className={classes.actions} width={30} height={30} />
			</Grid>
			<Grid direction="column" alignItems="center" className={classes.notes}>
				<Skeleton className={classes.note} width={80} height={20} />
				<Skeleton className={classes.note} width={130} height={20} />
				<Skeleton className={classes.note} width={130} height={20} />
				<Skeleton className={classes.note} height={1} />
				<Skeleton className={classes.note} width={80} height={20} />
				<Skeleton className={classes.note} width={130} height={20} />
				<Skeleton className={classes.note} width={130} height={20} />
				<Skeleton className={classes.note} height={50} />
			</Grid>
		</Grid>
	)
}

export default ClientCustomFieldsSkeleton
