import { makeStyles } from "@material-ui/core"

import { AlertContainerStyleProps } from "@/components/AlertContainer"

import colors from "@/styles/colors"

type StylesProps = AlertContainerStyleProps

const useStyles = (props: StylesProps) => makeStyles({
	container: {
		borderLeft: "4px solid",
		borderLeftColor: props.borderLeftColor,
		borderRadius: "8px",
		padding: "16px",
		backgroundColor: props.containerBackgroundColor,
		gap: "12px",
		boxShadow: `2px 5px 10px 8px ${colors.unrelated["0000000D"]}`
	},
	title: {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "24px",
		color: props.titleColor
	}
})(props)

export default useStyles
