import React, { useEffect, useState } from "react"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientCustomFields/CustomFieldsArea/styles"

import { ClientCatalogPagination, ClientProfileInfoData } from "@/protocols/clientCatalog"
import { Card, Grid, Typography, Divider as MuiDivider, Collapse, IconButton, Button } from "@material-ui/core"
import {
	KeyboardArrowDown as ArrowDown,
	KeyboardArrowUp as ArrowUp
} from "@material-ui/icons"
import { OnDataChangeFn } from "@/pages/Admin/ClientCatalog/ClientProfileInfo"

import ApiService from "@/services/Api"
import useCustomStyles from "@/styles/custom"
import { InfoDialog, Loading } from "@/components"
import ClientCustomFieldsModalContent from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientCustomFields/ClientCustomFieldButtonModal"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ErrorType } from "@/hooks/useValidation"
import ClientCustomFieldsSkeleton from "@/skeletons/ClientCustomFields"
import { useGlobalStateStore } from "@/store/GlobalState"

type ClientCustomFieldsAreaProps = {
	clientId: number
	loading: boolean
}

const ClientCustomFieldsArea: React.FC<ClientCustomFieldsAreaProps> = (props) => {
	const {
		clientId,
		loading
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [customFieldGroups, setCustomFieldGroups] = useState<ClientProfileInfoData["customFieldGroups"]>([])
	const [clientCatalog, setClientCatalog] = useState<ClientCatalogPagination>({} as ClientCatalogPagination)
	const [loadingProfileInfoData, setLoadingProfileInfoData] = useState(false)

	const globalStateStore = useGlobalStateStore()
	const isManager = globalStateStore.instance.user_in_instance_role.name === "Gestor"

	const getClientProfileInfoData = async () => {
		try {
			setLoadingProfileInfoData(true)
			const { data } = await ApiService.get<ClientProfileInfoData>(`/clients/${clientId}/profile`)
			setCustomFieldGroups(data.customFieldGroups)
			setLoadingProfileInfoData(false)
		} catch (err) {
			ErrorHandlerService.handle(err as ErrorType)
		}
	}

	useEffect(() => {
		getClientProfileInfoData()
	}, [])

	const [openCustomFields, setOpenCustomFields] = useState<boolean>(() => {
		const openCustomFields = localStorage.getItem("openCustomFields")

		if (openCustomFields) {
			return JSON.parse(openCustomFields)
		}

		return false
	})

	const [openDialog, setOpenDialog] = useState(false)

	const handleOpenCustomFields = () => {
		setOpenCustomFields((prevState) => {
			const newState = !prevState
			localStorage.setItem("openCustomFields", JSON.stringify(newState))
			return newState
		})
	}

	const onDataChange: OnDataChangeFn = (id, data) => {
		const updatedClients = clientCatalog?.rows?.map(client => {
			if (client.id === id) {
				if (data.contacts?.email) {
					client.contacts = client.contacts?.map((contact) => {
						if (contact.channel_type === "email") {
							contact.data.email = data.contacts?.email as string
						}
						return contact
					})
				}
				if ("optIn" in data) {
					client.accepts_automatic_messages = data.optIn as boolean
				}
				if ("nickname" in data) {
					client.nickname = data.nickname as string
				}
				if ("tags" in data) {
					client.tags = data.tags
				}
			}

			return client
		})
		const updatedClientCatalog = {
			...clientCatalog,
			rows: updatedClients
		} as ClientCatalogPagination

		setClientCatalog(updatedClientCatalog)
	}

	return (
		<>
			<Card className={classes.customFieldsCard}>
				<Loading loading={loading || loadingProfileInfoData} customLoadingElement={<ClientCustomFieldsSkeleton />}>
					<Grid container direction="column">
						<Grid container direction="column" className={classes.customFieldTitle} spacing={1}>
							<Grid style={{ display: "flex", justifyContent: "space-between" }}>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: "15px" }}
								>
									Campos Personalizados
								</Typography>

								<IconButton onClick={handleOpenCustomFields}>
									{openCustomFields ? (
										<ArrowUp />
									) : (
										<ArrowDown />
									)}
								</IconButton>
							</Grid>

							<Collapse in={openCustomFields}>
								{
									customFieldGroups.length > 0 ? (
										<Grid direction="column">
											<Grid item>
												{customFieldGroups.map((group, index) => {
													const isNotLastGroup = index + 1 !== customFieldGroups.length

													return (
														<Grid style={{ marginBottom: "10px" }} key={index}>
															<Typography
																variant="h2"
																color="textPrimary"
																style={{ fontSize: "15px" }}
															>
																{group.displayName.toUpperCase()}
															</Typography>

															{group.customFields.map((field, index) => (
																<Grid key={index}>
																	<Typography
																		variant="overline"
																	>
																		<b>{field.displayName}</b>: {field.value}
																	</Typography>
																</Grid>
															))}
															{
																isNotLastGroup && (
																	<MuiDivider style={{ marginTop: "10px" }} />
																)
															}
														</Grid>
													)
												})}
											</Grid>
											{
												isManager && (
													<Grid item style={{ marginTop: "24px" }}>
														<Button className={customClasses.secondaryActionButton} onClick={() => setOpenDialog(true)} style={{ width: "100%" }}>
															Gerenciar Campos Personalizados
														</Button>
													</Grid>
												)
											}
										</Grid>

									) : (
										<Grid container justifyContent="center">
											<Typography variant="body2" align="center" style={{ maxWidth: 200 }}>
										Nenhum campo personalizado foi criado até o momento.
											</Typography>
										</Grid>
									)
								}
							</Collapse>
						</Grid>
					</Grid>
				</Loading>
			</Card>

			<InfoDialog
				openDialog={openDialog}
				title="Gerenciar Campos Personalizados"
				onClose={() => setOpenDialog(false)}
				maxWidth="md"
			>
				<ClientCustomFieldsModalContent customFieldGroups={customFieldGroups} clientId={clientId} onDataChange={onDataChange} />
			</InfoDialog>
		</>
	)
}

export default ClientCustomFieldsArea
