import { getDifferenceInHours } from "@/utils/time"
import { WABAChannelChatCommunicationPermissionStatus } from "@/@integrations/WABA/protocols/wabaChannelChatCommunicationPermission"

type useWABAChatCommunicationPermissionProps = {
	status: WABAChannelChatCommunicationPermissionStatus
	scheduledPauseAt?: Date
}

export const useWABAChatCommunicationPermission = (props: useWABAChatCommunicationPermissionProps) => {
	const {
		status,
		scheduledPauseAt
	} = props

	const formattedSchedulePausedDate = new Date(scheduledPauseAt as Date)
	const canShowPauseDate = status === "active" && scheduledPauseAt
	const canShowCommunicationTimeoutWarning = canShowPauseDate && getDifferenceInHours(formattedSchedulePausedDate, new Date()) <= 4

	const getStatusMessage = () => {
		const wabaChatCommunicationPermissionStatusToStatusMessage: Record<WABAChannelChatCommunicationPermissionStatus, string> = {
			active: "Permissão de comunicação da WABA ativa",
			paused: "Permissão de comunicação da WABA pausada"
		}

		return wabaChatCommunicationPermissionStatusToStatusMessage[status]
	}

	return {
		canShowCommunicationTimeoutWarning,
		formattedSchedulePausedDate,
		canShowPauseDate,
		statusMessage: getStatusMessage()
	}
}
