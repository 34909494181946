import { makeStyles } from "@material-ui/core"

import { UpsellAttendantButtonStyleProps } from "@/pages/Admin/Attendant/components/UpsellAttendantAlertContainer/index"

type StylesProps = UpsellAttendantButtonStyleProps

const useStyles = (props: StylesProps) => makeStyles({
	"@keyframes pulse": {
		"0%": {
			boxShadow: `0 0 0 0 ${props.hoverColor}`
		},
		"70%": {
			boxShadow: "0 0 0 2px"
		},
		"100%": {
			boxShadow: "0 0 0 0"
		}
	},
	buttonPulseEffect: {
		animation: "$pulse 2s infinite",
		"&:hover": {
			animation: "none"
		}
	},
	button: {
		fontSize: "16px",
		fontWeight: 500,
		padding: 16,
		backgroundColor: props.backgroundColor,
		border: `1px solid ${props.borderColor}`,
		color: props.textColor,
		"&:hover": {
			backgroundColor: props.hoverColor
		}
	}
})(props)

export default useStyles
