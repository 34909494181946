import React from "react"
import { ShortChatBotFlowBlock, ShortChatBotTrigger, ShortFlow } from "@/protocols/chatBotConstructor"
import { BlockErrorContent, ChatBotFlowError, FlowError } from "@/protocols/chatBotFlow"
import { ChatBotFlowBlockRule, ChatBotFlowBlockType, ChatBotFlowTriggerType } from "@/protocols/chatBot"

import useChatBotFlowConstructorStore from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/hooks/useChatBotFlowConstructorStore"
import { getConsolidatedBlockTheme } from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/utils/blockTheme"

import { useReactFlow } from "reactflow"

import { PlayArrow as TriggerNodeIcon } from "@material-ui/icons"

import colors from "@/styles/colors"

type UseChatBotFlowValidationResponse = {
	validateFlow: (chatBotFlow: ShortFlow) => FlowError
	blockAlreadyExistInFlow: (blockType: ChatBotFlowBlockType, chatBotFlow: ShortFlow) => boolean
	triggerAlreadyExistInFlow: (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], chatBotFlowTrigger: ChatBotFlowTriggerType) => boolean
}

type BlockValidator = (block: ShortChatBotFlowBlock) => {
	errorType?: ChatBotFlowError
} | null

const useChatBotFlowValidation = (): UseChatBotFlowValidationResponse => {
	const chatBotFlowConstructorStore = useChatBotFlowConstructorStore()

	const reactFlow = useReactFlow()

	const filterChatBotFlowByStatus = (chatBotFlow: ShortFlow, status: ShortChatBotFlowBlock["status"]) => {
		const newChatBotFlowBlocks = chatBotFlow.chat_bot_flow_blocks.filter(block => block.status !== status)

		const filteredChatBotFlow: ShortFlow = {
			...chatBotFlow,
			chat_bot_flow_blocks: [
				...newChatBotFlowBlocks
			]
		}

		return filteredChatBotFlow
	}

	const filterChatBotFlowTriggerByStatus = (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], status: ShortChatBotTrigger["status"]) => {
		const filteredChatBotFlowTriggers = chatBotFlowTriggers.filter(trigger => trigger.status !== status)

		return filteredChatBotFlowTriggers
	}

	const getConsolidatedBlockByBlockType = (blockType: ChatBotFlowBlockType) => {
		const blockCategoryType = chatBotFlowConstructorStore.getBlockCategoryType(blockType)
		return getConsolidatedBlockTheme(blockType, blockCategoryType)
	}

	const finishFlowBlockAlreadyExist = (chatBotFlow: ShortFlow): BlockErrorContent | undefined => {
		const finishFlowBlock = chatBotFlow.chat_bot_flow_blocks?.find(block => block.chat_bot_block_type === "finish-flow")

		if (finishFlowBlock) {
			return
		}

		const consolidatedBlock = getConsolidatedBlockByBlockType("finish-flow")

		return {
			errors: ["finish_flow_block_not_find"],
			block: {
				icon: consolidatedBlock.icon,
				color: consolidatedBlock.color,
				name: "Finalizar bot"
			},
			type: "flow_error"
		}
	}

	const blockAlreadyExistInFlow = (blockType: ChatBotFlowBlockType, chatBotFlow: ShortFlow): boolean => {
		const filteredChatBotFlow = filterChatBotFlowByStatus(chatBotFlow, "DELETED")

		const block = filteredChatBotFlow.chat_bot_flow_blocks?.find(block => block.chat_bot_block_type === blockType)

		return !!block
	}

	const triggerAlreadyExistInFlow = (chatBotFlowTriggers: ShortFlow["chat_bot_triggers"], chatBotFlowTrigger: ChatBotFlowTriggerType): boolean => {
		const filteredChatBotFlowTriggers = filterChatBotFlowTriggerByStatus(chatBotFlowTriggers, "DELETED")

		const trigger = filteredChatBotFlowTriggers?.find(trigger => trigger.type === chatBotFlowTrigger)

		return !!trigger
	}

	const getNodeByChatBotFlowBlockId = (chatBotFlowBlockId: number) => {
		const nodes = reactFlow.getNodes()
		return nodes.find(node => node.data.chatBotFlowBlockId === chatBotFlowBlockId)
	}

	const isMissingAnyNextConnectionInFlowBlock = (nodeId: string, rulesConnections: number): boolean => {
		const connections = reactFlow.getEdges()

		const nextConnections = connections.filter(connection => connection.source === nodeId)
		return nextConnections.length !== rulesConnections
	}

	const isMissingAnyPreviousConnectionInFlowBlock = (nodeId: string): boolean => {
		const connections = reactFlow.getEdges()

		const previousConnection = connections.find(connection => connection.target === nodeId)

		return !previousConnection
	}

	const hasContentToSend = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		if (block?.content?.messagesToSend?.length > 0) {
			return null
		}
		return {
			errorType: "block_missing_content"
		}
	}

	const hasAttendanceTransferData = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		const transferData = block?.content?.attendanceTransferData
		if (transferData) {
			const specificTypes = ["random-team-attendant", "specific-attendant", "specific-team"]
			const hasSpecificTypes = specificTypes.includes(transferData?.type)
			const hasSelection = transferData?.teamId !== null || transferData?.userId !== null

			if (hasSpecificTypes && !hasSelection) {
				return {
					errorType: "transfer_block_missing_selection"
				}
			}
			return null
		}
		return {
			errorType: "transfer_block_missing_selection"
		}
	}

	const hasAtLeatOneAnswerForConditionalBlock = (rules: ChatBotFlowBlockRule[]): {errorType: ChatBotFlowError} | null => {
		if (rules.some(rule => rule.category === "continue-condition")) {
			return null
		}
		return {
			errorType: "multiple_condition_block_without_answer"
		}
	}

	const hasAttendanceSchedule = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		const { next_chat_bot_flow_block_rules } = block
		const hasContinueCondition = next_chat_bot_flow_block_rules.some(rule => rule.category === "continue-condition" && rule.validations.length > 0)
		if (hasContinueCondition) {
			return null
		}
		return {
			errorType: "attendace_schedule_missing_time"
		}
	}

	const hasLocationToSaveAnswer = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		const locationToSave = block?.content?.locationToSaveInput
		if (locationToSave && Object.keys(locationToSave).length > 0) {
			return null
		}
		return {
			errorType: "save_answer_block_missing_location"
		}
	}

	const hasGoToBot = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		if (block?.content?.goToChatBotFlowId) {
			return null
		}
		return {
			errorType: "go_to_block_missing_bot"
		}
	}

	const hasTagSelected = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		const hasTagSelected = block?.content?.clientTagsManagementData
		if (hasTagSelected && Object.keys(hasTagSelected?.actions).length > 0) {
			return null
		}
		return {
			errorType: "manage_client_tags_block_missing_tag_selection"
		}
	}

	const hasPathAdded = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		if (Object.keys(block?.next_chat_bot_flow_block_rules).length > 0) {
			return null
		}
		return {
			errorType: "random_path_flow_missing_path"
		}
	}

	const hasOptoutOptinOptionSelected = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		if (block?.content?.autoSendMessagesOption) {
			return null
		}
		return {
			errorType: "optout_optin_missing_selection"
		}
	}

	const hasUrlAdded = (block: ShortChatBotFlowBlock): {errorType: ChatBotFlowError} | null => {
		if (block?.content?.webhook?.url.trim() !== "") {
			return null
		}
		return {
			errorType: "webhook_block_missing_url"
		}
	}

	const hasCondition = (block: ShortChatBotFlowBlock) : {errorType: ChatBotFlowError} | null => {
		if (block.next_chat_bot_flow_block_rules.length <= 1) {
			return { errorType: "condition_block_missing_option" }
		}

		const hasCondition = block.next_chat_bot_flow_block_rules.some((nextChatBotFlow: ChatBotFlowBlockRule) =>
			nextChatBotFlow.category !== "exit-condition" && nextChatBotFlow.validations.length >= 1
		)

		return hasCondition ? null : { errorType: "condition_block_missing_option" }
	}

	const hasTagAddedToActiveCampaign = (block: ShortChatBotFlowBlock) : {errorType: ChatBotFlowError} | null => {
		if (block?.content?.activeCampaignTagName && block?.content?.activeCampaignTagName !== "") {
			return null
		}

		return {
			errorType: "active_campaign_missing_tag"
		}
	}

	const hasDealRegisteredToActiveCampaign = (block: ShortChatBotFlowBlock) : {errorType: ChatBotFlowError} | null => {
		if (block?.content?.deal?.pipeline && block?.content?.deal?.stage) {
			return null
		}

		return {
			errorType: "active_campaign_block_missing_deal"
		}
	}

	const blockTypeToBlockValidator = (key: ChatBotFlowBlockType): BlockValidator[] => {
		const validators: Record<ChatBotFlowBlockType, BlockValidator[]> = {
			"send-message": [
				(block) => hasContentToSend(block)
			],
			"ask-and-wait": [
				(block) => hasContentToSend(block)
			],
			"conditional-text": [
				(block) => hasContentToSend(block),
				(block) => hasAtLeatOneAnswerForConditionalBlock(block.next_chat_bot_flow_block_rules)
			],
			"attendance-schedule": [
				(block) => hasAttendanceSchedule(block)
			],
			"attendance-transfer": [
				(block) => hasAttendanceTransferData(block)
			],
			"validate-and-save-v2": [
				(block) => hasLocationToSaveAnswer(block)
			],
			"manage-client-tags": [
				(block) => hasTagSelected(block)
			],
			"go-to-flow": [
				(block) => hasGoToBot(block)
			],
			"random-path-flow": [
				(block) => hasPathAdded(block)
			],
			"optout-optin": [
				(block) => hasOptoutOptinOptionSelected(block)
			],
			"send-webhook": [
				(block) => hasUrlAdded(block)
			],
			condition: [
				(block) => hasCondition(block)
			],
			"register-contact-to-active-campaign": [],
			"register-tag-to-active-campaign": [
				(block) => hasTagAddedToActiveCampaign(block)
			],
			"register-deal-to-active-campaign": [
				(block) => hasDealRegisteredToActiveCampaign(block)
			],
			"finish-attendance": [],
			"assign-attendance": [],
			"assign-attendance-randomly": [],
			"assign-attendance-to-account-manager": [],
			"assign-attendance-to-general-queue": [],
			"restart-flow": [],
			"conditional-button": [],
			"conditional-menu": [],
			"save-input": [],
			"finish-flow": [],
			repeat: [],
			wait: [],
			"validate-and-save": [],
			"ai-agent-conversation": [],
			"send-waba-template-message-and-wait": [],
			"conditional-waba-button-template": []
		}
		return validators[key] || null
	}

	const validateBlock = (block: ShortChatBotFlowBlock): BlockErrorContent | void => {
		const blockErrors: ChatBotFlowError[] = []

		const node = getNodeByChatBotFlowBlockId(block.id)

		if (!node) return

		let isMissingAnyNextConnectionInFlowBlockResult = false

		const isMissingAnyPreviousConnectionInFlowBlockResult = isMissingAnyPreviousConnectionInFlowBlock(node.id)

		if (block.chat_bot_block_type !== "finish-flow") {
			isMissingAnyNextConnectionInFlowBlockResult = isMissingAnyNextConnectionInFlowBlock(node.id, block.next_chat_bot_flow_block_rules.length)
		}

		if (isMissingAnyPreviousConnectionInFlowBlockResult || isMissingAnyNextConnectionInFlowBlockResult) {
			blockErrors.push("block_without_mandatory_connection")
		}

		const validators = blockTypeToBlockValidator(block.chat_bot_block_type)
		if (validators) {
			validators.forEach((validator) => {
				const result = validator(block)
				if (result?.errorType) {
					blockErrors.push(result.errorType)
				}
			})
		}

		if (blockErrors.length > 0) {
			const consolidatedBlock = getConsolidatedBlockByBlockType(block.chat_bot_block_type)
			const node = getNodeByChatBotFlowBlockId(block.id)

			return {
				block: {
					nodeId: node?.id,
					icon: consolidatedBlock.icon,
					color: consolidatedBlock.color,
					name: node?.data.flowBlock.title
				},
				errors: blockErrors,
				type: "block_error"
			}
		}
	}

	const validateBlocks = (chatBotFlowBlocks: ShortChatBotFlowBlock[]): FlowError | void => {
		const blockErrors: FlowError = []

		chatBotFlowBlocks.forEach(block => {
			const validateBlockResult = validateBlock(block)

			if (validateBlockResult) {
				blockErrors.push(validateBlockResult)
			}
		})

		if (blockErrors.length > 0) {
			return blockErrors
		}
	}

	const validateTrigger = (): BlockErrorContent | undefined => {
		const nodes = reactFlow.getNodes()
		const triggerNode = nodes.find(node => node.type === "triggernode")

		const connections = reactFlow.getEdges()

		const nextConnections = connections.filter(connection => connection.source === triggerNode?.id)

		if (nextConnections.length > 0) {
			return
		}

		return {
			errors: ["block_without_mandatory_connection"],
			block: {
				icon: {
					Component: <TriggerNodeIcon />
				},
				color: {
					blockIcon: colors.palette.primary,
					blockHeaderBackground: colors.unrelated.F9F3FD,
					blockSelected: colors.unrelated.F79722
				},
				name: "Gatilho para acionar o bot",
				nodeId: triggerNode?.id
			},
			type: "block_error"
		}
	}

	const validateFlow = (chatBotFlow: ShortFlow): FlowError => {
		const flowErrors: FlowError = []

		const filteredChatBotFlow = filterChatBotFlowByStatus(chatBotFlow, "DELETED")

		const isFinishFlowBlockError = finishFlowBlockAlreadyExist(filteredChatBotFlow)

		if (isFinishFlowBlockError) {
			flowErrors.push(isFinishFlowBlockError)
		}

		const blockErrors = validateBlocks(filteredChatBotFlow.chat_bot_flow_blocks)

		if (blockErrors) {
			flowErrors.push(...blockErrors)
		}

		const triggerErrors = validateTrigger()

		if (triggerErrors) {
			flowErrors.push(triggerErrors)
		}

		chatBotFlowConstructorStore.setValidationErrorsCount(flowErrors.length)

		return flowErrors
	}

	return {
		validateFlow,
		blockAlreadyExistInFlow,
		triggerAlreadyExistInFlow
	}
}

export default useChatBotFlowValidation
