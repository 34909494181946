import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	gridContainer: {
		height: "100%",
		overflow: "auto",
		paddingBottom: "16px"
	},
	gridItemProfile: {
		padding: "0px 16px"
	}
})

export default useStyles
