import React, { useEffect } from "react"
import {
	Button,
	Grid,
	IconButton,
	MenuItem,
	Popover,
	Select,
	TextField,
	Typography
} from "@material-ui/core"

import {
	Divider,
	Notification
} from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import { ErrorType } from "@/hooks/useValidation"

import ErrorHandler from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import { saveSnooze } from "@/utils/snoozePersistence"
import {
	defaultSnoozeTime,
	getSnoozeTimeOptionContent,
	handleInputSnoozeToMilliSecond,
	getSnoozeTime,
	snoozeMenuOptions
} from "@/utils/snooze"
import { fullDatetime } from "@/utils/time"

import {
	AccessAlarm as SnoozeIcon,
	Close as CancelScheduleIcon
} from "@material-ui/icons"

import { SnoozeActionButton, SnoozeTime, SnoozeTimeOptions } from "@/protocols/snooze"
import { SchedulesInInboxBase } from "@/protocols/schedule"

import colors from "@/styles/colors"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/styles"

type SnoozeProps = {
	handleCancelSnooze: () => void
}

const Snooze: React.FC<SnoozeProps> = (props) => {
	const {
		handleCancelSnooze
	} = props

	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()

	const [snoozeTime, setSnoozeTime] = React.useState<SnoozeTime>(defaultSnoozeTime)
	const [snoozeOptionsMenuAnchorEl, setSnoozeOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const snoozeOptionsMenuIsOpen = Boolean(snoozeOptionsMenuAnchorEl)

	const isSnoozeCurrentChatStatus = chatGlobalStateStore.chat.current?.status === "snooze"
	const inboxChannelChatId = chatGlobalStateStore.chat?.current?.id as number
	const instanceId = globalStateStore.instance.instance_id
	const userId = globalStateStore.user.id
	const scheduleTime = chatGlobalStateStore.chat.current?.schedule?.time

	const handleSnoozeOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setSnoozeOptionsMenuAnchorEl(event.currentTarget)
	}

	const handleSnoozeOptionsMenuClose = () => {
		setSnoozeOptionsMenuAnchorEl(null)
	}

	const handleSetSnooze = (data: SnoozeTime) => {
		setSnoozeTime(data)
	}

	const cancelSnooze = () => {
		handleSnoozeOptionsMenuClose()

		handleCancelSnooze()
	}

	const handleCreateSnooze = async () => {
		handleSnoozeOptionsMenuClose()

		try {
			const snoozeTimeInMilliseconds = handleInputSnoozeToMilliSecond(snoozeTime.time * snoozeMenuOptions[snoozeTime.timeOption.type].timeInMinutes)

			const result = await ApiService.post("/schedules/snooze/handle", {
				callback: {
					type: "webhook"
				},
				payload: {
					inboxChannelChatId,
					userId
				},
				time: String(snoozeTimeInMilliseconds),
				timeType: "date-by-now-in-milliseconds"
			})

			const newSchedule = result.data as SchedulesInInboxBase

			await chatGlobalStateStore.chat.updateById(inboxChannelChatId, {
				status: "snooze",
				schedule: {
					id: newSchedule.id,
					time: new Date(newSchedule.time)
				}
			})

			Notification.success({ message: "Soneca definida com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar definir a soneca." })
			ErrorHandler.handle(error as ErrorType)
		}

		saveSnooze({
			userId,
			instanceId,
			snooze: {
				snoozeTimeOption: snoozeTime.timeOption.type,
				value: snoozeTime.time as number
			}
		})
	}

	const buttonSnooze = (): SnoozeActionButton => {
		const buttonSnoozeStyles: Record<"cancel" | "create", SnoozeActionButton> = {
			cancel: {
				customClassName: `${classes.cancelSnoozeButton}`,
				btnVariant: "outlined",
				btnColor: "default",
				onClick: cancelSnooze,
				component: (
					<>
						<CancelScheduleIcon fontSize="medium"/>

						<Divider orientation="vertical" size={1} />

						Cancelar Soneca
					</>
				)
			},
			create: {
				customClassName: `${classes.btnSnooze}`,
				btnVariant: "contained",
				btnColor: "primary",
				onClick: handleCreateSnooze,
				component: <> Definir soneca </>
			}
		}

		if (isSnoozeCurrentChatStatus) {
			return buttonSnoozeStyles.cancel
		}

		return buttonSnoozeStyles.create
	}

	const btnSnooze = buttonSnooze()

	useEffect(() => {
		const snoozeTime = getSnoozeTime(userId, instanceId)

		handleSetSnooze(snoozeTime)
	}, [instanceId])

	return (
		<Grid item>
			<IconButton
				id={"snoozePopover"}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={(event) => handleSnoozeOptionsMenuClick(event)}
			>
				<SnoozeIcon style={{ color: `${chatGlobalStateStore.chat.current?.status === "snooze" ? colors.unrelated.F5A46B : ""}` }} />
			</IconButton>

			<Popover
				anchorReference="anchorEl"
				open={snoozeOptionsMenuIsOpen}
				anchorEl={snoozeOptionsMenuAnchorEl}
				onClose={handleSnoozeOptionsMenuClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				classes={{ paper: classes.popoverSnooze }}
				style={{
					width: "100%"
				}}
			>
				<Grid container spacing={3} className={classes.snoozeContainer}>
					<Grid item xs={12}>
						<Typography
							variant="h2"
							style={{ fontSize: "16px" }}
						>
							{!isSnoozeCurrentChatStatus ? "SONECA POR..." : "SONECA ATÉ:"}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							{!isSnoozeCurrentChatStatus ? (
								<>
									<Grid item xs={3}>
										<TextField
											defaultValue={snoozeTime.time}
											variant="outlined"
											type="number"
											fullWidth
											onChange={({ target }) => handleSetSnooze({
												...snoozeTime,
												time: Number(target.value)
											})}
											InputProps={{ inputProps: { min: 1, max: 99 } }}
										/>
									</Grid>
									<Grid item xs={9}>
										<Select
											variant="outlined"
											fullWidth={true}
											defaultValue={snoozeTime.timeOption.type}
											onChange={({ target }) => handleSetSnooze({
												...snoozeTime,
												timeOption: getSnoozeTimeOptionContent(target.value as SnoozeTimeOptions)
											}) }
										>
											{Object.keys(snoozeMenuOptions).map((option, index) => {
												return (
													<MenuItem
														key={index}
														value={option}
													>
														{snoozeMenuOptions[option as SnoozeTimeOptions].title}
													</MenuItem>
												)
											})}
										</Select>
									</Grid>
								</>
							) : (
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid
											item
											xs={12}
										>
											<Grid container justifyContent="center">
												{fullDatetime(new Date(scheduleTime as Date))}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Button
							className={btnSnooze.customClassName}
							variant={btnSnooze.btnVariant}
							color={btnSnooze.btnColor}
							fullWidth
							onClick={btnSnooze.onClick}
						>
							{btnSnooze.component}
						</Button>
					</Grid>
				</Grid>
			</Popover>
		</Grid>)
}

export default Snooze
