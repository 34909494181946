import React from "react"
import {
	Typography,
	Grid,
	Chip,
	Tooltip
} from "@material-ui/core"

import { CHAT_BOT_STATUS_MAP, renderChatBotTriggerIcon } from "@/utils/chatBot"
import { isSmallScreen } from "@/utils/checkDevice"

import { ChatBotCategory } from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"

import { ShortChatBotFlow } from "@/services/ChatBotFlow"
import { ChatBotTabType } from "@/pages/Admin/ChatBot/ChatBotList"
import TagChip from "@/pages/Admin/Flow/FlowConstructor/FlowEditor/components/TagChip"
import useStyles from "@/components/ContactAction/ChatBotList/styles"
import CustomLink from "@/components/Link"

type ChatBotListItemProps = {
	chatBotFlow: Pick<ShortChatBotFlow, "id" | "name" | "active" | "tag">
	chatBotCategory: ChatBotCategory | undefined
	chatBotConstructorPath: string
	webhookTemplateType?: WebhookTemplateType | "custom" | undefined
	chatBotTabType?: ChatBotTabType
	simpleView?: boolean
}

const ChatBotListItem: React.FC<ChatBotListItemProps> = (props) => {
	const {
		chatBotFlow,
		chatBotCategory,
		webhookTemplateType
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			justifyContent={isSmallScreen ? "space-evenly" : "space-between"}
			spacing={2}
			xs={12}
			md={12}
			lg={12}
			style={{ width: "100%" }}
		>
			<Grid item xs={1} md={2} lg={1}>
				{renderChatBotTriggerIcon(chatBotCategory || "no-triggers", webhookTemplateType || "custom")}
			</Grid>

			<Grid item xs={isSmallScreen ? 5 : 7} md={6} lg={8}>
				<Grid
					container
					direction="column"
					spacing={chatBotFlow?.tag ? 1 : 0}
					xs={12}
				>
					<Grid
						item
						xs={12}
					>
						{isSmallScreen ? <Tooltip
							title={chatBotFlow.name}
						>
							<Typography
								variant="h4"
								className={classes.listItemTitle}
							>
								{chatBotFlow.name}
							</Typography>
						</Tooltip> : <Typography
							variant="h4"
							className={classes.listItemTitle}
						>
							{chatBotFlow.name}
						</Typography>}
					</Grid>

					{(chatBotFlow?.tag) && (
						<Grid
							item
							xs={12}
							style={{
								width: "100%"
							}}
						>
							<Grid
								container
								spacing={1}
								style={{
									width: "80%",
									flexWrap: "inherit"
								}}
							>
								<Grid
									item
								>
									<Typography
										className={classes.tagAssociatedTriggerTitle}
									>
										Tag:
									</Typography>
								</Grid>

								<Grid
									item
									style={{
										width: "100%"
									}}
								>
									<TagChip
										color={chatBotFlow.tag.color}
										name={chatBotFlow.tag.name}
										style={{
											borderRadius: "4px",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "100%"
										}}
										{...(chatBotFlow.tag.name.length > 30 && { tooltip: { name: chatBotFlow.tag.name } })}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>

			{
				!isSmallScreen && <Grid item xs={3} md={2} lg={2}>
					{!chatBotFlow.active ? <Tooltip
						title="Este bot está inativo, para utiliza-lo você pode ativa-lo nas configurações do bot."
					>
						<Chip
							label={CHAT_BOT_STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.statusLabel}
							style={{
								backgroundColor: CHAT_BOT_STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.chipBackgroundColor
							}}
							className={classes.chip}
						/>
					</Tooltip> : <Chip
						label={CHAT_BOT_STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.statusLabel}
						style={{
							backgroundColor: CHAT_BOT_STATUS_MAP[chatBotFlow.active ? "enabled" : "disabled"]?.chipBackgroundColor
						}}
						className={classes.chip}
					/>}
				</Grid>
			}

			<Grid item xs={1} md={2} lg={1} >
				<CustomLink
					target="_BLANK"
					href={`${window.location.origin}/admin/flow/${chatBotFlow.id}`}
					iconSize="small"
				/>
			</Grid>
		</Grid >
	)
}

export default ChatBotListItem
