import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(theme => ({
	fullDialog: {
		height: "100%"
	},
	dialogContent: {
		height: "100%"
	},
	dialogTitle: {
		color: newColors.grey[700],
		fontWeight: "bold",
		fontSize: 24,
		lineHeight: 1.5
	},
	dialogDescription: {
		maxWidth: 550,
		color: newColors.grey[700]
	},
	planAddonsInformationText: {
		fontSize: 16,
		fontWeight: 700,
		maxWidth: 440,
		color: newColors.purple[600]
	},
	dialogActions: {
		padding: "8px 24px"
	},
	termsOfUse: {
		fontStyle: "italic"
	},
	termsOfUseLink: {
		color: newColors.grey[700],
		fontWeight: "bold",
		"&:hover": {
			color: newColors.purple[700]
		}
	},
	dialogActionPrimaryButton: {
		width: "100%",
		backgroundColor: newColors.purple[600],
		color: newColors.grey[0],
		textTransform: "uppercase",
		boxShadow: "none",
		"&:hover": {
			backgroundColor: newColors.purple[700]
		}
	},
	dialogActionSecondaryButton: {
		color: newColors.grey[500],
		fontWeight: "normal",
		"&:hover": {
			background: "transparent",
			color: newColors.grey[600]
		}
	},
	benefitListItem: {
		display: "flex",
		alignItems: "start",
		paddingLeft: 0
	},
	benefitItemIcon: {
		color: newColors.purple[600]
	},
	imageContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		width: "100%",
		height: "100%",
		backgroundColor: newColors.green[100],
		[theme.breakpoints.down("sm")]: {
			display: "none"
		},
		overflow: "hidden"
	},
	closeButton: {
		padding: 4,
		position: "absolute",
		backgroundColor: newColors.green[200],
		borderRadius: "100%",
		top: 12,
		right: 12,
		"&:hover": {
			backgroundColor: newColors.green[300]
		}
	}
}))

export default useStyles
