import { Divider, Loading, Portlet, Notification } from "@/components"
import Breadcrumb from "@/components/BreadcrumbNew"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import ApiService from "@/services/Api"
import {
	Button,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	Link,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"
import React, { useState } from "react"
import useStyles from "@/pages/Admin/ClientCatalog/ImportContacts/styles"

import ImportContactsHitory, { ImportContactsHistoryType } from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsHistory"
import ImportContactsReport from "@/pages/Admin/ClientCatalog/ImportContacts/ImportContactsReport"
import useCustomStyles from "@/styles/custom"
import useSocket from "@/hooks/useSocket"
import ImportContactsSkeleton from "@/skeletons/Admin/ImportContactsSkeleton"
import { letalkLinks } from "@/utils/link"

type ImportContactsFormValuesType = {
	duplicate_contacts_action: "ignore" | "replace",
	contacts_file?: File
}

const ImportContacts = () => {
	const [loading, setLoading] = useState(false)
	const [openImportContactsReportDialog, setOpenImportContactsReportDialog] = useState(false)
	const [processingContactsFile, setProcessingContactsFile] = useState(false)
	const [selectedImportContactsHistory, setSelectedImportContactsHistory] = useState<ImportContactsHistoryType>()
	const [totalContactsProcessed, setTotalContactsProcessed] = useState<number>(0)
	const [importContactsHistoryList, setImportContactsHistoryList] = useState<ImportContactsHistoryType[]>([])
	const [importContactsFormValues, setImportContactsFormValues] = useState<ImportContactsFormValuesType>({
		duplicate_contacts_action: "replace"
	})

	const { validation, clearValidation, triggerValidation } = useValidation()

	const classes = useStyles()

	const socket = useSocket()

	const customClasses = useCustomStyles()

	const handleGetImportContactsHistory = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/clients/contacts/imports-history")

			const parsedData: ImportContactsHistoryType[] = response.data.map((importContactsHistory: {
				consolidated_data: {
					total_contacts: number
					total_contacts_replaced: number
					total_new_contacts: number
				},
				status: ImportContactsHistoryType["status"]
				id: ImportContactsHistoryType["id"]
				instance_id: ImportContactsHistoryType["instanceId"]
				created_at: ImportContactsHistoryType["createdAt"]
				contacts_not_imported: ImportContactsHistoryType["contactsNotImported"]
			}) => {
				if (importContactsHistory.status === "importing") {
					setProcessingContactsFile(true)
					setTotalContactsProcessed(importContactsHistory.consolidated_data.total_contacts)
				}
				return {
					id: importContactsHistory.id,
					instanceId: importContactsHistory.instance_id,
					consolidatedData: {
						totalContacts: importContactsHistory.consolidated_data.total_contacts,
						totalContactsReplaced: importContactsHistory.consolidated_data.total_contacts_replaced,
						totalNewContacts: importContactsHistory.consolidated_data.total_new_contacts
					},
					contactsNotImported: importContactsHistory.contacts_not_imported,
					status: importContactsHistory.status,
					createdAt: importContactsHistory.created_at
				}
			})

			setImportContactsHistoryList(parsedData)
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar obter o histórico de importação de contatos." })
		}
		setLoading(false)
	}

	const handleImportContacts = async () => {
		try {
			if (importContactsFormValues.contacts_file) {
				setTotalContactsProcessed(0)
				setProcessingContactsFile(true)
				const formData = new FormData()

				formData.append("contactsFile", importContactsFormValues.contacts_file)
				formData.append("duplicateContactsAction", importContactsFormValues.duplicate_contacts_action)
				await ApiService.post("/clients/contacts/import-from-file", formData,
					{
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}
				)
			}
		} catch (error) {
			setProcessingContactsFile(false)
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Houve um erro ao importar os contatos." })
		}

		setImportContactsFormValues({
			duplicate_contacts_action: "replace",
			contacts_file: undefined
		})
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target

		clearValidation(name)

		if (name === "contacts_file") {
			if (event.target.files) {
				setImportContactsFormValues({ ...importContactsFormValues, [name]: event.target.files[0] })
			}
		} else {
			setImportContactsFormValues({ ...importContactsFormValues, [name]: value })
		}
	}

	const handleOnContactsImportStatusChanged = () => {
		socket.onContactsImportStatusChanged((contactsImport) => {
			if (contactsImport.status === "importing") {
				const totalContacts = contactsImport?.consolidatedData?.totalContacts
				totalContacts && setTotalContactsProcessed(totalContacts)
			} else {
				setSelectedImportContactsHistory(contactsImport)
				setProcessingContactsFile(false)
				setOpenImportContactsReportDialog(true)
				handleGetImportContactsHistory()
			}
		})
	}

	useDidMount(() => {
		handleGetImportContactsHistory()
		handleOnContactsImportStatusChanged()
	})

	return <Grid container>
		<Loading loading={loading} customLoadingElement={<ImportContactsSkeleton />}>
			<Grid container spacing={3}>
				<Divider orientation="horizontal" size={2} />
				<Grid item>
					<Breadcrumb
						data={
							[
								{
									name: "Contatos",
									pathname: "/admin/client-catalog"
								},
								{
									name: "Importar Contatos",
									pathname: "/admin/client-catalog/import-contacts"
								}
							]
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<Portlet>
						{
							processingContactsFile ? <Grid
								container
								direction="column"
								justifyContent="center"
								alignItems="center"
								spacing={4}
							>
								<Grid item>
									<CircularProgress color="secondary" />
								</Grid>
								<Grid item>
									<Typography variant="h4">IMPORTANDO CONTATOS</Typography>
								</Grid>
								<Typography variant="body1" align="center">
									<b>{totalContactsProcessed}</b> contato{totalContactsProcessed > 1 && "s"} processados até o momento
								</Typography>
								<Grid item>
									<Typography variant="body1" align="center">
										O arquivo está sendo processado.
									</Typography>
									<Typography variant="body1" align="center">
										Você pode fechar essa tela a qualquer momento que não irá afetar na importação dos contatos.
									</Typography>
								</Grid>
							</Grid> : <Grid container direction="column" spacing={3}>
								<Grid item>
									<Alert icon={false} severity="warning">
										Os números devem ser enviados com DDI, DDD e 9° dígito. Ex.: <b>55 11 9 94544758</b> (desconsideramos qualquer caracter que não seja número) <br />
										O arquivo enviado deve ter na 1ª linha as seguintes colunas: <b>nome</b>, <b>numero</b> e <b>email</b> (esta última coluna é opcional) <br />
										Tipo de arquivo: <b>CSV</b> <br />
										Limite máximo do arquivo: <b>1MB</b> (Cerca de 25.000 contatos) <br /><br />

										É obrigatório o preenchimento das colunas <b>nome</b> e <b>numero</b> nos contatos que serão importados. O preenchimento da coluna <b>email</b> não é obrigatório, mesmo que a coluna exista no cabeçalho da planilha.<br />
										<b>IMPORTANTE:</b> contatos que não possuam <b>nome</b> e <b>número</b>, ambos preenchidos, não serão considerados como contatos encontrados no arquivo. Portanto, não serão importados e também não estarão na planilha ao realizar o download dos contatos não importados.<br /><br />

										<Link
											href={letalkLinks.wikiHowToImportContacts}
											underline="always"
											target="_blank"
											color="inherit"
										>
											Clique aqui para acessar o tutorial completo de como o arquivo enviado deve ser configurado.
										</Link>
									</Alert>
								</Grid>

								<Grid item>
									<FormControl>
										<FormLabel> <b> Arquivo </b> </FormLabel>
										<Divider orientation="horizontal" size={1} />
										<div style={{ marginTop: "5px" }}>
											<label htmlFor="contacts_file" className={validation.contacts_file ? classes.customInputFileError : classes.customInputFile}>
												<TextField
													id="contacts_file"
													variant="outlined"
													type="file"
													name="contacts_file"
													style={{ opacity: 0, zIndex: -1, position: "absolute" }}
													inputProps={{
														accept:
															".csv"
													}}
													onChange={handleInputChange}
													helperText={validation.contacts_file}
													error={!!validation.contacts_file}
												/>

												<span className={classes.customInputFileLabel}>
													{importContactsFormValues.contacts_file ? importContactsFormValues.contacts_file.name : "Escolha um arquivo para importar"}
												</span>

												<Button style={{ marginBottom: "5px" }} className={customClasses.buttonAction} variant="contained" component="span">
													SELECIONAR ARQUIVO
												</Button>
											</label>
										</div>
										{
											!!validation.contacts_file && <FormHelperText error>{validation.contacts_file}</FormHelperText>
										}
									</FormControl>

									<Divider orientation="horizontal" size={3} />

									<FormControl focused={false}>
										<FormLabel> <b> O que deseja fazer quando for identificado número já cadastrado na Letalk? </b> </FormLabel>

										<RadioGroup
											aria-labelledby="demo-error-radios"
											name="duplicate_contacts_action"
											value={importContactsFormValues.duplicate_contacts_action}
											onChange={handleInputChange}
										>
											<FormControlLabel value="replace" control={<Radio />} label="Atualizar o cadastro na letalk" />
											<FormControlLabel value="ignore" control={<Radio />} label="Ignorar Número" />
										</RadioGroup>

									</FormControl>
								</Grid>

								<Grid item >
									<Grid container justifyContent="flex-end">
										<Grid item>
											<Button
												color="primary"
												variant="contained"
												onClick={handleImportContacts}
											>
												IMPORTAR
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						}
					</Portlet>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid item xs={12}>
					<Typography
						variant="h4"
						color="textPrimary"
					>
						HISTÓRICO DE IMPORTAÇÕES
					</Typography>
					<Divider orientation="horizontal" size={2} />
					<ImportContactsHitory
						importContactsHistoryList={importContactsHistoryList}
						setOpenImportContactsReportDialog={setOpenImportContactsReportDialog}
						setSelectedImportContactsHistory={setSelectedImportContactsHistory}
					/>
				</Grid>

				<ImportContactsReport
					openDialog={openImportContactsReportDialog}
					setOpenDialog={setOpenImportContactsReportDialog}
					importContactsHistory={selectedImportContactsHistory}
				/>
			</Grid>
		</Loading>
	</Grid>
}

export default ImportContacts
