import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

type StylesProps = {
	qualityBackgroundColor: string
	statusBackgroundColor?: string
}

const useStyles = makeStyles((theme) => ({
	header: {
		paddingBottom: 0,
		padding: 0,
		alignItems: "baseline"
	},
	name: {
		fontSize: "1rem",
		width: "23ch",
		fontWeight: 500,
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	feedbackGrid: {
		display: "flex",
		gap: theme.spacing(1)
	},
	statusChip: (props: StylesProps) => ({
		backgroundColor: props.statusBackgroundColor
	}),
	qualityChip: (props: StylesProps) => ({
		backgroundColor: props.qualityBackgroundColor
	}),
	categoryChip: {
		backgroundColor: newColors.purple[100],
		borderRadius: 20,
		color: newColors.purple[500],
		fontSize: 12,
		height: 30
	},
	footer: {
		justifyContent: "flex-end",
		padding: 0
	},
	content: {
		justifyContent: "center",
		alignitems: "center",
		height: 324,
		overflowY: "auto",
		padding: 10,
		borderRadius: 8,
		"&::-webkit-scrollbar": {
			width: "0.25em"
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: newColors.grey[100],
			borderRadius: "10px"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0,0,0,0.2)",
			borderRadius: "10px",
			border: "2px solid transparent",
			backgroundClip: "content-box"
		}
	},
	moreOptionsButton: {
		padding: "8px 1px 0px 0px",
		"&:hover": {
			backgroundColor: "transparent"
		}
	}
}))

export default useStyles
