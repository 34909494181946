import apiConfig from "@/config/api"
import { IChat, IMessage } from "@/protocols/channel"
import { BuildedMessage } from "@/protocols/messages"
import { UserChatSettingsProps } from "@/protocols/settings"
import { ChatLoadingErrors } from "@/store/ActiveCampaignExternalChatGlobalState"
import { ActiveCampaignContactWindowTyping } from "zoid"

type GetClientChatMessagesResponse = {
	messages: IMessage[]
	count: number
}
type GetQuickViewPluginCredentialsResponse = {
	instanceId: number | null
	representantUserAuthToken: string
	representantUserName: string
	representantUserId: number | null
	chatLoadingErrors: ChatLoadingErrors
	inboxChannelUserChatSettings: UserChatSettingsProps
}

type GetClientChatMessagesParams = {
	inboxChannelChatId: number
	inboxChannelId: number
	rowsPerPage: number
	page: number
}

type ActiveCampaignQuickViewMethods = {
	getClientChatByPhoneNumber: (phoneNumber: string) => Promise<IChat>
	getClientChatMessages: (params: GetClientChatMessagesParams) => Promise<GetClientChatMessagesResponse>
	getQuickViewPluginCredentials: (phoneNumber: string) => Promise<GetQuickViewPluginCredentialsResponse>
	getActiveContactData: () => ActiveCampaignContactWindowTyping
	replaceWabaTemplateVariables: (wabaChannelMessageTemplateId: number, clientId: number) => Promise<BuildedMessage>
}

const useActiveCampaignChatQuickView = (): ActiveCampaignQuickViewMethods => {
	const defaultApiUrl = apiConfig.apiUrl

	let canMakeApiCall = true
	let connection_id = window.xprops?.connection_data?.[0]?.id

	if (!connection_id) {
		console.error("No connection ID informed by Active Quick View, review your configurations file")
		canMakeApiCall = false
	}

	const renewConnectionIdAndApiCallPermission = () => {
		const newConnectionId = window.xprops?.connection_data?.[0]?.id
		if (newConnectionId) {
			connection_id = window.xprops?.connection_data?.[0]?.id
			canMakeApiCall = true
		} else {
			canMakeApiCall = false
		}
	}

	const quickViewMethods = {
		postDataViaProxy: async (
			endpoint: string,
			body: Record<string, unknown>,
			headers?: Record<string, unknown>
		) => {
			renewConnectionIdAndApiCallPermission()

			if (canMakeApiCall) {
				const mountedUrl = `${defaultApiUrl}/${endpoint}`
				const response = await window.xprops.postDataViaProxy(mountedUrl, connection_id, "connection", body, headers)

				const isError = response.data.data.status !== 200
				if (isError) {
					throw response.data.data.body
				}

				return response
			}
		},
		getDataViaProxy: async (
			endpoint: string
		) => {
			renewConnectionIdAndApiCallPermission()

			if (canMakeApiCall) {
				const mountedUrl = `${defaultApiUrl}/${endpoint}`

				const response = await window.xprops.getDataViaProxy(mountedUrl, connection_id, "connection")

				const isError = response.data.data.status !== 200
				if (isError) {
					throw response.data.data.body
				}

				return response
			}
		},
		deleteViaProxy: async (
			endpoint: string
		) => {
			renewConnectionIdAndApiCallPermission()

			if (canMakeApiCall) {
				const mountedUrl = `${defaultApiUrl}/${endpoint}`
				const response = await window.xprops.deleteViaProxy(mountedUrl, connection_id, "connection")

				const isError = response.data.data.status !== 200
				if (isError) {
					throw response.data.data.body
				}

				return response
			}
		}
	}

	/**
	 * Chat methods does not return error body, only error status
	 * Error treatment has do be done in a custom way because of that
	 */
	const chatMehods = {
		getClientChatByPhoneNumber: async (phoneNumber: string): Promise<IChat> => {
			const response = await quickViewMethods.getDataViaProxy(`plugin-settings/active-campaign-chat/client/chat/${phoneNumber}`)

			const clientChat = response?.data?.data?.body?.inboxChannelChat
			return clientChat
		},
		getClientChatMessages: async (params: GetClientChatMessagesParams): Promise<GetClientChatMessagesResponse> => {
			const response = await quickViewMethods.getDataViaProxy(`plugin-settings/active-campaign-chat/client/chat/messages/${params.inboxChannelChatId}/${params.inboxChannelId}/${params.rowsPerPage}/${params.page}`)

			const clientChatMessages = response?.data?.data?.body?.inboxChannelChatMessages
			const clientChatMessagesCount = response?.data?.data?.body?.count

			return {
				messages: clientChatMessages,
				count: clientChatMessagesCount
			}
		},
		getQuickViewPluginCredentials: async (phoneNumber: string): Promise<GetQuickViewPluginCredentialsResponse> => {
			try {
				const response = await quickViewMethods.getDataViaProxy(`plugin-settings/active-campaign-chat/plugin-credentials/${phoneNumber}`)

				const responseBody = response?.data?.data?.body

				const chatLoadingErrors: ChatLoadingErrors = {
					...(responseBody?.chatLoadingErrors && responseBody?.chatLoadingErrors)
				}

				return {
					instanceId: Number(responseBody?.instanceId),
					representantUserAuthToken: responseBody?.representantUserAuthToken,
					representantUserName: responseBody?.representantUserName,
					representantUserId: responseBody?.representantUserId,
					chatLoadingErrors: chatLoadingErrors,
					inboxChannelUserChatSettings: responseBody?.inboxChannelUserChatSettings
				}
			} catch (error) {
				return {
					instanceId: null,
					representantUserAuthToken: "",
					representantUserName: "",
					representantUserId: null,
					chatLoadingErrors: {
						GenericError: true
					},
					inboxChannelUserChatSettings: {
						signature: {
							group_signature: false,
							group_signature_name: "",
							group_signature_text: "",
							individual_signature: false,
							individual_signature_name: "",
							individual_signature_text: ""
						}
					}
				}
			}
		},
		getActiveContactData: (): ActiveCampaignContactWindowTyping => {
			return window.xprops.data.contact
		},
		replaceWabaTemplateVariables: async (wabaChannelMessageTemplateId: number, clientId: number): Promise<BuildedMessage> => {
			const response = await quickViewMethods.postDataViaProxy(`plugin-settings/active-campaign-chat/waba/message/template/${wabaChannelMessageTemplateId}/variables`, { clientId })

			return response?.data?.data?.body
		}
	}

	return {
		...chatMehods
	}
}

export default useActiveCampaignChatQuickView
