import { Divider, InfoDialog, Loading, LoadingOverlay, Notification, Portlet, ProductReportActionButtonGroup, ProductReportChatbot, ProductReportClient, ProductReportHeader, ProductReportInfo, TextCopy, TypographyListGroup, UpsellDialog } from "@/components"
import ApiService from "@/services/Api"
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Link, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@material-ui/core"
import React, { ChangeEvent, useState } from "react"
import { ActiveCampaignPluginChat, ActiveCampaignPluginSettings } from "@/pages/Admin/Plugins/ActiveCampaignPlugin"
import { ActiveCampaignPluginSettingsStatusSkeleton } from "@/skeletons/Admin/ActiveCampaignPluginSkeletons"

import { letalkLinks } from "@/utils/link"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ErrorType } from "@/hooks/useValidation"
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, DEFAULT_WHERE_DATA } from "@/utils/pagination"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import useCustomStyles from "@/styles/custom"
import {
	InfoOutlined as InfoIcon,
	Extension as RequestBodyIcon,
	Close as CloseIcon,
	NavigateNext as OpenAccordionIcon,
	RefreshOutlined as ProcessingIcon,
	Restore as ReceivedIcon,
	Done as ProcessedIcon
} from "@material-ui/icons"
import { FiltersListType } from "@/components/FiltersDialog"
import { getRowsLabel } from "@/utils/table"
import useStyles from "@/pages/Admin/Plugins/ActiveCampaignPlugin/ActiveToLetalk/styles"
import useDebounce from "@/hooks/useDebounce"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { ptBR } from "date-fns/locale"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import newColors from "@/styles/newColors"
import colors from "@/styles/colors"
import HardCoded from "@/services/HardCoded"

export type ActiveCampaignChatPluginEventStatus = "received" | "processing" | "processed" | "error"

export type ActiveCampaignChatPluginEventType = "create_contact" | "send_waba_template" | "add_contact_on_bot"

type ChatBotFlow = {
	id: number,
	name: string,
	deleted_at: string | null
} | null

export type ActiveCampaignChatPluginEvent = {
	id: number
	instance_id: number
	inbox_channel_id?: number
	type: ActiveCampaignChatPluginEventType
	status: ActiveCampaignChatPluginEventStatus
	body: Record<string, unknown>
	client_id?: number
	client?: {
		id: number
		name: string
		nickname: string
		phoneNumber: string
	}
	chat_bot_flow: ChatBotFlow
	error?: Record<string, unknown>
	created_at: Date
	updated_at: Date
	deleted_at?: Date
}

export type PaginatedActiveCampaignChatPluginEvents = {
	count: number
	rows: ActiveCampaignChatPluginEvent[]
}

export type ActiveCampaignChatPluginEventsWhereData = {
	page: number
	rowsPerPage: number
	type?: ActiveCampaignChatPluginEventType
	search?: string
	filters?: FiltersListType[]
}

interface SettingsProps {
	pluginChat: ActiveCampaignPluginChat
	setPluginChat: React.Dispatch<React.SetStateAction<ActiveCampaignPluginChat>>
}

export enum DATE_INDEXES {
	START_DATE,
	END_DATE
}

export type ActiveCampaignChatPluginEventTypeToName = Record<ActiveCampaignChatPluginEventType, string>

const EVENT_TYPE_TO_NAME: Partial<ActiveCampaignChatPluginEventTypeToName> = {
	create_contact: "Criação de contato"
}

const filtersInitialValue: FiltersListType[] = [
	{
		type: "date",
		column: "created_at",
		values: ["", ""],
		operator: "between"
	}
]

const ActiveToLetalk = (props: SettingsProps) => {
	const { pluginChat, setPluginChat } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const activeCampaignChatPluginSubscriptionData = useSubscriptionLimits("permission", "active_campaign_chat_plugin")

	const [loading, setLoading] = useState({
		status: false,
		update: false,
		activeCampaignChatPluginEvents: false
	})

	const [whereData, setWhereData] = useState<ActiveCampaignChatPluginEventsWhereData>(DEFAULT_WHERE_DATA)

	const [filtersList, setFiltersList] = useState<FiltersListType[]>(filtersInitialValue)

	const [activeCampaignChatPluginEvents, setActiveCampaignChatPluginEvents] = useState<ActiveCampaignChatPluginEvent[]>([])

	const [expanded, setExpanded] = useState(false)

	const [totalFilteredEvents, setTotalFilteredEvents] = useState(0)

	const hasActiveCampaignChatPluginEvents = activeCampaignChatPluginEvents.length >= 1

	if (HardCoded.checkFeatureFlag("canViewAllActiveCampaignChatEventStatus")) {
		EVENT_TYPE_TO_NAME.add_contact_on_bot = "Adicionar contato no bot"
		EVENT_TYPE_TO_NAME.send_waba_template = "Enviar template da WABA"
	}

	const handleUpdatePluginSettings = async (pluginChatId?: number, newData?: ActiveCampaignPluginSettings, updateType?: "status" | "update") => {
		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: true
			})
		}

		try {
			await ApiService.put(`/plugin-settings/${pluginChatId}`, {
				...newData
			})

			if (updateType === "status") {
				if (newData?.status === "enabled") {
					Notification.success({ message: "Plugin instalado com sucesso." })
				} else {
					Notification.success({ message: "Plugin desinstalado com sucesso." })
				}
			} else {
				Notification.success({ message: "Configurações do plugin atualizadas." })
			}
		} catch (error) {
			Notification.error({ message: "Não foi possível alterar os dados do plugin." })
		}

		if (updateType && updateType !== "status") {
			setLoading({
				...loading,
				[updateType]: false
			})
		}
	}

	const handlePluginStatusChange = async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const canBlockStatusChange = activeCampaignChatPluginSubscriptionData.mustBlock && checked
		if (canBlockStatusChange) {
			/**
			 * Fake enable and auto disable effect
			 */
			setPluginChat({
				...pluginChat,
				status: "enabled"
			})
			UpsellDialog.open({
				dialogCode: activeCampaignChatPluginSubscriptionData.blockCode,
				onClose: () => {
					setPluginChat({
						...pluginChat,
						status: "disabled"
					})
				},
				onSave: () => {
					setPluginChat({
						...pluginChat,
						status: "disabled"
					})
				}
			})
		} else {
			const statusValue = checked ? "enabled" : "disabled"

			setPluginChat({
				...pluginChat,
				status: statusValue
			})

			await handleUpdatePluginSettings(pluginChat?.id, {
				status: statusValue
			}, "status")
		}
	}

	const loadActiveCampaignChatPluginEvents = async (newWhereData: Partial<ActiveCampaignChatPluginEventsWhereData> = DEFAULT_WHERE_DATA) => {
		setLoading(state => ({ ...state, activeCampaignChatPluginEvents: true }))

		try {
			const response = await ApiService.get("/plugin-settings/active-campaign-chat/history", {
				params: {
					...whereData,
					...newWhereData
				}
			})

			const { rows, count } = response.data as PaginatedActiveCampaignChatPluginEvents

			setActiveCampaignChatPluginEvents(rows)

			setTotalFilteredEvents(count)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoading(state => ({ ...state, activeCampaignChatPluginEvents: false }))
	}

	const handleWhereDataChange = (newData: Partial<ActiveCampaignChatPluginEventsWhereData>) => {
		setWhereData(state => ({
			...state,
			...newData
		}))
	}

	const handlePageChange = async (_: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await loadActiveCampaignChatPluginEvents({ page })
	}

	const handleRowsPerPageChange = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await loadActiveCampaignChatPluginEvents({ rowsPerPage })
	}

	const handleStartRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
		async ({ target }) => {
			const newRowsPerPage = +target.value

			handlePageChange(target, 0)

			handleRowsPerPageChange(newRowsPerPage)
		}

	const handleShowEventRequestBody = (requestBody: Record<string, unknown>) => {
		if (requestBody) {
			const stringifiedRequestBody = JSON.stringify(requestBody || {}, null, 2)

			InfoDialog.open({
				title: "Dados recebidos",
				openDialog: true,
				children: (
					<pre>
						<code>
							{stringifiedRequestBody}
						</code>
					</pre>
				)
			})
		}
	}

	const handleRefreshEvents = async () => {
		await loadActiveCampaignChatPluginEvents({})

		Notification.success({ message: "A lista de eventos recebidos foi atualizada com sucesso!" })
	}

	const handleEventTypeFilterChange =
		async (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
			const newFilter = event.target.value as ActiveCampaignChatPluginEventType

			handleWhereDataChange({
				type: newFilter
			})

			await loadActiveCampaignChatPluginEvents({ type: newFilter })
		}

	const handleContactsDataSearchChange = async (search: string) => {
		handleWhereDataChange({
			search,
			page: 0
		})
	}

	const handleGetFilter = (filterColumn: string) => {
		return filtersList.find(filter => filter.column === filterColumn)
	}

	const handleGetDateFromCreatedAt = (dateIndex: number) => {
		const createdAtFilter = handleGetFilter("created_at")

		const date = createdAtFilter?.values[dateIndex]

		if (date) {
			return new Date(date)
		}

		return null
	}

	const handleFiltersListChange = (
		filterColumn: string,
		valueIndex: number,
		// eslint-disable-next-line
		value: any
	) => {
		const newFilters = [...filtersList]

		const filter = handleGetFilter(filterColumn)

		if (filter) {
			filter.values[valueIndex] = value
		}

		handleWhereDataChange({ filters: newFilters })

		setFiltersList(newFilters)
	}

	const handleStartDateChange = async (date: MaterialUiPickersDate) => {
		date?.setHours(0)
		date?.setMinutes(0)

		const isoDate = date?.toISOString()

		handleFiltersListChange("created_at", 0, isoDate)
	}

	const handleEndDateChange = async (date: MaterialUiPickersDate) => {
		date?.setHours(23)
		date?.setMinutes(59)

		const isoDate = date?.toISOString()

		handleFiltersListChange("created_at", 1, isoDate)
	}

	const handleAccordionExpand = () => {
		setExpanded(state => {
			const newExpanded = !state
			const expandedNow = newExpanded

			if (expandedNow) {
				loadActiveCampaignChatPluginEvents()
			}

			return newExpanded
		})
	}

	const startDate = handleGetDateFromCreatedAt(DATE_INDEXES.START_DATE)
	const endDate = handleGetDateFromCreatedAt(DATE_INDEXES.END_DATE)

	useDebounce(
		async () => await loadActiveCampaignChatPluginEvents({ search: whereData?.search }),
		whereData.search,
		1250
	)

	useDebounce(
		async () => await loadActiveCampaignChatPluginEvents(),
		filtersList,
		1250
	)

	return <div>
		<Link
			href={letalkLinks.wikiHowToConfigurePluginWithActiveCampaign}
			underline="always"
			target="_blank"
			color="inherit"
		>
			Conecte sua conta do Active campaign e crie contatos na Letalk através de automações
		</Link>

		<Divider orientation="horizontal" size={3} />

		<Portlet >
			<Loading
				loading={loading.status}
				customLoadingElement={<ActiveCampaignPluginSettingsStatusSkeleton/>}
			>
				<Grid container>
					<Grid item xs={5} alignContent="center">
						<Switch
							checked={pluginChat?.status === "enabled"}
							onChange={handlePluginStatusChange}
						/>
						<span>
							{pluginChat?.status === "enabled" ? <b>Plugin Instalado</b> : <b>Plugin Desinstalado</b>}
						</span>
					</Grid>
					{
						(pluginChat?.status === "enabled" && !activeCampaignChatPluginSubscriptionData.mustBlock) && (
							<Grid
								item
								xs={6}
								style={{
									marginLeft: "auto"
								}}
							>
								<TextCopy
									text={String(pluginChat?.settings?.token)}
									onSuccessMessage="Token copiado com sucesso"
									encryptText
								/>
							</Grid>
						)
					}
				</Grid>
			</Loading>
		</Portlet>

		<>
			<Divider orientation="horizontal" size={3} />

			<Paper elevation={2}>
				<Accordion expanded={expanded} >
					<AccordionSummary
						expandIcon= {<OpenAccordionIcon />}
						classes={{
							expandIcon: classes.expandIcon,
							expanded: classes.expanded,
							root: classes.accordionSummary
						}}
						IconButtonProps={{ onClick: handleAccordionExpand }}
					>
						<b>Histórico de eventos</b>
					</AccordionSummary>

					<AccordionDetails className={customClasses.flexCol} >
						<Grid
							container
							direction="column"
							xs={12}
							spacing={6}
						>
							<Grid item>

								<ProductReportHeader
									title=""
									subTitle="Entenda o processo de recebimento de eventos do plugin:"
									description={(
										<>
											Recebemos o pacote de dados e criamos uma fila;

											<Divider orientation="horizontal" size={2} />

											Processamos o pacote de dados para certificar que recebemos todas as informações corretas que precisamos para realizar a ação correspondente ao evento;

											<Divider orientation="horizontal" size={2} />

											Disparamos esse evento para o Inbox da Letalk, que será responsável pela realização da ação de fato.
										</>
									)}
								/>
							</Grid>

							<Grid
								item
								container
								alignItems="center"
								xs={12}
							>
								<Grid
									item
									container
									xs={11}
									spacing={2}
								>
									<Grid item>
										<Select
											placeholder="Tipo de evento"
											defaultValue="hint"
											variant="outlined"
											className={classes.eventTypeFilterSelect}
											fullWidth
											onChange={(event) => handleEventTypeFilterChange(event)}
										>
											<MenuItem value="hint" disabled hidden>
												Tipo de evento
											</MenuItem>

											{Object.entries(EVENT_TYPE_TO_NAME).map(([slug, name]) => (
												<MenuItem key={slug} value={slug} >
													{name}
												</MenuItem>
											))}
										</Select>
									</Grid>

									<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
										<Grid item >
											<DatePicker
												placeholder="De"
												format="dd/MM/yyyy"
												okLabel="Pronto"
												cancelLabel="Cancelar"
												inputVariant="outlined"
												variant="inline"
												size="small"
												views={["year", "month", "date"]}
												className={classes.dateInput}
												value={startDate}
												animateYearScrolling
												fullWidth
												autoOk
												onChange={handleStartDateChange}
											/>
										</Grid>

										<Grid item >
											<DatePicker
												placeholder="Até"
												format="dd/MM/yyyy"
												okLabel="Pronto"
												cancelLabel="Cancelar"
												inputVariant="outlined"
												variant="inline"
												size="small"
												views={["year", "month", "date"]}
												className={classes.dateInput}
												value={endDate}
												animateYearScrolling
												fullWidth
												autoOk
												onChange={handleEndDateChange}
											/>
										</Grid>
									</MuiPickersUtilsProvider>

									<Grid item>
										<TextField
											placeholder="Pesquise pelo nome ou telefone do contato"
											variant="outlined"
											size="small"
											className={classes.contactDataSearchInput}
											value={whereData.search}
											InputProps={{
												endAdornment: whereData.search && (
													<IconButton
														size="small"
														onClick={() =>
															handleWhereDataChange({ search: "" })
														}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												)
											}}
											fullWidth
											onChange={({ target }) =>
												handleContactsDataSearchChange(target.value)
											}
										/>
									</Grid>
								</Grid>

								<Grid
									item
									xs={1}
								>
									<ProductReportActionButtonGroup
										refresh={{
											tooltip: "Atualizar",
											onClick: handleRefreshEvents
										}}
									/>
								</Grid>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<LoadingOverlay
									loading={loading.activeCampaignChatPluginEvents}
								>
									{hasActiveCampaignChatPluginEvents && (
										<Paper className={customClasses.fullWidth} >
											<TableContainer>
												<Table
													stickyHeader
													size="small"
												>
													<TableHead>
														<TableRow>
															<TableCell>
															ID
															</TableCell>

															<TableCell>
															Tipo do evento
															</TableCell>

															<TableCell>
																<Grid
																	container
																	alignItems="center"
																	justify-content="center"
																>
																Data / Hora

																	<Divider orientation="vertical" size={2} />

																	<Tooltip
																		title="Data e hora do recebimento do evento"
																	>
																		<InfoIcon />
																	</Tooltip>
																</Grid>
															</TableCell>

															<TableCell>
																Infor.
															</TableCell>

															<TableCell>
																Contato
															</TableCell>

															<TableCell>
																Bot
															</TableCell>

															<TableCell />
														</TableRow>
													</TableHead>

													<TableBody
														className={customClasses.reportTableBodyText}
													>
														{activeCampaignChatPluginEvents.map(event => (
															<TableRow
																key={event.id}
																tabIndex={-1}
															>
																<TableCell>
																	{event.id}
																</TableCell>

																<TableCell>
																	{EVENT_TYPE_TO_NAME[event.type]}
																</TableCell>

																<TableCell>
																	<TypographyListGroup>
																		<Typography>
																			{formatDateInBrazilianDate(new Date(event.created_at))}
																		</Typography>

																		<Typography
																			className={customClasses.italicText}
																		>
																			{formatDateInHours(new Date(event.created_at))}
																		</Typography>
																	</TypographyListGroup>
																</TableCell>

																<TableCell>
																	<ProductReportInfo
																		messages={[]}
																		status={event.status}
																		error={event.error?.message as string}
																		productErrorMap={{
																			NoPhoneNumberOnEventData: "Não recebemos o número do WhatsApp desse contato",
																			InvalidPhoneNumber: "Número de telefone inválido",
																			ChatBotNotExists: "Chatbot inexistente",
																			InactiveChatBot: "Chatbot inativo",
																			NoValidContactFound: "Número de telefone não existe no WhatsApp",
																			TemplateNotExists: "Template da WABA inexistente"
																		}}
																		extraInfo={{
																			received: {
																				title: "Evento recebido",
																				icon: <ReceivedIcon />,
																				backgroundColor: colors.unrelated.DBEBFF,
																				iconColor: colors.unrelated["4B74A8"],
																				tooltipText: ""
																			},
																			processing: {
																				title: "Processando",
																				tooltipText: "Recebemos o evento, está na fila para ser enviado para o Inbox",
																				icon: <ProcessingIcon />,
																				backgroundColor: colors.grayScale[12]
																			},
																			processed: {
																				title: "Ação realizada",
																				icon: <ProcessedIcon />,
																				backgroundColor: newColors.green[100],
																				iconColor: newColors.green[600],
																				tooltipText: ""
																			},

																			error: {
																				title: "Erro ao realizar ação",
																				tooltipText: ""
																			}
																		}}
																	/>
																</TableCell>

																<TableCell>
																	<ProductReportClient client={event.client} />
																</TableCell>

																<TableCell>
																	<ProductReportChatbot chatbot={event.chat_bot_flow} />
																</TableCell>

																<TableCell>
																	<Tooltip
																		title="Dados recebidos"
																	>
																		<IconButton
																			onClick={() => handleShowEventRequestBody(event.body)}
																		>
																			<RequestBodyIcon />
																		</IconButton>
																	</Tooltip>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												component="div"
												labelRowsPerPage="Resultados por página:"
												rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
												count={totalFilteredEvents}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
												onPageChange={handlePageChange}
												onRowsPerPageChange={handleStartRowsPerPageChange}
											/>
										</Paper>
									)}

									{!hasActiveCampaignChatPluginEvents && <>
										<Divider size={3} orientation="horizontal" />

										<Typography align="center" variant="h2">
										Nenhum resultado encontrado
										</Typography>
									</>}
								</LoadingOverlay>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Paper>
		</>
	</div>
}

export default ActiveToLetalk
