import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	customFieldsCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	customFieldTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1)
	},
	button: {
		color: colors.grayScale[1]
	},
	iconButton: {
		padding: "0",
		margin: "0",
		minHeight: "20px"
	}
}))

export default useStyles
