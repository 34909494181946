import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	chatBotName: {
		fontSize: "20px"
	},
	unknown: {
		color: colors.unrelated.a5a5a5
	}
})

export default useStyles
