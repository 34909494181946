import React from "react"
import {
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField
} from "@material-ui/core"

import { SnoozeTime, SnoozeTimeOptions } from "@/protocols/snooze"
import { getSnoozeTimeOptionContent, snoozeMenuOptions } from "@/utils/snooze"
import { BuildedMessage } from "@/protocols/messages"

import Messages from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/Messages"

type CreateNewSnoozeContentProps = {
	messages: BuildedMessage[]
	sendMessageOnWakeUp: boolean
	handleSendMessageOnWakeUp: (data: boolean) => void
	handleSetMessages: (messages: BuildedMessage[]) => void
	handleSetSnoozeTime: (data: SnoozeTime) => void
	snoozeTime: SnoozeTime
}

const CreateNewSnoozeContent: React.FC<CreateNewSnoozeContentProps> = (props) => {
	const { messages, handleSetMessages, sendMessageOnWakeUp, handleSendMessageOnWakeUp, snoozeTime, handleSetSnoozeTime } = props

	return (
		<>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<InputLabel>Soneca por:</InputLabel>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<TextField
									defaultValue={snoozeTime.time}
									variant="outlined"
									type="number"
									fullWidth
									onChange={({ target }) => handleSetSnoozeTime({
										...snoozeTime,
										time: Number(target.value)
									})}
									InputProps={{ inputProps: { min: 1, max: 99 } }}
								/>
							</Grid>
							<Grid item xs={9}>
								<Select
									variant="outlined"
									fullWidth={true}
									defaultValue={snoozeTime.timeOption.type}
									onChange={({ target }) => handleSetSnoozeTime({
										...snoozeTime,
										timeOption: getSnoozeTimeOptionContent(target.value as SnoozeTimeOptions)
									}) }
								>
									{Object.keys(snoozeMenuOptions).map((option, index) => {
										return (
											<MenuItem
												key={index}
												value={option}
											>
												{snoozeMenuOptions[option as SnoozeTimeOptions].title}
											</MenuItem>
										)
									})}
								</Select>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel control={<Switch size="small" checked={sendMessageOnWakeUp} onChange={({ target }) => handleSendMessageOnWakeUp(target.checked)} />} label="Enviar uma mensagem ao despertar do soneca" />
			</Grid>
			{sendMessageOnWakeUp && (
				<Grid item xs={12}>
					<Messages messages={messages || []} onSave={(messages) => handleSetMessages(messages) }/>
				</Grid>
			)}
		</>
	)
}

export default CreateNewSnoozeContent
