import React from "react"

import { MediaMessageContent } from "@/components/Messages/MediaMessage"
import { TextMessageContent } from "@/components/Messages/TextMessage"
import { ContactCardMessageContent, ContactCardMessageContentFooter } from "@/components/Messages/ContactCardMessage"
import { ButtonMessageContainerFooter, ButtonMessageContentBoxStyle } from "@/components/Messages/ButtonMessage"
import { MenuMessageContentFooter } from "@/components/Messages/MenuMessage"

import {
	IMessageExtraData,
	MessageStatus,
	MessageType
} from "@/protocols/channel"

import { isMediaMessage } from "@/utils/message"
import { CustomLinkMessageContentFooter } from "@/components/Messages/CustomLinkMessage"
import MessageDeletedContent from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageDeletedContent"
import { Grid } from "@material-ui/core"
import {
	MissedVideoCall as MissedVideoCallIcon,
	PhoneCallback as PhoneCallbackIcon
} from "@material-ui/icons"
import WABAMessageHeader from "@/@integrations/WABA/components/Chat/WABAMessages/WABAMessageHeader"

type ChildContentInput = {
	fileName?: string
	caption?: string
	content?: string
	uploadingMedia?: boolean
	fancyBoxSlug?: string
	extraData?: IMessageExtraData
	copySpecial?: boolean
	translateVariables?: boolean
	mediaUploadProgress?: number
	messageErrorCode?: string
}

type ChildContentFooterInput = {
	extraData?: IMessageExtraData
}

type ChildContainerFooterInput = {
	extraData?: IMessageExtraData
	sentByCustomer?: boolean
	isDefaultSize?: boolean
	status: MessageStatus
}

type ChildContentHeaderInput = {
	extraData?: IMessageExtraData
	fileName?: string
	uploadingMedia?: boolean
	fancyBoxSlug?: string
	translateVariables?: boolean
	mediaUploadProgress?: number
	messageErrorCode?: string
}

export default {
	getChildContent: (type: MessageType, input: ChildContentInput): React.ReactElement => {
		const {
			caption,
			content,
			fileName,
			copySpecial,
			extraData,
			fancyBoxSlug,
			translateVariables,
			uploadingMedia,
			mediaUploadProgress,
			messageErrorCode
		} = input

		if (isMediaMessage(type)) {
			return extraData?.mediaExpiration?.expired ? (
				<MessageDeletedContent
					customDeletedMessage={`Mensagens de mídia como fotos, vídeo, áudios e documentos com mais de ${extraData?.mediaExpiration?.expirationInDays} dias são apagados.`}
				/>
			) : (
				<MediaMessageContent
					caption={caption as string}
					content={content as string}
					fileName={fileName as string}
					type={type}
					uploadingMedia={uploadingMedia}
					fancyBoxSlug={String(fancyBoxSlug)}
					mediaUploadProgress={mediaUploadProgress}
					messageErrorCode={messageErrorCode}
				/>
			)
		} else if (type === "contact-card") {
			return (
				<ContactCardMessageContent
					extraData={extraData as IMessageExtraData}
				/>
			)
		} else if (type === "individual-video-call-missed") {
			return (
				<Grid
					alignContent="center"
					style={{ display: "flex" }}
				>
					<MissedVideoCallIcon /> Chamada de vídeo perdida
				</Grid>
			)
		} else if (type === "individual-voice-call-missed") {
			return (
				<Grid
					alignContent="center"
					style={{ display: "flex" }}
				>
					<PhoneCallbackIcon /> Chamada de voz perdida
				</Grid>
			)
		} else if (type === "ciphertext") {
			return (<TextMessageContent
				content={"Aguardando essa mensagem.\nIsso pode demorar um pouco" as string}
				copySpecial={copySpecial}
				translateVariables={translateVariables}
			/>)
		} else {
			return (
				<TextMessageContent
					content={content as string}
					copySpecial={copySpecial}
					translateVariables={translateVariables}
					extraData={extraData}
				/>
			)
		}
	},
	getChildContentFooter: (type: MessageType, input: ChildContentFooterInput): React.ReactElement | undefined => {
		const {
			extraData
		} = input

		if (type === "contact-card") {
			return (
				<ContactCardMessageContentFooter
					extraData={extraData as IMessageExtraData}
				/>
			)
		}

		if (type === "menu") {
			return (
				<MenuMessageContentFooter
					extraData={extraData as IMessageExtraData}
				/>
			)
		}

		if (type === "custom-link") {
			return (
				<CustomLinkMessageContentFooter
					extraData={extraData as IMessageExtraData}
				/>
			)
		}
	},
	getChildContainerFooter: (input: ChildContainerFooterInput): React.ReactElement | undefined => {
		const {
			extraData,
			sentByCustomer,
			status,
			isDefaultSize
		} = input

		if (extraData?.button && extraData?.button?.buttons?.length > 0) {
			return (
				<ButtonMessageContainerFooter
					extraData={extraData as IMessageExtraData}
					sentByCustomer={sentByCustomer as boolean}
					isDefaultSize={isDefaultSize}
					status={status}
				/>
			)
		}
	},
	getMessageContentBoxStyle: (extraData?: IMessageExtraData): React.CSSProperties | undefined => {
		if (extraData?.button && extraData?.button?.buttons?.length > 0) {
			return ButtonMessageContentBoxStyle
		}
	},
	getChildContentHeader: (input: ChildContentHeaderInput): React.ReactElement | undefined => {
		const {
			extraData,
			translateVariables,
			uploadingMedia,
			mediaUploadProgress,
			fileName,
			fancyBoxSlug,
			messageErrorCode
		} = input

		if (extraData?.header?.content) {
			return (
				<WABAMessageHeader
					header={extraData.header}
					translateVariables={translateVariables}
					uploadingMedia={uploadingMedia}
					fancyBoxSlug={fancyBoxSlug}
					mediaUploadProgress={mediaUploadProgress}
					messageErrorCode={messageErrorCode}
					fileName={fileName}
				/>
			)
		}
	}
}
