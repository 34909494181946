import { useGlobalStateStore } from "@/store/GlobalState"
import { differenceInDays, differenceInHours } from "date-fns"

export const SECOND_IN_MS = 1000

export const MINUTE_IN_MS = SECOND_IN_MS * 60

export const HOUR_IN_MS = MINUTE_IN_MS * 60

export const DAY_IN_MS = HOUR_IN_MS * 24

export const HOURS_IN_MINUTES = 60

export const DAYS_IN_MINUTES = HOURS_IN_MINUTES * 24

export const WEEK_IN_MINUTES = DAYS_IN_MINUTES * 7

export const isSameDate = (firstDate: Date, secondDate: Date) => {
	const firstDateDay = firstDate.getDate()
	const firstDateMonth = firstDate.getMonth()
	const firstDateYear = firstDate.getFullYear()

	const secondDateDay = secondDate.getDate()
	const secondDateMonth = secondDate.getMonth()
	const secondDateYear = secondDate.getFullYear()

	const isSameDay = firstDateDay === secondDateDay
	const isSameMonth = firstDateMonth === secondDateMonth
	const isSameYear = firstDateYear === secondDateYear

	const isSameDate = isSameDay && isSameMonth && isSameYear

	return isSameDate
}

export const isSameTimestamp = (firstDate: Date, secondDate: Date) => {
	const firstTimestamp = +firstDate
	const secondTimestamp = +secondDate

	const isSameTimestamp = firstTimestamp === secondTimestamp

	return isSameTimestamp
}

export const formatDateInHours = (date: Date) => {
	const hours = date.getHours()
	const minutes = date.getMinutes()

	const formattedHours = String(hours).length === 1 ? `0${hours}` : hours
	const formattedMinutes = String(minutes).length === 1 ? `0${minutes}` : minutes

	const formattedDateInHours = `${formattedHours}:${formattedMinutes}`

	return formattedDateInHours
}

export const formatDateInBrazilianDate = (date: Date) => {
	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()

	const formattedDay = day < 10 ? `0${day}` : day
	const formattedMonth = month < 10 ? `0${month}` : month

	const formattedDateInBrazilianDate = `${formattedDay}/${formattedMonth}/${year}`

	return formattedDateInBrazilianDate
}

export const getBrazilianWeekDay = (date: Date) => {
	const weekDay = date.getDay()

	const brazilianWeekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]

	return brazilianWeekDays[weekDay]
}

export const secondsToTime = (expectedSeconds: number) => {
	const hours = Math.floor(expectedSeconds / (60 * 60))

	const minutesDivisor = expectedSeconds % (60 * 60)
	const minutes = Math.floor(minutesDivisor / 60)

	const secondsDivisor = minutesDivisor % 60
	const seconds = Math.ceil(secondsDivisor)

	return {
		hours,
		minutes,
		seconds
	}
}

export const hasMoreThanDays = (date: Date, moreThanDays: number): boolean => {
	const today = new Date()
	const weekInMilliseconds = 1000 * 60 * 60 * 24 * (moreThanDays)
	return (today.getTime() - date.getTime()) > weekInMilliseconds
}

export const hasMoreThanAWeek = (date: Date): boolean => {
	return hasMoreThanDays(date, 7)
}

export const formatMessageDate = (date: Date) => {
	const formattedDateInHours = formatDateInHours(date)

	if (hasMoreThanAWeek(date)) {
		const formattedDate = formatDateInBrazilianDate(date)
		const dateWithTwoYearNumbers = formattedDate.slice(0, 6) + formattedDate.slice(8, 10)
		return `${dateWithTwoYearNumbers} - ${formattedDateInHours}`
	} else {
		const formattedDateInBrazilianWeekDay = getBrazilianWeekDay(date)

		const formattedMessageDate = `${formattedDateInBrazilianWeekDay} - ${formattedDateInHours}`
		return formattedMessageDate
	}
}

export const isToday = (someDate: Date) => {
	const today = new Date()
	return someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
}

export const isYesterday = (someDate: Date) => {
	const today = new Date()
	return someDate.getDate() === today.getDate() - 1 &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
}

export const getFormattedCounterTime = (counterTime: number) => {
	const time = secondsToTime(counterTime)

	const formattedMinutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes
	const formattedSeconds = time.seconds < 10 ? `0${time.seconds}` : time.seconds

	const formattedTime = `${formattedMinutes}:${formattedSeconds}`

	return formattedTime
}

export const timeout = async (milliseconds: number): Promise<void> => {
	await new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const fullDatetime = (date: Date) => {
	return `${formatDateInBrazilianDate(date)} às ${formatDateInHours(date)}`
}

export const translatedDaysOfWeek: Record<number, string> = {
	0: "Domingo",
	1: "Segunda",
	2: "Terça",
	3: "Quarta",
	4: "Quinta",
	5: "Sexta",
	6: "Sábado"
}

/**
 * Turns time in 'hh:mm' to Date.
 */
export const transformHourAndMinutesToDate = (hourAndMinutes: string) => {
	const pieces = hourAndMinutes?.split(":")

	if (pieces && pieces.length >= 1) {
		const hour = Number(pieces?.[0])
		const minutes = Number(pieces?.[1])

		return new Date(0, 0, 0, hour, minutes)
	}

	return null
}

export const transformDateIntoHourAndMinutes = (date: Date) => {
	const hourAndMinutes = new Intl.DateTimeFormat("pt-BR", { hour: "numeric", minute: "numeric" }).format(date)

	return hourAndMinutes
}

export const isValidDate = (date: Date) => date instanceof Date && !isNaN(Number(date))

export const getIntervalDate = (hours = 24) => {
	const now = new Date()

	const fromDate = new Date(now)
	fromDate.setHours(now.getHours() - hours)
	fromDate.setMinutes(0)
	fromDate.setSeconds(0)
	fromDate.setMilliseconds(0)

	const toDate = new Date(now)
	toDate.setHours(23)
	toDate.setMinutes(59)
	toDate.setSeconds(59)
	toDate.setMilliseconds(0)

	return {
		fromDate,
		toDate
	}
}

export function safelyCastDate<Value extends unknown> (value: Value) {
	const date = new Date(value as Date)

	const isValid = Boolean(value) && isValidDate(date)

	if (isValid) {
		return date
	} else {
		return value
	}
}

export const isMoreOrEqualThanDaysAgo = (date: Date | undefined, days: number) => {
	if (date) {
		const currentDate = new Date()
		const targetDate = new Date(date)

		const timeDifference = currentDate.getTime() - targetDate.getTime()

		const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

		return daysDifference >= days
	} else {
		return false
	}
}

/**
 * This can be useful in situations where you want to add a timeout aspect to an asynchronous operation like:
 *  - Transfer the feeling of loading data to the front
 * @param resolve
 * @param milliseconds
 */
export const promiseWithTimeout = async (resolve: () => Promise<void>, milliseconds: number) => {
	return Promise.all([
		resolve(),
		timeout(milliseconds)
	])
}

export const isHourInRange = (
	actualDate: Date,
	startTimeConfig: {
		hours: number,
		minutes: number
	},
	endTimeConfig: {
		hours: number,
		minutes: number
	}
) => {
	const startDate = new Date(actualDate)
	startDate.setHours(startTimeConfig.hours)
	startDate.setMinutes(startTimeConfig.minutes)

	const endDate = new Date(actualDate)
	endDate.setHours(endTimeConfig.hours)
	endDate.setMinutes(endTimeConfig.minutes)

	return actualDate >= startDate && actualDate <= endDate
}

export const isAbleToValidateEmail = () => {
	const globalStateStore = useGlobalStateStore()
	const userStore = globalStateStore.user

	const monthInDays = 30
	const daysSinceTheUserCreation = differenceInDays(new Date(), new Date(userStore.created_at))
	const canValidateEmail = !userStore.extra_data?.is_email_confirmed && daysSinceTheUserCreation >= monthInDays

	return canValidateEmail
}

export const getDifferenceInHours = (date: Date, compareDate: Date): number => {
	const compareTime = differenceInHours(date, compareDate)

	return compareTime
}
