import React from "react"

import { WebhookTemplateType, WebhookType } from "@/protocols/webhook"

import ActiveCampaignLogo from "@/assets/images/integrations/activecampaign.png"
import RDStationLogo from "@/assets/images/integrations/rd-station.png"
import HubSpotLogo from "@/assets/images/integrations/hubspot.png"

import ActiveCampaignIcon from "@/assets/icons/active_campaign_icon.svg"
import RDStationIcon from "@/assets/icons/rd_station.svg"
import HubspotIcon from "@/assets/icons/hubspot.svg"

import { videoLinks } from "@/utils/link"

type WebhookTemplates = {
	[key in WebhookTemplateType]: {
		logo: string
		description: string
		tutorialUrl: string
		title: string
		customStyles?: React.CSSProperties
		svgIcon?: string
	}
}

export const webhookTemplatesInfo: WebhookTemplates = {
	"active-campaign": {
		logo: ActiveCampaignLogo,
		description: "Integração com o ActiveCampaign",
		title: "Active Campaign",
		tutorialUrl: videoLinks.activeCampaignTutorial,
		svgIcon: ActiveCampaignIcon
	},
	"rd-station": {
		logo: RDStationLogo,
		description: "Integração com a RD Station",
		title: "RD Station",
		tutorialUrl: videoLinks.hotmartIntegrationTutorial,
		svgIcon: RDStationIcon
	},
	hubspot: {
		logo: HubSpotLogo,
		description: "Integração com a HubSpot",
		title: "HubSpot",
		tutorialUrl: videoLinks.hubspotIntegrationTutorial,
		svgIcon: HubspotIcon
	}
}

export const isCustomWebhook = (webhookType?: WebhookType) => {
	return webhookType === "custom"
}
