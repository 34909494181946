import React from "react"

import { Button, Grid, Typography, Divider as MuiDivider } from "@material-ui/core"
import {
	Edit as EditIcon
} from "@material-ui/icons"
import { CustomFieldValue } from "@/pages/Admin/ClientCatalog/ClientProfileInfo/CustomFields"
import useStyles from "@/components/CustomFieldsList/styles"
import { ClientProfileInfoData } from "@/protocols/clientCatalog"

type CustomFieldsListProps = {
	customFieldGroups: ClientProfileInfoData["customFieldGroups"]
	handleEditCustomFieldValue: (value: CustomFieldValue) => void
}

const CustomFieldsList: React.FC<CustomFieldsListProps> = (props) => {
	const classes = useStyles()

	const {
		customFieldGroups,
		handleEditCustomFieldValue
	} = props

	return (
		<Grid direction="column">
			<Grid item>
				<Grid style={{ marginBottom: "10px" }}>
					{customFieldGroups.map((customField, index) => {
						const { id, displayName, customFields } = customField
						const isNotLastGroup = index + 1 !== customFieldGroups.length

						return (
							<Grid key={id} item>
								<Grid container className={classes.customFieldContainer}>
									<Grid item className={classes.customFieldGroupNameSizeContainer}>
										<Typography variant="h2" color="textPrimary">{displayName.toUpperCase()}</Typography>
									</Grid>
									<Grid item className={classes.customFieldValueContainer}>
										<Grid container direction="column">
											{customFields.map((field) => {
												const { id, displayName, value } = field
												return (
													<Grid container direction="row" alignItems="center" key={id} className={classes.customField}>
														<Grid item className={classes.customFieldValueName}>
															<Typography variant="overline" ><b>{displayName}: </b>{value}</Typography>
														</Grid>
														<Grid item className={classes.editCustomFieldButton}>
															<Button className={classes.editButton} onClick={() => handleEditCustomFieldValue(field as CustomFieldValue)}>
																<EditIcon className={classes.editCustomFieldIcon} />
															</Button>
														</Grid>
													</Grid>
												)
											})}
										</Grid>
									</Grid>
								</Grid>
								{
									isNotLastGroup && (
										<MuiDivider key={id} orientation="horizontal" />
									)
								}
							</Grid>
						)
					})}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CustomFieldsList
