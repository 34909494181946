import React, { createContext, useContext, useState, useRef } from "react"
import _ from "lodash"

import useAsyncState from "@/hooks/useAsyncState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useSocket from "@/hooks/useSocket"
import { ErrorType } from "@/hooks/useValidation"

import { sortMessagesInAscendingOrder, getLastChatMessage, sortChats, buildChatHash } from "@/utils/chat"
import { isSmallScreen } from "@/utils/checkDevice"
import { runAfterReactRender } from "@/utils/node"
import { getSavedChatListFilter, saveChatListFilter } from "@/utils/chatListFilterPersistence"
import { getTotalPages } from "@/utils/table"
import { getIntervalDate } from "@/utils/time"
import { getPhoneContact } from "@/utils/contact"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import QuickReplyService from "@/services/QuickReply"
import TagService from "@/services/Tag"
import { Media } from "@/services/Media"
import UserInInstanceService from "@/services/UserInInstance"

import {
	IChat,
	IMessage,
	Attendance,
	IChatStatus,
	IChatType,
	AssignAttendanceData,
	IReactions
} from "@/protocols/channel"
import { Client, ClientCatalog, Contact } from "@/protocols/clientCatalog"
import { IQuickReply } from "@/protocols/messages"
import { DeepPartial } from "@/protocols/utility"
import { Tag } from "@/protocols/tag"
import { UserInInstance } from "@/protocols/userInInstance"

import { TextInputHandler } from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"

import { Notification } from "@/components"
import { ScrolledBottomListHandler } from "@/components/ScrolledBottomList"

import MediaValidation from "@/validations/MediaValidation"
import { Chat, Message } from "@/protocols/chatGlobalStateProtocol"
import HardCoded, { FeatureFlag } from "@/services/HardCoded"

export type ChatEarlierMessagesLoadCursor = {
	page: number
	lastLoads: Record<number, Message[]>
}

type ProcessingStepsDialog = {
	opened: boolean
	messageId: string | null
}

export type ChatListFilter = {
	attendants: Array<{ id: number, name: string }>
	tags: Tag[]
	text: string
	chatTypes: IChatType[]
	fromLastMessageTransactedDate: Date | null
	toLastMessageTransactedDate: Date | null
	fromLastMessageTransactedDateOthersTab?: Date | null
	toLastMessageTransactedDateOthersTab?: Date | null
	teams: Array<{
		id: number
		name: string
	}>
}

export type QueueChatList = {
	[key: string]: {
		exhibitionName: string
		data: Chat[]
	}
}

export type QueueChatInterfaceList = {
	type: "title" | "chat"
	// eslint-disable-next-line
	data: any | Chat
	height: number
}

export type MessagesFilterId = "all" | "unread"

export type MessagesFilterName = "Tudo" | "Não lidas"

export type MessagesFilter = {
	id: MessagesFilterId
	name: MessagesFilterName
}

type ChatStateContextData = {
	attendant: {
		list: UserInInstance[]
		listOrderedAlphabetically: UserInInstance[]
		listHash: string
		loadAllFromServer(): Promise<void>
		setup: (attendants: UserInInstance[]) => Promise<void>
		loadingFromServer: boolean
	}
	tag: {
		getById: (tagId: number) => Tag
		list: Tag[]
		listOrderedAlphabetically: Tag[]
		listHash: string
		loadAllFromServer(): Promise<void>
		setup: (tags: Tag[]) => Promise<void>
		loadingFromServer: boolean
	}
	message: {
		current: Message[]
		currentHash: string
		list: Message[]
		listByChatId: (chatId: number) => Message[]
		getById: (messageId: string) => Message
		setup: (messages: IMessage[]) => Promise<void>
		updateById: (messageId: string, data: Partial<Message>) => Promise<void>
		removeById: (messageId: string[]) => Promise<void>
		add: (message: Message) => Promise<void>
		loadAllFromServerByChatId(chatId: number): Promise<void>
		loadEarlierMessagesByChatId(chatId: number): Promise<void>
		setReadByChatId(chatId: number): Promise<void>
		processingStepsDialog: {
			open: (messageId: string) => void
			close: () => void
			current: Message | null
			messageId: string | null
			isOpened: boolean
		}
		updateMessageReactionByMessageId: (data: IReactions) => Promise<void>
	}
	chat: {
		currentOpenedId: number | null
		list: Chat[]
		listHash: string
		current: Chat | undefined
		getById: (chatId: number) => Chat
		existsById: (chatId: number) => boolean
		setup: (chats: IChat[]) => Promise<void>
		updateById: (chatId: number, data: DeepPartial<Chat>) => Promise<void>
		openById: (chatId: number) => Promise<void>
		close: () => Promise<void>
		add: (chat: IChat) => Promise<void>
		removeById: (chatId: number) => Promise<void>
		deleteById: (chatId: number) => Promise<void>
		incrementUnreadMessagesCountById: (chatId: number, incrementBy: number) => Promise<void>
		loadAllFromServer: () => Promise<void>
		loadingFromServer: boolean
		filteredChatIds: {
			overwrite: (chatIds: number[]) => Promise<void>
			add: (chatId: number) => Promise<void>
			remove: (chatId: number) => Promise<void>
			list: number[]
		}
		earlierMessages: {
			setCursor: (chatId: number, data: Partial<ChatEarlierMessagesLoadCursor>) => Promise<void>
			resetCursor: (chatId: number) => Promise<void>
			getCachedMessages: (chatId: number, page: number) => Message[] | null
			loadedInitialMessages: (chatId: number) => boolean
		}
	}
	client: {
		current: Client | undefined
		updateById: (clientId: number, data: Partial<Client>) => Promise<void>
		setup: (clients: Client[]) => Promise<void>
		setupSearchClients: (clients: Client[], count: number) => void
		getById: (clientId: number) => Client
		list: Client[]
		listSearch: Client[]
		count: number
		loadAllFromServer: () => Promise<void>
		loadCountOrSearchMatchFromServer: (search?: string) => Promise<void>
		loadingFromServer: boolean
		getEmailContact: (client: Client | undefined) => Contact | undefined
	}
	quickReply: {
		setup: (quickReplies: IQuickReply[]) => Promise<void>
		list: IQuickReply[]
		loadAllFromServer: () => Promise<void>
	}
	chatListPanel: {
		chatListFilter: {
			current: ChatListFilter
			currentHash: string
			defaultFilter: Partial<ChatListFilter>
			resetFilters: () => Promise<void>
			isDefaultFilter: () => boolean
			update: (filter: Partial<ChatListFilter>) => Promise<void>
		}
		chatStatusFilter: {
			current: IChatStatus
			currentHash: string
			change: (type: IChatStatus) => void
		},
		messagesFilter: {
			current: MessagesFilter
			change: (value: MessagesFilter) => void
		}
	}
	attendance: {
		current: Attendance | undefined
		takeOnCurrentChat: () => Promise<void>
		takeByChatId: (chatId: number) => Promise<void>
		assignOnCurrentChat: (assign: AssignAttendanceData) => Promise<void>
		assignByChatId: (chatId: number, assign: AssignAttendanceData) => Promise<void>
		finishByChatId: (chatId: number) => Promise<void>
		finishOnCurrentChat: () => Promise<void>
	}
	conversationPanel: {
		textInput: {
			ref: React.RefObject<TextInputHandler>
			focus: () => void
			value: string
			clear: () => void
			addText: (text: string) => void
		}
		scrolledBottomList: {
			ref: React.RefObject<ScrolledBottomListHandler>
			enableAutoScrollBottom: () => void
			disableAutoScrollBottom: () => void
			lockScrollTop: () => void
			unlockScrollTop: () => void
			scrollPastBeginning: {
				isPast: boolean,
				change: (totalScrollHeight: number, currentScrollHeight: number) => void
			}
		},
		media: {
			current: Media[]
			clear: () => void
			add: (medias: Media[]) => void
		},
		replyMessage: {
			current: Message | undefined
			clear: () => void
			change: (messageId: string) => void
		}
	}
}

export const EARLIER_MESSAGES_DEFAULT_PAGE = 0

export const EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE = 20

const ChatGlobalState = createContext<ChatStateContextData>({} as ChatStateContextData)

export const useChatGlobalStateStore = () => useContext(ChatGlobalState)

const featureFlagToInterval: Partial<Record<FeatureFlag, number>> = {
	canFilterChatsWithLastMessageTransactedIn24Hours: 24,
	canFilterChatsWithLastMessageTransactedIn72Hours: 72
}

const ChatGlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()

	const instanceId = globalStateStore.instance?.instance_id
	const userId = globalStateStore.user?.id

	const [scrollPastBeginning, setScrollPastBeginning] = useState<boolean>(false)

	const [chatListFilter, setChatListFilter] = useAsyncState<ChatListFilter>(() => {
		const todayIntervalDate = getIntervalDate(24)
		const activeFeatureKey = Object.keys(featureFlagToInterval).find((key) => HardCoded.checkFeatureFlag(key as FeatureFlag))
		const intervalDate = activeFeatureKey ? getIntervalDate(featureFlagToInterval[activeFeatureKey as keyof typeof featureFlagToInterval]) : null

		const fromLastMessageTransactedDate: Date | null = intervalDate?.fromDate || null
		const toLastMessageTransactedDate: Date | null = intervalDate?.toDate || null
		const fromLastMessageTransactedDateOthersTab: Date | null = todayIntervalDate?.fromDate || null
		const toLastMessageTransactedDateOthersTab: Date | null = todayIntervalDate?.toDate || null

		let filter: ChatListFilter = {
			text: "",
			tags: [],
			attendants: [{
				id: globalStateStore.user?.id,
				name: globalStateStore.user?.name
			}],
			chatTypes: [],
			fromLastMessageTransactedDate: fromLastMessageTransactedDate,
			toLastMessageTransactedDate: toLastMessageTransactedDate,
			fromLastMessageTransactedDateOthersTab: fromLastMessageTransactedDateOthersTab,
			toLastMessageTransactedDateOthersTab: toLastMessageTransactedDateOthersTab,
			teams: []
		}

		const savedChatListFilter = getSavedChatListFilter({ userId, instanceId })

		filter = {
			...filter,
			...(savedChatListFilter || {})
		}

		return filter
	})

	const [processingStepsDialog, setProcessingStepsDialog] = useAsyncState({} as ProcessingStepsDialog)

	const [currentFilteredChatIds, setCurrentFilteredChatIds] = useAsyncState<Set<number>>(new Set())
	const [chats, setChats] = useAsyncState({} as Record<number, Chat>)
	const [loadingChatsFromServer, setLoadingChatsFromServer] = useState(true)

	const [chatEarlierMessagesLoadCursor, setChatEarlierMessagesLoadCursor] = useAsyncState({} as Record<string, ChatEarlierMessagesLoadCursor>)
	const [messages, setMessages] = useAsyncState({} as Record<string, Message>)
	const [currentOpenedChatId, setCurrentOpenedChatId] = useAsyncState<number | null>(0)
	const [quickReplies, setQuickReplies] = useAsyncState({} as Record<number, IQuickReply>)

	const [attendants, setAttendants] = useAsyncState({} as Record<number, UserInInstance>)
	const [loadingAttendantsFromServer, setLoadingAttendantsFromServer] = useState(true)

	const [tags, setTags] = useAsyncState({} as Record<number, Tag>)
	const [loadingTagsFromServer, setLoadingTagsFromServer] = useState(true)

	const [clients, setClients] = useAsyncState({} as Record<number, Client>)
	const [searchClients, setSearchClients] = useState<Client[]>([])
	const [clientsCount, setClientsCount] = useState(0)
	const [loadingClientsFromServer, setLoadingClientsFromServer] = useState(true)

	const [medias, setMedias] = useState<Media[]>([])
	const [replyMessageId, setReplyMessageId] = useState<string>("")

	const [chatStatus, setChatStatus] = useState<IChatStatus>("on-going")

	const [messagesFilter, setMessagesFilter] = useState<MessagesFilter>({ id: "all", name: "Tudo" })

	const textInputRef = useRef<TextInputHandler>(null)
	const scrolledBottomListRef = useRef<ScrolledBottomListHandler>(null)

	const chatState: ChatStateContextData = {
		attendant: {
			get list (): UserInInstance[] {
				const attendantList = Object.values(attendants.current)

				return attendantList
			},
			get listOrderedAlphabetically (): UserInInstance[] {
				const attendantList = this.list

				return attendantList.sort((oldAttendant, newAttendant) => oldAttendant.name.localeCompare(newAttendant.name))
			},
			get listHash (): string {
				const hash = chatState.attendant.list.map(({ id }) => id).join("")

				return hash
			},
			async loadAllFromServer (): Promise<void> {
				const data = await UserInInstanceService.getUsersInInstance({
					type: ["human", "virtual-attendant"],
					status: ["active"]
				})

				if (data) {
					await chatState.attendant.setup(data)
				}

				setLoadingAttendantsFromServer(false)
			},
			async setup (attendants: UserInInstance[]): Promise<void> {
				await setAttendants(() => {
					const updatedAttendants: Record<number, UserInInstance> = {}

					attendants.forEach(attendant => {
						updatedAttendants[attendant.id] = attendant
					})

					return updatedAttendants
				})
			},
			get loadingFromServer (): boolean {
				return loadingAttendantsFromServer
			}
		},
		tag: {
			getById (tagId: number): Tag {
				return tags.current[tagId]
			},
			get list (): Tag[] {
				const tagList = Object.values(tags.current)

				return tagList
			},
			get listOrderedAlphabetically (): Tag[] {
				const tagList = this.list

				return tagList.sort((oldTag, newTag) => oldTag.name.localeCompare(newTag.name))
			},
			get listHash (): string {
				const hash = chatState.tag.list.map(({ id }) => id).join("")

				return hash
			},
			async loadAllFromServer (): Promise<void> {
				const data = await TagService.getTags()

				if (data) {
					await chatState.tag.setup(data)
				}

				setLoadingTagsFromServer(false)
			},
			async setup (tags: Tag[]): Promise<void> {
				await setTags(() => {
					const updatedTags = {} as Record<number, Tag>

					tags.forEach(tag => {
						updatedTags[tag.id] = tag
					})

					return updatedTags
				})
			},
			get loadingFromServer (): boolean {
				return loadingTagsFromServer
			}
		},
		attendance: {
			get current (): Attendance | undefined {
				const currentAttendance = chatState.chat.current?.attendance

				return currentAttendance
			},

			async takeByChatId (chatId: number): Promise<void> {
				const chat = chatState.chat.getById(chatId)

				if (!chat) {
					return
				}

				const oldChatStatus = chat.status
				const { id, inboxChannelId, channelType } = chat

				await chatState.chat.updateById(id, {
					status: "on-going",
					attendance: {
						assignUserId: null,
						assignTeamId: null,
						assignmentQueueType: null,
						userId: globalStateStore.user?.id,
						userName: globalStateStore.user?.name,
						createdAt: String(new Date()),
						status: "active"
					}
				})

				chatState.chatListPanel.chatStatusFilter.change("on-going")
				chatState.conversationPanel.textInput.focus()
				chatState.message.setReadByChatId(id)

				await socket.takeAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao assumir o atendimento, tente novamente." })
						chatState.chat.updateById(id, {
							status: oldChatStatus,
							attendance: {
								userId: null,
								userName: ""
							}
						})
					}
				})
			},
			async takeOnCurrentChat (): Promise<void> {
				return await chatState.attendance.takeByChatId(chatState.chat.current?.id as number)
			},

			async assignByChatId (chatId, assign): Promise<void> {
				const chat = chatState.chat.getById(chatId)
				const actualAttendance = chatState.attendance.current

				/**
				 * Rollback must be a fixed variable
				 * If the state is referenced directly, state values will change
				 * and old values wont exist when doing rollback update
				*/
				const rollback: Chat = {
					...chat,
					attendance: {
						...actualAttendance
					}
				}

				if (!chat) {
					return
				}

				const { id, inboxChannelId, channelType } = chat

				socket.assignAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id,
					assignedUserId: assign.assignedUserId,
					assignedTeamId: assign.assignedTeamId,
					assignmentQueueType: assign.assignmentQueueType,
					assignmentObservation: assign.assignmentObservation,
					wasStartedBy: "assign"
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao transferir o atendimento, tente novamente." })
						chatState.chat.updateById(id, rollback)
					}
				})

				await chatState.chat.updateById(id, {
					status: "queue",
					attendance: {
						status: "waiting",
						userName: globalStateStore.user?.name,
						createdAt: String(new Date())
					}
				})
			},
			async assignOnCurrentChat (assign): Promise<void> {
				return await chatState.attendance.assignByChatId(chatState.chat.current?.id as number, assign)
			},

			async finishByChatId (chatId: number): Promise<void> {
				const chat = chatState.chat.getById(chatId)

				if (!chat) {
					return
				}

				const { id, inboxChannelId, channelType } = chat
				const { userName, userId } = chat.attendance

				await chatState.chat.updateById(id, {
					status: "archived",
					attendance: {
						userName: "",
						userId: null,
						assignTeamId: null,
						assignUserId: null,
						assignmentQueueType: null,
						status: "finished"
					}
				})

				await socket.finishAttendance({
					channelType,
					inboxChannelId,
					inboxChannelChatId: id
				}).then((success) => {
					if (!success) {
						Notification.error({ message: "Houve um erro ao finalizar o atendimento, tente novamente." })
						chatState.chat.updateById(id, {
							status: chat.status,
							attendance: {
								userId: userId,
								userName: userName,
								status: "active"
							}
						})
					}
				})
			},
			async finishOnCurrentChat (): Promise<void> {
				return await chatState.attendance.finishByChatId(chatState.chat.current?.id as number)
			}
		},
		message: {
			get currentHash (): string {
				const hash = chatState.message.current.map(message => message.id).join("")

				return hash
			},
			async setReadByChatId (chatId: number): Promise<void> {
				const chat = chatState.chat.getById(chatId)

				if (!chat) {
					return
				}
				/**
				 * The user only can set messages read on a chat
				 * it has an on-going attendance, since the attendance queue
				 * is not owned by any customer.
				 */
				const isQueueChat = chat.status === "queue"
				const chatHasUnreadMessages = Boolean(chat.unreadMessagesCount)

				if (chatHasUnreadMessages && !isQueueChat) {
					socket.setReadMessages({
						channelType: chat.channelType,
						inboxChannelId: chat.inboxChannelId,
						inboxChannelChatId: chat.id
					})

					await chatState.chat.updateById(chatId, {
						unreadMessagesCount: 0
					})
				}
			},
			get list (): Message[] {
				const messageList = Object.values(messages.current).sort(sortMessagesInAscendingOrder)

				return messageList
			},
			listByChatId (chatId: number): Message[] {
				const chatMessages = Object.values(messages.current)
					.filter(message => message.inboxChannelChatId === chatId)
					.sort(sortMessagesInAscendingOrder)

				return chatMessages
			},
			async removeById (messageIds: string[]): Promise<void> {
				setMessages(lastState => {
					const updatedState = _.omit(lastState, messageIds)

					return updatedState
				})
			},
			async loadEarlierMessagesByChatId (chatId): Promise<void> {
				try {
					const chat = chats.current[chatId]

					const chatExists = Boolean(chat)
					const alreadyLoadedAllEarlierMessages = Boolean(chat?.fullyLoadedAllEarlierMessages)
					const alreadyLoadingEarlierMessages = Boolean(chat?.loadingEarlierMessages)

					const canLoadEarlierMessages = chatExists && !alreadyLoadedAllEarlierMessages && !alreadyLoadingEarlierMessages

					if (!canLoadEarlierMessages) {
						return
					}

					chatState.chat.updateById(chatId, { loadingEarlierMessages: true })

					const cursor = chatEarlierMessagesLoadCursor.current[chatId] || { page: EARLIER_MESSAGES_DEFAULT_PAGE, lastLoads: {} }

					const response = await ApiService.get("/inbox/channel/chat/messages", {
						params: {
							inboxChannelChatId: chat.id,
							inboxChannelId: chat.inboxChannelId,
							instanceId,
							rowsPerPage: EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE,
							page: cursor.page
						}
					})

					const inboxChannelChatMessages = response.data.inboxChannelChatMessages
					const count = response.data.count

					const totalPages = getTotalPages(count, EARLIER_MESSAGES_DEFAULT_ROWS_PER_PAGE)
					const nextPage = cursor.page + 1
					const fullyLoadedAllEarlierMessages = nextPage >= totalPages

					chatState.chat.updateById(chat.id, {
						fullyLoadedAllEarlierMessages,
						loadingEarlierMessages: false
					})

					chatState.conversationPanel.scrolledBottomList.lockScrollTop()

					chatState.message.setup(inboxChannelChatMessages).then(() => {
						chatState.conversationPanel.scrolledBottomList.unlockScrollTop()
					})

					chatState.chat.earlierMessages.setCursor(chatId, {
						page: nextPage,
						lastLoads: {
							[cursor.page]: inboxChannelChatMessages
						}
					})
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			},
			async loadAllFromServerByChatId (chatId: number): Promise<void> {
				try {
					const chat = chats.current[chatId]

					if (!chat) {
						return
					}

					const response = await ApiService.get("/inbox/channel/chat/messages", {
						params: {
							inboxChannelChatId: chat.id,
							inboxChannelId: chat.inboxChannelId,
							instanceId
						}
					})

					const inboxChannelChatMessages: IMessage[] = response.data.inboxChannelChatMessages

					chatState.message.setup(inboxChannelChatMessages)

					chatState.chat.updateById(chat.id, { fullyLoadedAllEarlierMessages: true })
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			},
			get current (): Message[] {
				const currentMessages = Object.values(messages.current)
					.filter(message => message.inboxChannelChatId === currentOpenedChatId.current)
					.sort(sortMessagesInAscendingOrder)

				return currentMessages
			},
			getById (messageId: string): Message {
				const message = messages.current[messageId]

				return message
			},
			async setup (messages: IMessage[]): Promise<void> {
				await setMessages(lastState => {
					const updatedMessages = { ...lastState }

					const uniqueUpdatedChatIds = new Set<number>()

					messages.forEach(message => {
						uniqueUpdatedChatIds.add(message.inboxChannelChatId)

						updatedMessages[message.id] = {
							...message,
							uploadingMedia: false
						}
					})

					uniqueUpdatedChatIds.forEach(chatId => {
						setChats(lastState => {
							const updatedChats = { ...lastState }

							if (updatedChats[chatId]) {
								updatedChats[chatId].lastMessage = getLastChatMessage(chatId, updatedMessages)
								updatedChats[chatId].syncingMessages = false
							}

							return updatedChats
						})
					})

					return updatedMessages
				})
			},
			async updateById (messageId: string, data: Partial<Message>): Promise<void> {
				await setMessages(lastState => {
					const updatedMessages = { ...lastState }

					updatedMessages[messageId] = _.merge(updatedMessages[messageId], data)

					return updatedMessages
				})
			},
			async add (message: Message): Promise<void> {
				await setMessages(lastState => {
					const updatedState = { ...lastState }

					updatedState[message.id] = message

					setChats(lastState => {
						const updatedChats = { ...lastState }

						if (updatedChats[message.inboxChannelChatId]) {
							updatedChats[message.inboxChannelChatId].lastMessage = getLastChatMessage(message.inboxChannelChatId, updatedState)
						}

						return updatedChats
					})

					return updatedState
				})
			},
			processingStepsDialog: {
				open (messageId: string): void {
					setProcessingStepsDialog(() => ({ opened: true, messageId }))
				},
				close (): void {
					setProcessingStepsDialog(lastState => ({ ...lastState, opened: false }))
				},
				get current (): Message | null {
					if (processingStepsDialog.current.messageId) {
						return chatState.message.getById(processingStepsDialog.current.messageId)
					}

					return null
				},
				get isOpened (): boolean {
					return Boolean(processingStepsDialog.current.opened)
				},
				get messageId (): string | null {
					return processingStepsDialog.current.messageId
				}
			},
			/**
			 * When unreacting to a message, the data returned is an empty array. However, when using lodash's _.merge, the empty array is ignored,
			 * which results in the reaction remaining on the message, even after removal. To get around this problem, it was necessary to use the _.mergeWith method,
			 * which allows you to create a specific approach to deal with empty arrays.
			 */
			async updateMessageReactionByMessageId (data: IReactions): Promise<void> {
				const {
					messageId,
					reactions
				} = data

				await setMessages(lastState => {
					const updatedMessages = { ...lastState }

					updatedMessages[messageId] = _.mergeWith(updatedMessages[messageId], {
						extraData: {
							reactions
						}
					}, (oldValue, actualValue) => {
						if (_.isArray(oldValue)) {
							return actualValue
						}
					})

					return updatedMessages
				})
			}
		},
		chat: {
			existsById (chatId: number): boolean {
				const chat = chats.current[chatId]

				const exists = Boolean(chat)

				return exists
			},
			get currentOpenedId (): number | null {
				return currentOpenedChatId.current
			},
			get list (): Chat[] {
				const ids = Array.from(currentFilteredChatIds.current)
				const currentFilteredChats = Object.values(chats.current).filter(({ id }) => {
					return ids.includes(id)
				})

				const chatList = currentFilteredChats.sort(sortChats())
				return chatList as Chat[]
			},
			get listHash (): string {
				const hash = chatState.chat.list.map(chat => buildChatHash(chat)).join("")

				return hash
			},
			get current (): Chat | undefined {
				if (currentOpenedChatId.current) {
					const currentChat = chatState.chat.getById(currentOpenedChatId.current)

					return currentChat
				}

				return undefined
			},
			getById (chatId: number): Chat {
				const chat = chats.current[chatId]
				const client = clients.current[chat?.client?.id]

				return {
					...chat,
					title: client?.nickname || chat?.title
				}
			},
			async setup (newChats: IChat[]): Promise<void> {
				/**
				 * Avoid falsy values
				 */
				const validChats = newChats.filter((chat) => chat)
				const clients = newChats.map(chat => chat.client)

				await chatState.client.setup(clients)
				await setChats(lastChats => {
					const updatedChats = { ...lastChats }

					const inboxChannelChatMessages = validChats.reduce<IMessage[]>(
						(messages, chat) => [...chat.messages, ...messages],
						[]
					)

					setMessages(lastMessages => {
						const updatedMessages = { ...lastMessages }

						inboxChannelChatMessages.forEach(message => {
							updatedMessages[message.id] = {
								...message,
								uploadingMedia: false
							}
						})

						validChats.forEach(chat => {
							const existingChat = updatedChats[chat.id]

							updatedChats[chat.id] = {
								...chat,
								unreadMessagesCount: chat.consolidatedData?.unreadMessagesCount,
								fullyLoadedAllEarlierMessages: existingChat?.fullyLoadedAllEarlierMessages ?? false,
								loadingEarlierMessages: existingChat?.loadingEarlierMessages ?? false
							}

							const lastChatMessage = getLastChatMessage(chat.id, updatedMessages)

							updatedChats[chat.id].lastMessage = lastChatMessage
						})

						return updatedMessages
					})

					return updatedChats
				})
			},
			async updateById (chatId: number, data: DeepPartial<Chat>): Promise<void> {
				await setChats(lastState => {
					const updatedChats = { ...lastState }

					updatedChats[chatId] = _.merge(updatedChats[chatId], data)

					return updatedChats
				})
			},
			async openById (chatId: number): Promise<void> {
				chatState.conversationPanel.scrolledBottomList.enableAutoScrollBottom()

				chatState.chat.updateById(chatId, {
					consolidatedData: {
						markedUnread: false
					},
					extraData: {
						awakeningSnooze: false
					}
				})

				const lastOpenedChatId = currentOpenedChatId.current

				if (lastOpenedChatId) {
					chatState.chat.earlierMessages.resetCursor(lastOpenedChatId)
				}

				setCurrentOpenedChatId(() => chatId)

				chatState.message.setReadByChatId(chatId)

				chatState.conversationPanel.replyMessage.clear()
				chatState.conversationPanel.media.clear()

				/**
				 * Only open chat focusing on input when dealing with desktop device,
				 * since the UX on mobile is pretty bad.
				 */
				if (!isSmallScreen) {
					chatState.conversationPanel.textInput.focus()
				}

				const selectedChat = chatState.chat.getById(chatId)
				const isSearchActive = Boolean(chatState.chatListPanel.chatListFilter.current.text)

				if (selectedChat) {
					/**
					 * That's a rule to change current attendance type filter
					 * in case this selected chat was got during a search, since
					 * on searches we disable filtering chats by attendance type
					 * in order to get all chats.
					 */
					if (!isSearchActive && selectedChat.status !== chatState.chatListPanel.chatStatusFilter.current) {
						chatState.chatListPanel.chatStatusFilter.change(selectedChat.status)
					}

					const selectedChatAlreadyLoadedInitialMessages = chatState.chat.earlierMessages.loadedInitialMessages(chatId)
					const selectedChatAlreadyLoadedAllMessages = selectedChat?.fullyLoadedAllEarlierMessages
					const canLoadSelectedChatInitialMessages = !selectedChatAlreadyLoadedAllMessages && !selectedChatAlreadyLoadedInitialMessages

					if (canLoadSelectedChatInitialMessages) {
						chatState.message.loadEarlierMessagesByChatId(selectedChat?.id as number)
					}
				}
			},
			async close (): Promise<void> {
				await setCurrentOpenedChatId(() => null)
			},
			async add (chat: IChat): Promise<void> {
				/**
				 * Promise.allSettled was used to solve a bug where sometimes one promise return and blocks the other promise return's.
				 * It was causing errors on state update
				*/
				await Promise.allSettled([
					chatState.chat.setup([chat]),
					chatState.chat.filteredChatIds.add(chat.id)
				])
			},
			async removeById (chatId: number): Promise<void> {
				await setChats(lastState => {
					const updatedState = { ...lastState }

					delete updatedState[chatId]

					return updatedState
				})
				await chatState.chat.filteredChatIds.remove(chatId)
			},
			async deleteById (chatId: number): Promise<void> {
				try {
					await ApiService.delete(`/inbox/channel/chats/${chatId}`)
					this.removeById(chatId)
				} catch (err) {
					ErrorHandlerService.handle(err as ErrorType)
					Notification.error({ message: "Não é possível excluir o chat enquanto o atendimento estiver em andamento" })
				}
			},
			async incrementUnreadMessagesCountById (chatId: number, incrementBy: number): Promise<void> {
				await setChats(lastState => {
					const updatedState = { ...lastState }

					if (updatedState[chatId]) {
						const unreadMessagesCount = updatedState[chatId]?.unreadMessagesCount

						updatedState[chatId].unreadMessagesCount = (unreadMessagesCount || 0) + incrementBy
					}

					return updatedState
				})
			},
			async loadAllFromServer (): Promise<void> {
				try {
					setLoadingChatsFromServer(true)

					const userIds = chatState.chatListPanel.chatListFilter.current.attendants.map(({ id }) => id).join(",")
					const tagIds = chatState.chatListPanel.chatListFilter.current.tags.map(({ id }) => id).join(",")
					const chatTypes = chatState.chatListPanel.chatListFilter.current.chatTypes.join(",")
					const text = chatState.chatListPanel.chatListFilter.current.text
					const fromLastMessageTransactedDate = chatState.chatListPanel.chatListFilter.current.fromLastMessageTransactedDate
					const toLastMessageTransactedDate = chatState.chatListPanel.chatListFilter.current.toLastMessageTransactedDate
					const fromLastMessageTransactedDateOthersTab = chatState.chatListPanel.chatListFilter.current.fromLastMessageTransactedDateOthersTab
					const toLastMessageTransactedDateOthersTab = chatState.chatListPanel.chatListFilter.current.toLastMessageTransactedDateOthersTab

					const response = await ApiService.get("/inbox/channel/chats-with-messages", {
						params: {
							instanceId,
							userIds,
							tagIds,
							chatTypes,
							text,
							fromLastMessageTransactedDate,
							toLastMessageTransactedDate,
							fromLastMessageTransactedDateOthersTab,
							toLastMessageTransactedDateOthersTab
						}
					})

					const newChats: IChat[] = response.data.inboxChannelChats

					await chatState.chat.setup(newChats)

					const newChatIds = newChats.map(({ id }) => id)

					await chatState.chat.filteredChatIds.overwrite(newChatIds)

					setLoadingChatsFromServer(false)
				} catch (error) {
					if ((error as ErrorType)?.response?.data?.codeMessages?.text === "number_of_characters_less_than_three") {
						Notification.warning({
							message: "Você deve inserir mais de 3 caracteres."
						})
					}

					ErrorHandlerService.handle(error as ErrorType)
					setLoadingChatsFromServer(false)
				}
			},
			get loadingFromServer (): boolean {
				return loadingChatsFromServer
			},
			filteredChatIds: {
				get list (): number[] {
					return Array.from(currentFilteredChatIds.current.values())
				},
				async overwrite (chatIds: number[]): Promise<void> {
					await setCurrentFilteredChatIds(() => {
						const newFilteredChatIds = new Set<number>()

						chatIds.map(chatId => newFilteredChatIds.add(chatId))

						return newFilteredChatIds
					})
				},
				async add (chatId: number): Promise<void> {
					await setCurrentFilteredChatIds((filteredChatIds) => {
						filteredChatIds.add(chatId)

						return filteredChatIds
					})
				},
				async remove (chatId: number): Promise<void> {
					await setCurrentFilteredChatIds((filteredChatIds) => {
						filteredChatIds.delete(chatId)

						return filteredChatIds
					})
				}
			},
			earlierMessages: {
				async setCursor (chatId: number, data: Partial<ChatEarlierMessagesLoadCursor>): Promise<void> {
					await setChatEarlierMessagesLoadCursor(lastState => {
						const updatedCursors = { ...lastState }

						if (!updatedCursors[chatId]) {
							updatedCursors[chatId] = {
								page: EARLIER_MESSAGES_DEFAULT_PAGE,
								lastLoads: {}
							}
						}

						updatedCursors[chatId] = _.merge(updatedCursors[chatId], data)

						return updatedCursors
					})
				},
				async resetCursor (chatId: number): Promise<void> {
					await setChatEarlierMessagesLoadCursor(lastState => {
						const updatedCursors = { ...lastState }

						const oldCursor = updatedCursors[chatId]

						if (oldCursor) {
							const pageToKeepCached = 0

							const firstPageLoaded = oldCursor?.lastLoads?.[pageToKeepCached]

							const firstPageWasAlreadyLoaded = Boolean(firstPageLoaded)

							if (firstPageWasAlreadyLoaded) {
								const lastLoadedMessage = firstPageLoaded[firstPageLoaded.length - 1]

								if (lastLoadedMessage) {
									const chatMessages = chatState.message.listByChatId(chatId)

									const messageIdsToRemove = chatMessages
										.filter(chatMessage => new Date(chatMessage.createdAt) < new Date(lastLoadedMessage?.createdAt))
										.map(chatMessage => chatMessage.id)

									/**
									 * WARNING:
									 *  - Only remove messages if there is more than one message to be removed,
									 * since sometimes it happens for this setState to run multiple times in a row,
									 * and in case we remove only one message, the chat message list does not scroll
									 * correctly to bottom when the chat is opened.
									 */
									if (messageIdsToRemove.length > 1) {
										chatState.message.removeById(messageIdsToRemove).then(() => chatState.conversationPanel.scrolledBottomList.enableAutoScrollBottom)
										chatState.chat.updateById(chatId, { fullyLoadedAllEarlierMessages: false })
									}
								}
							}

							const resetCursor: ChatEarlierMessagesLoadCursor = {
								page: pageToKeepCached + 1,
								lastLoads: {}
							}

							updatedCursors[chatId] = resetCursor
						}

						return updatedCursors
					})
				},
				getCachedMessages (chatId: number, page: number): Message[] | null {
					const cursor = chatEarlierMessagesLoadCursor.current[chatId]

					if (cursor?.lastLoads?.[page]) {
						return _.cloneDeep(cursor.lastLoads[page])
					}

					return null
				},
				loadedInitialMessages (chatId: number): boolean {
					const cursor = chatEarlierMessagesLoadCursor.current[chatId]

					if (cursor) {
						return cursor.page > 0
					}

					return false
				}
			}
		},
		client: {
			async loadAllFromServer (): Promise<void> {
				try {
					const response = await ApiService.get("/clients/full")

					const data = response.data as ClientCatalog

					const onlyClientsWithPhone = data.clients?.filter(client => {
						const phoneContact = getPhoneContact(client)

						return Boolean(phoneContact)
					})

					await chatState.client.setup(onlyClientsWithPhone || [])
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}

				setLoadingClientsFromServer(false)
			},
			async loadCountOrSearchMatchFromServer (search?: string): Promise<void> {
				setLoadingClientsFromServer(true)

				try {
					const response = await ApiService.get(
						"/clients/count-or-search",
						{
							params: {
								search
							}
						}
					)
					const data = response.data as ClientCatalog

					const onlyClientsWithPhone = data.clients?.filter(client => {
						const phoneContact = getPhoneContact(client)

						return Boolean(phoneContact)
					})

					chatState.client.setupSearchClients(onlyClientsWithPhone || [], data.count || 0)

					await chatState.client.setup(onlyClientsWithPhone || [])
				} catch (error) {
					if ((error as ErrorType)?.response?.data?.codeMessages.search === "number_of_characters_less_than_four") {
						Notification.warning({
							message: "Você deve inserir mais de 4 caracteres."
						})
					}

					ErrorHandlerService.handle(error as ErrorType)
				}

				setLoadingClientsFromServer(false)
			},
			get current (): Client | undefined {
				const currentClientId = chatState.chat.current?.client?.id

				if (currentClientId) {
					const client = chatState.client.getById(currentClientId)

					return client
				}

				return undefined
			},
			async updateById (clientId: number, data: Partial<Client>): Promise<void> {
				await setClients(lastState => {
					const updatedState = { ...lastState }

					updatedState[clientId] = _.mergeWith(updatedState[clientId], data, (oldValue, newValue) => {
						/**
						 * That's a workaround to correctly update the 'tagIds' field inside client, since
						 * it is an array and lodash do not know how to handle it. With that rule, the
						 * new value updated on 'tagIds' will overwrite the old value.
						 */
						if (_.isArray(oldValue) && _.isArray(newValue)) {
							return newValue
						}
					})

					return updatedState
				})
			},
			async setup (clients: Client[]): Promise<void> {
				await setClients(lastState => {
					const updatedState = { ...lastState }

					/**
					 * Avoid falsy clients
					 */
					const validClients = clients.filter(client => client)

					validClients.forEach(client => {
						updatedState[client.id] = client
					})

					return updatedState
				})
			},
			setupSearchClients (clients: Client[], count: number): void {
				setClientsCount(count)

				/**
				 * Avoid falsy clients
				 */
				const validClients = clients.filter(client => client)

				if (validClients.length !== 0) {
					setClientsCount(validClients.length)
				}

				setSearchClients(validClients)
			},
			getById (clientId: number): Client {
				const client = clients.current[clientId]

				return client
			},
			get list (): Client[] {
				const clientList = Object.values(clients.current)

				return clientList
			},
			get count (): number {
				return clientsCount
			},
			get listSearch (): Client[] {
				return searchClients
			},
			getEmailContact (client: Client | undefined): Contact | undefined {
				const emailContact = client?.contacts?.find(contact => (
					contact.channel_type === "email"
				))

				return emailContact
			},
			get loadingFromServer (): boolean {
				return loadingClientsFromServer
			}
		},
		quickReply: {
			async setup (quickReplies: IQuickReply[]): Promise<void> {
				await setQuickReplies(() => {
					const updatedState: Record<number, IQuickReply> = {}

					quickReplies.forEach(quickReply => {
						updatedState[quickReply.id] = quickReply
					})

					return updatedState
				})
			},
			get list (): IQuickReply[] {
				const quickReplyList = Object.values(quickReplies.current)

				return quickReplyList
			},
			async loadAllFromServer (): Promise<void> {
				const quickReplies = await QuickReplyService.getAll()

				chatState.quickReply.setup(quickReplies)
			}
		},
		chatListPanel: {
			chatListFilter: {
				get current (): ChatListFilter {
					return chatListFilter.current
				},
				get currentHash (): string {
					const hash = JSON.stringify(chatState.chatListPanel.chatListFilter.current)

					return hash
				},
				get defaultFilter () {
					return {
						attendants: [{
							id: globalStateStore.user.id,
							name: globalStateStore.user.name
						}],
						tags: [],
						chatTypes: [],
						teams: []
					}
				},
				async resetFilters () {
					await setChatListFilter(lastState => {
						const updatedData = {
							...lastState,
							...(this.defaultFilter || {})
						}

						saveChatListFilter({
							userId,
							instanceId,
							chatListFilter: updatedData
						})

						return updatedData
					})

					await chatState.chat.loadAllFromServer()
				},
				isDefaultFilter () {
					const defaultFilterSettings = Object.keys(this.defaultFilter)

					const savedChatListFilter = getSavedChatListFilter({ userId, instanceId })
					const stateChatListFilter = chatState.chatListPanel.chatListFilter.current
					const savedChatListFilterRemovalKeys = Object.keys(savedChatListFilter || {}).filter(savedChatListFilterKey => !defaultFilterSettings.includes(savedChatListFilterKey))
					const stateChatListFilterRemovalKeys = Object.keys(stateChatListFilter || {}).filter(stateChatListFilterKey => !defaultFilterSettings.includes(stateChatListFilterKey))

					const savedChatListFilterFormatted = _.omit(savedChatListFilter, savedChatListFilterRemovalKeys)
					const stateChatListFilterFormatted = _.omit(stateChatListFilter, stateChatListFilterRemovalKeys)

					const isStateSync = _.isEqual(savedChatListFilterFormatted, stateChatListFilterFormatted)
					const isDefaultFilter = _.isEqual(savedChatListFilterFormatted, this.defaultFilter)

					return Boolean(isStateSync && isDefaultFilter)
				},
				async update (filter: Partial<ChatListFilter>): Promise<void> {
					await setChatListFilter(lastState => {
						const updatedData = {
							...lastState,
							...(filter || {})
						}

						saveChatListFilter({
							userId,
							instanceId,
							chatListFilter: updatedData
						})

						return updatedData
					})
				}
			},
			chatStatusFilter: {
				get current () {
					return chatStatus
				},
				get currentHash (): string {
					const hash = JSON.stringify(chatState.chatListPanel.chatStatusFilter.current)

					return hash
				},
				change (status: IChatStatus): void {
					setChatStatus(status)
				}
			},
			messagesFilter: {
				get current (): MessagesFilter {
					return messagesFilter
				},
				change (filter: MessagesFilter): void {
					setMessagesFilter(filter)
				}
			}
		},
		conversationPanel: {
			textInput: {
				get ref (): React.RefObject<TextInputHandler> {
					return textInputRef
				},
				get value (): string {
					return textInputRef.current?.getCurrentValue() || ""
				},
				clear (): void {
					textInputRef.current?.clear()
				},
				focus (): void {
					runAfterReactRender(() => {
						textInputRef.current?.focus()
					})
				},
				addText (text: string): void {
					textInputRef.current?.addText(text)
				}
			},
			scrolledBottomList: {
				get ref (): React.RefObject<ScrolledBottomListHandler> {
					return scrolledBottomListRef
				},
				enableAutoScrollBottom (): void {
					scrolledBottomListRef.current?.enableAutoScrollBottom()
				},
				disableAutoScrollBottom (): void {
					scrolledBottomListRef.current?.disableAutoScrollBottom()
				},
				lockScrollTop (): void {
					scrolledBottomListRef.current?.lockScrollTop()
				},
				unlockScrollTop (): void {
					scrolledBottomListRef.current?.unlockScrollTop()
				},
				scrollPastBeginning: {
					get isPast (): boolean {
						return scrollPastBeginning
					},
					change: (totalScrollHeight, currentScrollHeight) => {
						const scrollTolerance = 1000
						const isScrollPastBeginning = (totalScrollHeight - scrollTolerance) > currentScrollHeight

						setScrollPastBeginning(isScrollPastBeginning)
					}
				}
			},
			media: {
				add (medias: Media[]): void {
					MediaValidation.validateMedia(medias?.[0], () => {
						setMedias(lastState => ([
							...lastState,
							...medias
						]))
					})
				},
				get current (): Media[] {
					return medias
				},
				clear (): void {
					setMedias([])
				}
			},
			replyMessage: {
				change (messageId: string): void {
					setReplyMessageId(messageId)
				},
				get current (): Message {
					const currentReplyMessage = messages.current[replyMessageId]

					return currentReplyMessage
				},
				clear () {
					setReplyMessageId("")
				}
			}
		}
	}

	ChatGlobalState.displayName = "ChatGlobalState"

	return (
		<ChatGlobalState.Provider
			value={chatState}
		>
			{children}
		</ChatGlobalState.Provider>
	)
}

export default ChatGlobalStateProvider
