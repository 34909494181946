import React from "react"

import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const UpsellQuantityDialogSkeleton = () => {
	return (
		<Grid
			container
			style={{
				height: "100%"
			}}
		>
			<Grid
				item
				xs={8}
			>
				<Grid
					container
					direction="column"
					justifyContent="space-between"
					style={{
						height: "100%",
						padding: "8px 24px"
					}}
				>
					<Grid
						item
						style={{
							flex: 1
						}}
					>
						<Grid
							container
							direction="column"
						>
							<Grid
								item
							>
								<Skeleton
									variant="text"
									height={30}
									width="70%"
								/>
							</Grid>
							<Grid
								item
							>
								<Skeleton
									variant="text"
									height={100}
									width="80%"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs="auto"
						style={{
							flex: 2
						}}
					>
						<Grid
							container
							direction="column"
							spacing={1}
						>
							<Grid
								item
							>
								{Array.from(new Array(4)).map((_, index) => (
									<Skeleton
										key={index}
										variant="text"
										height={45}
										width="80%"
									/>
								))}
							</Grid>

							<Grid
								item
							>
								<Grid
									container
									direction="column"
								>
									<Grid
										item
									>
										<Grid
											container
											alignItems="center"
										>
											<Skeleton
												variant="rect"
												height={75}
												width="70%"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
					>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={12}
							>
								<Grid
									container
									justifyContent="flex-start"
								>
									<Grid
										item
										xs={10}
									>
										<Skeleton
											variant="rect"
											height={50}
											width="100%"
										/>
									</Grid>
									<Grid
										item
										xs={10}
									>
										<Skeleton
											variant="text"
											height={25}
											width="100%"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={10}
							>
								<Grid
									container
									style={{
										justifyContent: "center"
									}}
								>
									<Skeleton
										variant="rect"
										height={25}
										width="50%"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				xs={4}
			>
				<Skeleton
					variant="rect"
					height="100%"
					width="100%"
				/>
			</Grid>
		</Grid>
	)
}

export default UpsellQuantityDialogSkeleton
