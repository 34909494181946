import React, { forwardRef, useImperativeHandle, useState, useRef } from "react"
import clsx from "clsx"
import {
	Button,
	Grid,
	IconButton,
	Tooltip,
	ButtonBase,
	InputAdornment
} from "@material-ui/core"
import {
	AddCircleOutline as CreateMessageIcon,
	Delete as DeleteIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import MediaPreview from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview"
import VoiceInput from "@/pages/Attendance/Chat/ConversationPanel/Input/VoiceInput"
import FileInput from "@/pages/Attendance/Chat/ConversationPanel/Input/FileInput"
import TextInput, { TextInputHandler } from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"
import EmojiPicker from "@/pages/Attendance/Chat/ConversationPanel/Input/EmojiPicker"
import VariableInput, { Variable } from "@/components/ChatMessageBuilder/VariableInput"
import QuickReply from "@/pages/Attendance/Chat/ConversationPanel/Input/QuickReply"
import MessageItem from "@/components/ChatMessageBuilder/MessageItem"
import { ScrolledBottomListHandler } from "@/components/ScrolledBottomList"
import TooMuchMessagesWarningDialog from "@/components/ChatMessageBuilder/TooMuchMessagesWarningDialog"

import { generateUUID } from "@/utils/id"
import { isMediaMessage } from "@/utils/message"
import { runAfterReactRender } from "@/utils/node"

import {
	Divider,
	PopConfirm,
	ScrolledBottomList
} from "@/components"

import MediaService, { Media } from "@/services/Media"

import MediaValidation from "@/validations/MediaValidation"

import { BuildedMessage } from "@/protocols/messages"

import useDidMount from "@/hooks/useDidMount"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/components/ChatMessageBuilder/styles"
import useAsyncState from "@/hooks/useAsyncState"
import useElementSize from "@/hooks/useElementSize"
import Mentions from "@/components/ChatMessageBuilder/Mentions"
import { MessageOptionsType } from "@/pages/Attendance/Chat/ConversationPanel/Input"
import { IMessageExtraData } from "@/protocols/channel"
import HardCoded from "@/services/HardCoded"
import { useGlobalStateStore } from "@/store/GlobalState"
import _ from "lodash"
import TextEnhancementButton from "@/components/Skynet/TextEnhancementButton"
import ChatGlobalStateProvider from "@/store/ChatGlobalState"

export type Message = {
	id: string
	order: number
	type: "text" | "picture" | "audio" | "video" | "file" | "button" | "custom-link"
	content: string
	mediaName?: string
	mediaKey?: string
	mediaUrl?: string
	status?: "CREATED" | "UPDATED" | "DELETED" | "ORDERED"
	extraData?: IMessageExtraData
}

export type ChatMessageBuilderHandler = {
	getMessages: () => Message[]
	blur: () => void
	createMessage: () => void
}

export type InputType = "voice" | "file" | "quick-reply" | "emoji" | "variables" | "mentions" | "custom-link"

type ChatMessageBuilderProps = {
	disabled?: boolean
	disabledMessage?: string
	disabledInputs?: InputType[]
	initialMessages?: Message[]
	variables?: Variable[]
	variablesCategoryTitleMap?: Record<string, string>
	messageLimit?: number
	helpLink?: {
		from?: "integrations" | "message-blast",
		href: string
	}
	disableTooMuchMessagesDialog?: boolean
	/**
	 * In case no message is given to the ChatMessageBuilder, it will
	 * automatically creates a new one and start editing it as soon as
	 * the component renders.
	*/
	renderWithInitialMessage?: boolean
	textInputId?: string
	singleMessage?: boolean
}

const BLOCK_MESSAGES = {
	InputBlocked: "É necessário selecionar alguma mensagem acima para usar as ferramentas de edição.",
	MediaInputBlocked: "Como essa mensagem contém um texto, não é possível adicionar uma mídia nela.",
	TextInputBlocked: "Como essa mensagem contém uma mídia, não é possível adicionar um texto nela."
}

const DEFAULT_INPUT_HEIGHT = 128
const TEXT_INPUT_CONTAINER_ELEMENT_ID = "text-input-container"

const TOO_MUCH_WARNING_MESSAGES_COUNT = 1

const ChatMessageBuilder: React.ForwardRefRenderFunction<ChatMessageBuilderHandler, ChatMessageBuilderProps> = (props, ref) => {
	const {
		initialMessages,
		variables,
		variablesCategoryTitleMap,
		disabled,
		disabledMessage,
		messageLimit,
		helpLink,
		disabledInputs,
		disableTooMuchMessagesDialog,
		renderWithInitialMessage,
		textInputId = `ChatMessageBuilderTextInput_${Date.now()}`,
		singleMessage: uniqueMessage = false
	} = props

	const [messages, setMessages] = useAsyncState<Message[]>(initialMessages || [])
	const [shownTooMuchMessagesWarningDialog, setShownTooMuchMessagesWarningDialog] = useState(false)

	const [currentMessageBeingEditedId, setCurrentMessageBeingEditedId] = useState<string>("")

	const textInputRef = useRef<TextInputHandler>(null)
	const scrolledBottomListRef = useRef<ScrolledBottomListHandler>(null)
	const inputContainerRef = useRef<HTMLElement>(null)

	const inputContainerSize = useElementSize(inputContainerRef)

	const currentMessageBeingEdited = messages.current.find(({ id }) => id === currentMessageBeingEditedId)

	const textInputBlocked = Boolean(currentMessageBeingEdited?.content) && isMediaMessage(currentMessageBeingEdited?.type)
	const mediaInputBlocked = Boolean(currentMessageBeingEdited?.content) && !isMediaMessage(currentMessageBeingEdited?.type)
	const inputBlocked = !currentMessageBeingEditedId
	const customClasses = useCustomStyles()
	const classes = useStyles({
		/**
		 * Use a default value to avoid glitch bugs due to a not found height.
		 */
		inputHeight: inputContainerSize.current.height || DEFAULT_INPUT_HEIGHT
	})

	const globalStateStore = useGlobalStateStore()

	// const [openCustomLinkDialog, setOpenCustomLinkDialog] = useState(false)
	// const [customLinkToEdit, setCustomLinkToEdit] = useState<CustomLinkButtons>()

	const handleShowTooMuchMessagesWarningDialogIfNeeded = () => {
		if (disableTooMuchMessagesDialog) {
			return
		}

		const validMessages = messages.current.filter(message => message.status !== "DELETED")
		const currentMessagesCount = validMessages.length
		const isTryingToAddTooMuchMessages = currentMessagesCount === TOO_MUCH_WARNING_MESSAGES_COUNT

		const showTooMuchMessageWarningDialog = isTryingToAddTooMuchMessages && !shownTooMuchMessagesWarningDialog

		if (showTooMuchMessageWarningDialog) {
			TooMuchMessagesWarningDialog.open({ inboxChannelId: globalStateStore.currentChannel?.id })

			setShownTooMuchMessagesWarningDialog(true)
		}
	}

	const currentMedias: Media[] = messages.current
		.filter(({ status }) => status !== "DELETED")
		.filter(({ id }) => id === currentMessageBeingEditedId)
		.filter(({ type }) => isMediaMessage(type))
		.map(mediaMessage => MediaService.buildMediaByUrl({
			name: mediaMessage.mediaName as string,
			url: mediaMessage.content,
			type: mediaMessage.type
		}))

	const filteredMessages = messages.current.filter(({ status }) => status !== "DELETED")

	const reachedMessageLimit = filteredMessages.length >= (messageLimit as number)

	const handleRefreshMessageOrdination = () => {
		setMessages(lastState => {
			const updatedState = lastState.map((message, index) => {
				if (message.status !== "DELETED") {
					message.order = index
				}

				if (!message.status) {
					message.status = "ORDERED"
				}

				return message
			})

			return updatedState
		})
	}

	const runAfterInputAnimation = (fn: () => void) => {
		setTimeout(() => {
			fn()
		}, 250)
	}

	const handleGetMessages = () => {
		const validMessages = messages.current.map(message => {
			const isValidMessage = Boolean(message.content)

			if (!isValidMessage) {
				message.status = "DELETED"
			}

			return message
		})

		return validMessages
	}

	const handleClearTextInput = () => {
		textInputRef.current?.setCurrentValue("")
	}

	const handleClearCurrentMessageBeingEdited = () => {
		handleClearTextInput()

		setCurrentMessageBeingEditedId("")
	}

	const handleBlur = () => {
		handleClearCurrentMessageBeingEdited()
	}

	const handleSetCurrentMessageBeingEdited = (messageId: string) => {
		const isCurrentMessageBeingEdited = currentMessageBeingEditedId === messageId

		/**
		 * In case the selected ID is already being edited, we toggle its
		 * edit mode to off.
		 */
		if (isCurrentMessageBeingEdited) {
			handleClearCurrentMessageBeingEdited()
		} else {
			const message = messages.current.find(({ id }) => id === messageId)

			const isTextMessage = message?.type === "text" || message?.type === "custom-link"

			if (isTextMessage) {
				textInputRef.current?.setCurrentValue(message?.content as string)

				runAfterInputAnimation(() => {
					textInputRef.current?.focus()
					textInputRef.current?.moveCursorToEnd()
				})
			} else {
				handleClearTextInput()
			}

			setCurrentMessageBeingEditedId(messageId)
		}
	}

	const handleCreateMessage = async () => {
		const lastMessage = filteredMessages.pop()

		const lastMessageExists = Boolean(lastMessage)
		const isLastMessageValid = Boolean(lastMessage?.content)

		const canCreateNewMessage = (!lastMessageExists || isLastMessageValid) && !reachedMessageLimit

		if (canCreateNewMessage) {
			handleShowTooMuchMessagesWarningDialogIfNeeded()

			const newMessageId = generateUUID()

			const newMessage: Message = {
				id: newMessageId,
				content: "",
				type: "text",
				order: messages.current.length,
				status: "CREATED"
			}

			await setMessages((lastState) => ([...lastState, newMessage]))

			handleSetCurrentMessageBeingEdited(newMessageId)

			/**
			 * In case no message is being edited, it means the input is not rendered.
			 * Since it will be rendered now, we need to scroll chat to bottom to avoid
			 * getting the input above the new message.
			 */
			if (!currentMessageBeingEditedId) {
				runAfterInputAnimation(() => {
					scrolledBottomListRef.current?.forceScrollBottom()
				})
			} else {
				runAfterReactRender(() => {
					scrolledBottomListRef.current?.forceScrollBottom()
				})
			}
		}
	}

	const handleChangeMessage = (messageId: string, data: Partial<Message>) => {
		let needChangeType = false

		if (Number(data?.extraData?.customLink?.buttons?.length) < 1) {
			needChangeType = true
		}

		setMessages(lastState => {
			const updatedState = lastState.map(message => {
				if (message.id === messageId) {
					_.merge(message, data)

					let updatedStatus = data.status || message.status

					/**
					 * WARNING:
					 * - In case an 'ORDERED' message is changed, we need to set its status to 'UPDATED'
					 * in order to make all the needed business rules before saving it. That way we avoid
					 * bugs like the user being able to save media messages in Base64 format.
					 */
					const needToForceChangeStatusToUpdated = updatedStatus === "ORDERED" || !updatedStatus

					if (needToForceChangeStatusToUpdated) {
						updatedStatus = "UPDATED"
					}

					return {
						...message,
						type: needChangeType ? "text" : message.type,
						status: updatedStatus
					}
				}

				return message
			})

			return updatedState
		})
	}

	const handleDeleteMessage = (messageId: string) => {
		PopConfirm.open({
			title: "Excluir mensagem",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: async () => {
				handleChangeMessage(messageId, {
					content: "",
					status: "DELETED"
				})

				handleRefreshMessageOrdination()

				if (currentMessageBeingEditedId === messageId) {
					handleClearTextInput()

					handleClearCurrentMessageBeingEdited()
				} else {
					runAfterReactRender(() => {
						/**
						 * Force refocus after some message is deleted
						 */
						textInputRef.current?.focus()
					})
				}
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	const handleClearCurrentMediaMessages = () => {
		handleChangeMessage(currentMessageBeingEditedId, {
			type: "text",
			content: ""
		})
	}

	const handleChangeCurrentMediaMessage = async (medias: Media[]) => {
		const [media] = medias

		const isMediaSupportDisabled = disabledInputs?.includes("file")

		if (!isMediaSupportDisabled) {
			MediaValidation.validateMedia(media, async () => {
				const content = await MediaService.turnFileIntoBase64(media.data)

				handleChangeMessage(currentMessageBeingEditedId, {
					type: media.type as Message["type"],
					mediaName: media.data.name,
					content,
					mediaUrl: "",
					mediaKey: ""
				})
			})
		}
	}

	const handleChangeCurrentTextMessage = (text: string) => {
		const isCustomLink = !!currentMessageBeingEdited?.extraData?.customLink?.buttons?.length

		handleChangeMessage(currentMessageBeingEditedId, {
			type: isCustomLink ? "custom-link" : "text",
			content: text
		})
	}

	const onQuickReplySelect = (message: BuildedMessage) => {
		if (isMediaMessage(message.type)) {
			textInputRef.current?.setCurrentValue?.("")

			handleChangeMessage(currentMessageBeingEditedId, {
				content: message.content,
				mediaName: message.mediaName,
				type: message.type,
				mediaUrl: message.content,
				mediaKey: ""
			})
		} else {
			textInputRef.current?.addText?.(message.content)
		}
	}

	const load = async () => {
		const isThereAnyMessage = filteredMessages.length > 0

		if (!isThereAnyMessage && renderWithInitialMessage) {
			runAfterReactRender(() => {
				handleCreateMessage()
			})
		}
	}

	const handleOnSaveMentions = async (optionsSelected: MessageOptionsType["mentions"]) => {
		setMessages(lastState => {
			const updatedState = lastState.map(message => {
				if (message.id === currentMessageBeingEditedId) {
					message.extraData = {
						...message.extraData,
						options: {
							mentions: {
								...optionsSelected
							}
						}
					}

					message.status = "UPDATED"
				}
				return message
			})
			return updatedState
		})
	}

	// const handleOnCustomLinkInputAddLink = (value: CustomLinkButtons) => {
	// 	handleChangeMessage(currentMessageBeingEditedId, {
	// 		type: "custom-link",
	// 		extraData: {
	// 			customLink: {
	// 				buttons: [
	// 					value
	// 				]
	// 			}
	// 		}
	// 	})
	// }

	// const handleOnCustomLinkInputEditLink = (value: CustomLinkButtons) => {
	// 	setCustomLinkToEdit(value)
	// 	setOpenCustomLinkDialog(true)
	// }

	// const handleOnDeleteCustomLink = async (index: number) => {
	// 	const buttons = currentMessageBeingEdited?.extraData?.customLink?.buttons
	//
	// 	buttons?.splice(index, 1)
	//
	// 	handleChangeMessage(currentMessageBeingEditedId, {
	// 		extraData: {
	// 			customLink: {
	// 				buttons: buttons
	// 			}
	// 		}
	// 	})
	// }

	useImperativeHandle(ref, () => ({
		getMessages: handleGetMessages,
		blur: handleBlur,
		createMessage: handleCreateMessage
	}))

	useDidMount(() => {
		load()
	})

	// useEffect(() => {
	// 	if (openCustomLinkDialog === false) {
	// 		setCustomLinkToEdit(undefined)
	// 	}
	// }, [openCustomLinkDialog])

	return (
		<Grid
			container
			className={classes.chatMessageBuilderContainer}
		>
			<Grid
				container
				className={clsx({
					[classes.messageListContainer]: true,
					[classes.messageListContainerInputInvisible]: inputBlocked,
					[classes.messageListContainerInputVisible]: !inputBlocked
				})}
			>
				<Grid
					container
					direction="column"
					alignItems="flex-end"
					wrap="nowrap"
					className={clsx({
						[classes.messageListContent]: true,
						[customClasses.scrollBar]: true
					})}
				>
					<ScrolledBottomList
						ref={scrolledBottomListRef}
						autoScrollDisabled
					>
						{[
							...filteredMessages.map(message => (
								<Grid
									key={message.id}
									container
									justifyContent="flex-end"
									alignItems="flex-start"
									className={classes.messageContainer}
								>
									{!disabled && (
										<Grid
											container
											justifyContent="center"
											alignItems="center"
											direction="column"
											className={classes.messageActionContainer}
										>
											<IconButton
												size="small"
												onClick={() => handleDeleteMessage(message.id)}
												className={classes.messageActionButton}
												disabled={disabled}
											>
												<DeleteIcon
													fontSize="small"
												/>
											</IconButton>

											<Divider orientation="horizontal" size={0.5} />

											<IconButton
												size="small"
												onClick={() => handleSetCurrentMessageBeingEdited(message.id)}
												className={classes.messageActionButton}
												disabled={disabled}
											>
												<EditIcon
													fontSize="small"
												/>
											</IconButton>
										</Grid>
									)}

									<Tooltip
										title={disabled ? (disabledMessage || "") : ""}
										placement="left"
									>
										<span
										>
											{disabled ? <Grid
												container
												direction="column"
												spacing={0}
												className={classes.disabledMessageButton}
											>
												<MessageItem
													type={message.type}
													content={message.content}
													mediaName={message.mediaName}
													className={clsx({
														[classes.messageItem]: true,
														[classes.currentMessageBeingEdited]: message.id === currentMessageBeingEditedId
													})}
													extraData={message.extraData}
												/>
											</Grid>
												: <ButtonBase
													onClick={() => handleSetCurrentMessageBeingEdited(message.id)}
													className={classes.messageButton}
													disabled={disabled}
												>
													<MessageItem
														type={message.type}
														content={message.content}
														mediaName={message.mediaName}
														className={clsx({
															[classes.messageItem]: true,
															[classes.currentMessageBeingEdited]: message.id === currentMessageBeingEditedId
														})}
														extraData={message.extraData}
													/>
												</ButtonBase>
											}
										</span>
									</Tooltip>
								</Grid>
							)),
							(
								<Grid
									key="add-message-button"
									container
									justifyContent="flex-end"
									alignItems="flex-start"
									className={clsx({
										[classes.messageContainer]: true,
										[classes.hiddenButton]: disabled || reachedMessageLimit
									})}
								>
									{!uniqueMessage && (
										<Button
											variant="text"
											startIcon={<CreateMessageIcon />}
											onClick={handleCreateMessage}
											disabled={disabled}
										>
											ADICIONAR MENSAGEM
										</Button>
									)}
								</Grid>
							)
						]}
					</ScrolledBottomList>
				</Grid>
			</Grid>

			<Tooltip
				title={inputBlocked ? BLOCK_MESSAGES.InputBlocked : ""}
			>
				<Grid
					container
					innerRef={inputContainerRef}
					className={clsx({
						[customClasses.chatInputContainer]: true,
						[classes.inputContainer]: true,
						[classes.inputContainerInvisible]: inputBlocked,
						[classes.inputContainerVisible]: !inputBlocked
					})}
				>
					<MediaPreview
						medias={currentMedias}
						onClear={() => handleClearCurrentMediaMessages()}
					/>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						alignItems="center"
						justifyContent="center"
					>
						<Grid
							id={textInputId}
							container
						>
							<Grid
								container
								id={TEXT_INPUT_CONTAINER_ELEMENT_ID}
							>
								<TextInput
									onSubmit={() => handleCreateMessage()}
									onMedia={medias => handleChangeCurrentMediaMessage(medias)}
									onChange={text => handleChangeCurrentTextMessage(text)}
									ref={textInputRef}
									disabled={inputBlocked || textInputBlocked}
									getInputProps={textInputHandler => ({
										endAdornment: (<InputAdornment position="end">
											<TextEnhancementButton
												TextEnhancementDialogProps={{
													anchorElement: document.getElementById(TEXT_INPUT_CONTAINER_ELEMENT_ID),
													customSecondaryTitleTextStyle: classes.customSecondaryTitleTextStyle
												}}
												text={textInputHandler.getCurrentValue()}
												onReplace={text => {
													textInputHandler.replaceText(text)
													handleChangeCurrentTextMessage(text)
												}}
												IconButtonSvgIconProps={{
													className: clsx({
														[classes.fadeInOutAnimation]: Boolean(textInputHandler.getCurrentValue())
													})
												}}
											/>
										</InputAdornment>)
									})}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />
					{/*
						<IncludedCustomLinkList
							links={currentMessageBeingEdited?.extraData?.customLink?.buttons}
							onClick={handleOnCustomLinkInputEditLink}
							onDelete={handleOnDeleteCustomLink}
					/>
					*/}
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
					>
						{(!disabledInputs?.includes("quick-reply") &&
							/* This provider is placed here because the QuickReply component depends on this context. */
							<ChatGlobalStateProvider>
								<QuickReply
									shortcutEnabled
									onSelect={onQuickReplySelect}
									onClose={() => textInputRef.current?.focus?.()}
									disabled={inputBlocked}
								/>
							</ChatGlobalStateProvider>
						)}
						{/** We have disabled custom links due to problems related to functioning in the baileys library */}
						{/*
							{!disabledInputs?.includes("custom-link") && (
								<CustomLinkInput
									setExtraData={handleOnCustomLinkInputAddLink}
									open={openCustomLinkDialog}
									setOpen={setOpenCustomLinkDialog}
									disabled={inputBlocked}
									linkToEdit={customLinkToEdit}
								/>
							)}
						*/}

						{
							!disabledInputs?.includes("mentions") && HardCoded.checkFeatureFlag("groupMentions") && (
								<Mentions
									onSave={(optionsSelected) => handleOnSaveMentions(optionsSelected)}
									disabled={inputBlocked}
									mentionsSelected={{ ...currentMessageBeingEdited?.extraData?.options?.mentions }}
								/>
							)
						}

						{!disabledInputs?.includes("voice") && (
							<VoiceInput
								onMedia={audio => handleChangeCurrentMediaMessage([audio])}
								disabled={inputBlocked || mediaInputBlocked}
							/>
						)}

						{(!disabledInputs?.includes("variables") && variables) && (
							<VariableInput
								variablesCategoryTitleMap={variablesCategoryTitleMap}
								variables={variables || []}
								onVariable={(variable) => textInputRef.current?.addTextByCursor?.(`{{ ${variable} }}`)}
								disabled={inputBlocked || textInputBlocked}
								helpLink={helpLink}
							/>
						)}

						{!disabledInputs?.includes("emoji") && (
							<EmojiPicker
								onEmoji={(emoji) => textInputRef.current?.addTextByCursor?.(emoji)}
								disabled={inputBlocked || textInputBlocked}
							/>
						)}

						{!disabledInputs?.includes("file") && (
							<FileInput
								onMedia={files => handleChangeCurrentMediaMessage(files)}
								disabled={inputBlocked || mediaInputBlocked}
							/>
						)}
					</Grid>
				</Grid>
			</Tooltip>
		</Grid>
	)
}

export default forwardRef(ChatMessageBuilder)
