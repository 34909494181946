import React from "react"
import clsx from "clsx"
import {
	PersonAdd
} from "@material-ui/icons"
import {
	Button,
	Typography
} from "@material-ui/core"

import UpsellQuantityDialog from "@/components/UpsellDialog/UpsellQuantityDialog"
import { AlertContainer } from "@/components"
import {
	AlertType
} from "@/components/AlertContainer"

import { SubscriptionUserQuantityStatusType } from "@/pages/Admin/Attendant"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import useStyles from "@/pages/Admin/Attendant/components/UpsellAttendantAlertContainer/styles"

type UpsellAttendantAlertContainerProps = {
	subscriptionUserStatus: SubscriptionUserQuantityStatusType | null
	actualAttendantsQuantity: number
}

export type UpsellAttendantButtonStyleProps = {
	backgroundColor: string
	borderColor: string
	textColor: string
	hoverColor: string
}

const ATTENDANTS_ALERT_UPSELL_DATA: Record<"user_reach_limit" | "user_is_reaching_the_limit", {
	title: string
	text: string
	upsellAlertType: Extract<AlertType, "info" | "warning">
}> = {
	user_is_reaching_the_limit: {
		title: "Contrate mais atendentes",
		text: "É possível que, em breve, você precise contratar mais atendentes. Caso prefira adiantar essa contratação, basta clicar no botão a frente.",
		upsellAlertType: "info"
	},
	user_reach_limit: {
		title: "Limite de atendentes atingido",
		text: "O limite de atendentes da sua conta foi atingido. Clique no botão a frente para fazer o upgrade agora mesmo!",
		upsellAlertType: "warning"
	}
}

const UpsellAttendantAlertContainer: React.FC<UpsellAttendantAlertContainerProps> = (props) => {
	const {
		subscriptionUserStatus,
		actualAttendantsQuantity
	} = props

	if (!subscriptionUserStatus) {
		return null
	}

	const attendantsAlertUpsellContent = ATTENDANTS_ALERT_UPSELL_DATA[subscriptionUserStatus]

	const getButtonStyleProps = (): UpsellAttendantButtonStyleProps => {
		const alertTypeToButtonStyleProps: Record<Extract<AlertType, "info" | "warning">, UpsellAttendantButtonStyleProps> = {
			info: {
				backgroundColor: newColors.grey[0],
				borderColor: colors.palette.primary,
				textColor: colors.palette.primary,
				hoverColor: newColors.purple[50]
			},
			warning: {
				backgroundColor: newColors.grey[0],
				borderColor: newColors.yellow[500],
				textColor: newColors.yellow[500],
				hoverColor: newColors.yellow[50]
			}
		}

		return alertTypeToButtonStyleProps[attendantsAlertUpsellContent.upsellAlertType]
	}

	const buttonStyleProps = getButtonStyleProps()

	const classes = useStyles(buttonStyleProps)

	return (
		<AlertContainer
			title={attendantsAlertUpsellContent.title}
			icon={
				<PersonAdd
					style={{
						color: subscriptionUserStatus === "user_is_reaching_the_limit" ? colors.palette.primary : newColors.yellow[500],
						width: 32,
						height: 32
					}}
				/>
			}
			customButton={(
				<Button
					variant="outlined"
					className={clsx({
						[classes.button]: true,
						[classes.buttonPulseEffect]: subscriptionUserStatus === "user_reach_limit"
					})}
					onClick={() => UpsellQuantityDialog.open({
						type: "user",
						actualQuantity: actualAttendantsQuantity,
						isUserReachSubscriptionLimit: subscriptionUserStatus === "user_reach_limit"
					})}
				>
					Fazer Upgrade
				</Button>
			)}
			alertType={attendantsAlertUpsellContent.upsellAlertType}
			customStyles={{
				info: {
					borderLeftColor: colors.palette.primary,
					containerBackgroundColor: newColors.grey[0],
					titleColor: colors.palette.primary
				},
				warning: {
					borderLeftColor: newColors.yellow[500],
					containerBackgroundColor: newColors.grey[0],
					titleColor: newColors.yellow[500]
				}
			}}
		>
			<Typography
				style={{
					fontSize: 12
				}}
			>
				{attendantsAlertUpsellContent.text}
			</Typography>
		</AlertContainer>
	)
}

export default UpsellAttendantAlertContainer
