import React, { useState } from "react"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	ThemeProvider,
	Typography
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"

import Rocket from "@/assets/lotties/rocket.lottie"

import { convertCentsInReals } from "@/utils/currencyFormatter"

import { UpsellQuantityInfo, UpsellQuantityType } from "@/protocols/planAddons"

import useStyles from "@/components/UpsellDialog/UpsellQuantityDialog/styles"
import theme from "@/styles/theme"

type UpsellQuantitySuccessfullyCompletedProps = {
	upsellQuantityInfo: UpsellQuantityInfo
	upsellQuantityType: UpsellQuantityType
	actualQuantity: number
	onClose: () => void
}

const UpsellQuantitySuccessfullyDialog: React.FC<UpsellQuantitySuccessfullyCompletedProps> = (props) => {
	const {
		upsellQuantityInfo,
		upsellQuantityType,
		actualQuantity,
		onClose
	} = props

	const [openDialog, setOpenDialog] = useState<boolean>(true)

	const classes = useStyles()

	const handleCloseDialog = () => {
		setOpenDialog(false)

		onClose()

		window.location.reload()
	}

	const getUpsellContent = () => {
		const upsellTypeToContent: Record<UpsellQuantityType, {
			title: string
			description: string
			content: React.ReactNode
			media: React.ReactNode
		}> = {
			user: {
				title: "Atendentes adicionados com sucesso!",
				description: "Sua equipe acaba de crescer! Agora você pode atender mais clientes e garantir uma experiência ainda melhor.",
				content: (
					<Grid
						container
						direction="column"
						spacing={1}
					>
						<Grid
							item
						>
							<Typography
								variant="body1"
							>
								<strong>Quantidade de Atendentes Contratados:</strong> {upsellQuantityInfo.quantity}
							</Typography>
						</Grid>

						<Grid
							item
						>
							<Typography
								variant="body1"
							>
								<strong>Nova Quantidade de Atendentes:</strong> {actualQuantity + upsellQuantityInfo.quantity}
							</Typography>
						</Grid>

						<Grid
							item
						>
							<Typography
								variant="body1"
							>
								<strong>Valor Total:</strong> {convertCentsInReals(upsellQuantityInfo.priceInCents)}
							</Typography>
						</Grid>
					</Grid>
				),
				media: (
					<>
						<DotLottieReact
							src={Rocket}
							speed={0.3}
							loop={true}
							autoplay={true}
							segment={[40, 60]}
							style={{
								position: "absolute",
								top: 190,
								left: 50,
								width: "200%",
								transform: "translate(-34%, -30%)"
							}}
						/>
					</>
				)
			}
		}

		return upsellTypeToContent[upsellQuantityType]
	}

	const upsellContent = getUpsellContent()

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth
				PaperProps={{
					className: classes.fullDialog
				}}
			>
				<Grid
					container
					className={classes.fullDialog}
				>
					<Grid
						item
						xs={8}
					>
						<Grid
							container
							direction="column"
							justifyContent="space-between"
							className={classes.fullDialog}
						>
							<Grid
								item
								style={{
									flex: 1
								}}
							>
								<DialogTitle>
									<Grid
										container
										direction="column"
										spacing={2}
									>
										<Grid
											item
										>
											<Typography variant="h3" className={classes.dialogTitle}>
												{upsellContent.title}
											</Typography>

										</Grid>
										<Grid
											item
										>
											<Typography className={classes.dialogDescription}>
												{upsellContent.description}
											</Typography>
										</Grid>
									</Grid>
								</DialogTitle>
							</Grid>
							<Grid
								item
								xs="auto"
								style={{
									flex: 2
								}}
							>
								<DialogContent className={classes.dialogContent}>
									<Grid
										container
										direction="column"
										spacing={2}
									>
										<Grid
											item
										>
											<Typography variant="body1">
												<strong>Resumo:</strong>
											</Typography>
										</Grid>

										<Grid
											item
										>
											{upsellContent.content}
										</Grid>
									</Grid>
								</DialogContent>
							</Grid>
							<Grid
								item
							>
								<DialogActions className={classes.dialogActions}>
									<Grid
										container
									>
										<Grid
											item
											xs={12}
										>
											<Grid
												container
												justifyContent="flex-start"
											>
												<Grid
													item
													xs={10}
												>
													<Button
														variant="contained"
														className={classes.dialogActionPrimaryButton}
														onClick={() => {
															handleCloseDialog()
															onClose()
														}}
													>
														ENTENDIDO
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={4}
						className={classes.imageContainer}
					>
						{upsellContent.media}

						<IconButton
							className={classes.closeButton}
							onClick={() => {
								handleCloseDialog()
								onClose()
							}}
						>
							<Close />
						</IconButton>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

export default UpsellQuantitySuccessfullyDialog
