import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		cursor: "pointer"
	},
	input: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		height: 52,
		border: "1px solid #ccc",
		borderRadius: "4px"
	},
	addFile: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: newColors.grey[200],
		borderRadius: 4,
		marginLeft: 5,
		width: 40,
		height: 40
	},
	mediaSizeLimit: {
		fontSize: 12,
		marginLeft: 12,
		color: newColors.grey[600],
		whiteSpace: "nowrap"
	},
	errorText: {
		marginTop: 4,
		color: "red",
		fontSize: 12
	}
})

export default useStyles
