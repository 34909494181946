import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	deleteSnoozeButton: {
		backgroundColor: colors.palette.error,
		"&:hover": {
			opacity: 0.8,
			backgroundColor: colors.palette.error
		}
	}
})

export default useStyles
