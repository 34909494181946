export const beautifyBytes = (bytes: number, decimals: number) => {
	if (bytes === 0) {
		return "0 Bytes"
	}

	const k = 1024
	const dm = decimals || 2
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const convertBytesToMegabytes = (bytes: number) => {
	const byteAsMegabyte = 1000000

	return bytes / byteAsMegabyte
}

export const convertMegabytesToBytes = (megabytes: number): number => {
	const bytesPerKilobyte = 1024
	const kilobytesPerMegabyte = bytesPerKilobyte

	return megabytes * kilobytesPerMegabyte * bytesPerKilobyte
}
