import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import MediaService, { Media } from "@/services/Media"
import { WABATemplateHeaderBody, WABATemplateHeaderType, WABATemplateHeaderTypeToTemplateHeaderBody } from "@/@integrations/WABA/protocols/wabaChannelMessageTemplate"
import useStyles from "@/pages/Admin/Templates/ManageTemplateForm/TemplateFileInput/styles"
import { AttachFile as AttachFileIcon } from "@material-ui/icons"
import { beautifyBytes, convertMegabytesToBytes } from "@/utils/format"
import useDidMount from "@/hooks/useDidMount"

type HeaderMediaType = Exclude<WABATemplateHeaderType, "text">

type FileInputProps = {
	onMedia: (files: Media[]) => void
	mediaType: HeaderMediaType
	content: WABATemplateHeaderBody<WABATemplateHeaderType> | undefined
}

type MediaData = {
	mediaLimitText: string
	supportedMimeType: string[]
	maxSize: number
	size: number
}

const TemplateFileInput: React.FC<FileInputProps> = (props) => {
	const { onMedia, mediaType, content } = props

	const headerMediaTypeToContent: Record<HeaderMediaType, WABATemplateHeaderTypeToTemplateHeaderBody[HeaderMediaType]> = {
		file: content as WABATemplateHeaderTypeToTemplateHeaderBody["file"],
		picture: content as WABATemplateHeaderTypeToTemplateHeaderBody["picture"],
		video: content as WABATemplateHeaderTypeToTemplateHeaderBody["video"]
	}

	const mediaContent = headerMediaTypeToContent[mediaType]

	const classes = useStyles()
	const [errorMessage, setErrorMessage] = useState<string>("")
	const [currentMediaType, setCurrentMediaType] = useState<HeaderMediaType>(mediaType)
	const [fileName, setFileName] = useState<string>("")

	const HEADER_TYPE_TO_OPTION_LABELS: Record<HeaderMediaType, MediaData> = {
		file: {
			mediaLimitText: "PDF (Tamanho máximo 100 MB)",
			supportedMimeType: [".pdf"],
			size: 100,
			maxSize: convertMegabytesToBytes(100)
		},
		picture: {
			mediaLimitText: "PNG e JPEG (Tamanho máximo 5 MB)",
			supportedMimeType: [".png", ".jpeg"],
			size: 100,
			maxSize: convertMegabytesToBytes(5)
		},
		video: {
			mediaLimitText: "MP4 (Tamanho máximo 16 MB)",
			supportedMimeType: [".mp4"],
			size: 100,
			maxSize: convertMegabytesToBytes(16)
		}
	}

	const getMimeTypeFromFileName = (fileName: string): string | undefined => {
		const fileNameSegments = fileName.split(".")
		const fileExtension = fileNameSegments.pop()

		return fileExtension?.toLowerCase()
	}

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event?.target
		const file = inputElement?.files?.[0]
		inputElement.value = ""

		if (file) {
			const mimeTypeFromFileName = getMimeTypeFromFileName(file.name)
			const hasValidType = HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.supportedMimeType.includes(`.${mimeTypeFromFileName}` || "")

			if (!mimeTypeFromFileName || !hasValidType) {
				setErrorMessage(`Tipo de arquivo inválido. Por favor, envie um arquivo do tipo: ${HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.supportedMimeType}`)
				return
			}

			const maxSize = HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.maxSize

			if (maxSize && file.size > maxSize) {
				setErrorMessage(`O arquivo é muito grande. O tamanho máximo permitido é ${beautifyBytes(HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.maxSize, 2)}`)
				return
			}

			setErrorMessage("")
			const fileMedia = MediaService.adaptFile(file)
			onMedia([fileMedia])
			setFileName(file.name)
		}
	}

	useEffect(() => {
		if (mediaType !== currentMediaType) {
			setErrorMessage("")
			setCurrentMediaType(mediaType)
			setFileName("")
		}
	}, [mediaType])

	useDidMount(() => {
		if (mediaContent && "media" in mediaContent && mediaContent.media?.url) {
			setFileName(mediaContent.media.url.split("/").pop() || "")
		} else {
			setFileName("")
		}
	})

	return (
		<Grid container alignItems="center" className={classes.container} component="label">
			<input
				type="file"
				style={{ display: "none" }}
				accept={HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.supportedMimeType.join(",")}
				onChange={handleChange}
			/>
			<Grid item className={classes.input}>
				<Grid item className={classes.addFile}>
					<AttachFileIcon />
				</Grid>

				<Grid item>
					<Typography className={classes.mediaSizeLimit}>
						{fileName || HEADER_TYPE_TO_OPTION_LABELS[mediaType]?.mediaLimitText}
					</Typography>
				</Grid>
			</Grid>

			<Grid item>
				{errorMessage && (
					<Typography color="error" variant="body2" className={classes.errorText}>
						{errorMessage}
					</Typography>
				)}
			</Grid>
		</Grid>
	)
}

export default TemplateFileInput
