import React from "react"

import {
	Grid,
	Typography
} from "@material-ui/core"

import useStyles from "@/components/AlertContainer/styles"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export type AlertType = "info" | "warning" | "error"

export type AlertContainerStyleProps = {
	borderLeftColor?: string
	titleColor: string
	containerBackgroundColor: string
}

export type AlertTypeToAlertContainerCustomStyleProps = {
	[K in AlertType]?: Partial<AlertContainerStyleProps>
}

type AlertContainerProps = {
	alertType: AlertType
	title?: string
	icon?: JSX.Element
	customButton?: React.ReactNode
	customStyles?: AlertTypeToAlertContainerCustomStyleProps
}

const AlertContainer: React.FC<AlertContainerProps> = (props) => {
	const {
		alertType,
		title,
		icon,
		children,
		customStyles,
		customButton
	} = props

	const getAlertContainerStyleProps = (alertType: AlertType): AlertContainerStyleProps => {
		const alertTypeToAlertContainerStyleProps: Record<AlertType, AlertContainerStyleProps> = {
			info: {
				borderLeftColor: colors.palette.primary,
				containerBackgroundColor: colors.unrelated.F9F6FE,
				titleColor: colors.palette.primary
			},
			warning: {
				borderLeftColor: newColors.yellow[500],
				containerBackgroundColor: newColors.yellow[50],
				titleColor: newColors.yellow[500]
			},
			error: {
				borderLeftColor: newColors.red[600],
				containerBackgroundColor: newColors.red[50],
				titleColor: newColors.red[600]
			}
		}

		const defaultAlertContainerStyles = alertTypeToAlertContainerStyleProps[alertType]

		const customAlertContainerStyles = customStyles?.[alertType]

		if (customAlertContainerStyles) {
			return {
				...defaultAlertContainerStyles,
				...customAlertContainerStyles
			}
		}

		return defaultAlertContainerStyles
	}

	const alertContainerStyleProps = getAlertContainerStyleProps(alertType)

	const classes = useStyles(alertContainerStyleProps)

	return (
		<Grid
			container
			className={classes.container}
		>
			<Grid
				item
				style={{
					display: "flex",
					alignItems: "center"
				}}
			>
				<Grid
					container
				>
					{icon && (
						<Grid
							item
						>
							{icon}
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid
				item
				xs
			>
				<Grid
					container
					direction="column"
				>
					{title && (
						<Grid item>
							<Typography
								className={classes.title}
							>
								{title}
							</Typography>
						</Grid>
					)}
					<Grid
						item
					>
						{children}
					</Grid>
				</Grid>
			</Grid>

			{customButton && (
				<Grid
					item
				>
					<Grid
						container
						alignItems="center"
						style={{
							height: "100%"
						}}
					>
						{customButton}
					</Grid>
				</Grid>
			)}
		</Grid>
	)
}

export default AlertContainer
