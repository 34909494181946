import React, { useState } from "react"
import {
	Grid, Link as LinkMUI, Typography
} from "@material-ui/core"

import {
	AccessibleDrawer, ActionDialog, Divider, Profile
} from "@/components"

import { WarningTwoTone as WarningIcon } from "@material-ui/icons"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import { isSmallScreen } from "@/utils/checkDevice"

import Input from "@/pages/Attendance/Chat/ConversationPanel/Input"
import ConversationHeader from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader"
import ClientInfo from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo"
import MessageList from "@/pages/Attendance/Chat/ConversationPanel/MessageList"
import MessageProcessingStepsDialog from "@/pages/Attendance/Chat/ConversationPanel/MessageProcessingStepsDialog"

import AlertContainer from "@/components/AlertContainer"
import WABAChatCommunicationPermissionBar from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionBar"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/styles"
import newColors from "@/styles/newColors"

import { isAbleToValidateEmail } from "@/utils/time"

const ConversationPanel: React.FC = () => {
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const canValidateEmail = isAbleToValidateEmail()
	const [openClientInfoDrawer, setOpenClientInfoDrawer] = useState(!isSmallScreen)
	const [openValidateEmailDialog, setOpenValidateEmailDialog] = useState(false)
	const handleCloseChat = () => {
		chatGlobalStateStore.chat.close()
	}
	const hadleOpenDialog = () => {
		setOpenValidateEmailDialog(true)
	}

	const hadleCloseDialog = () => {
		setOpenValidateEmailDialog(false)
	}

	const handleOpenClientInfoDrawer = () => {
		setOpenClientInfoDrawer(true)
	}

	const handleCloseClientInfoDrawer = () => {
		setOpenClientInfoDrawer(false)
	}

	return (
		<>
			<AccessibleDrawer
				id="conversation-panel-drawer"
				variant={isSmallScreen ? chatGlobalStateStore.chat.current ? "persistent" : "temporary" : "permanent"}
				open={Boolean(chatGlobalStateStore.chat.current)}
				anchor="right"
				onClose={handleCloseChat}
				onMobileBackButtonPress={handleCloseChat}
				className={classes.chatDrawer}
				classes={{
					paper: classes.chatDrawerPaper
				}}
			>

				{canValidateEmail && (
					<>
						<Divider size={1} orientation="horizontal" />
						<AlertContainer
							title="ATENÇÃO"
							alertType="warning"
							icon={<WarningIcon htmlColor={newColors.yellow[500]} fontSize="large" />}
						>
							<Typography variant="body2">
								Seu e-mail não está verificado. Ajude a proteger sua conta contra o acesso não autorizado.{" "}
								<LinkMUI
									underline="always"
									onClick={hadleOpenDialog}
									style={{
										textDecoration: "none",
										cursor: "pointer"
									}}
								>
									Validar email
								</LinkMUI>
							</Typography>
						</AlertContainer>

						<Divider orientation="horizontal" size={1} />
					</>
				)}

				<ActionDialog
					title={""}
					fullWidth
					maxWidth="md"
					onClose={hadleCloseDialog}
					openDialog={openValidateEmailDialog}
				>
					<Profile />
				</ActionDialog>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					className={classes.chatRightSideContainer}
				>
					<Grid
						container
						className={classes.chatHeader}
						justify="flex-start"
						alignItems="center"
						wrap="nowrap"
					>
						<ConversationHeader
							onClose={handleCloseChat}
							onClick={handleOpenClientInfoDrawer}
						/>
					</Grid>

					<Grid
						container
						className={classes.fixedHeightContainer}
					>
						<MessageList />
					</Grid>

					<MessageProcessingStepsDialog
						messageType={chatGlobalStateStore.message.processingStepsDialog.current?.type}
						processingSteps={chatGlobalStateStore.message.processingStepsDialog.current?.inboxChannelChatMessageLog?.processingSteps || {}}
						opened={chatGlobalStateStore.message.processingStepsDialog.isOpened}
						onClose={() => chatGlobalStateStore.message.processingStepsDialog.close()}
					/>

					<Grid
						container
						direction="column"
					>
						{chatGlobalStateStore.chat.current?.channelType === "waba" && (
							<WABAChatCommunicationPermissionBar
								status={chatGlobalStateStore.chat.current?.wabaChannelChatCommunicationPermission?.status ?? "paused"}
								scheduledPauseAt={chatGlobalStateStore.chat.current?.wabaChannelChatCommunicationPermission?.scheduledPauseAt}
							/>
						)}

						<Input />
					</Grid>
				</Grid>
			</AccessibleDrawer>

			{chatGlobalStateStore.client.current && (
				<ClientInfo
					open={openClientInfoDrawer}
					onClose={handleCloseClientInfoDrawer}
					clientId={chatGlobalStateStore.client.current.id}
				/>
			)}
		</>
	)
}

export default ConversationPanel
