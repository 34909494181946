import { DialogContentText, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "@/pages/Admin/CustomFields/GroupListVariable/styles"
import {
	Edit as EditIcon,
	Delete as TrashIcon
} from "@material-ui/icons"
import { ActionDialog, Notification, PopConfirm } from "@/components"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { CustomFieldGroupList } from "@/protocols/customField"
import { ErrorType } from "@/hooks/useValidation"
import ManageCustomField from "@/pages/Admin/CustomFields/ManageCustomField"
import ManageCustomFieldGroup from "@/pages/Admin/CustomFields/ManageCustomFieldGroup"
import { getErrorCodeMessages } from "@/utils/response"

type CustomFieldDeleteValues = {
	group_id: number,
	display_name: string
	id: number
	variable: string
}

type GroupListVariableProps = {
	groupList: CustomFieldGroupList[]
	onDataChange: () => void
}

const GroupListVariable: React.FC<GroupListVariableProps> = ({ onDataChange, groupList }) => {
	const [loading, setLoading] = useState(false)
	const [openDeleteCustomField, setOpenDeleteCustomField] = useState(false)
	const [deleteField, setDeleteCustomField] = useState<CustomFieldDeleteValues>({} as CustomFieldDeleteValues)
	const [deleteCustomFieldName, setDeleteCustomFieldName] = useState("")
	const [deleteCustomFieldHelperText, setDeleteCustomFieldHelperText] = useState(false)
	const handleCloseDeleteDialogCustomField = () => {
		setOpenDeleteCustomField(false)
		setDeleteCustomFieldHelperText(false)
	}
	const handleDeleteCustomFieldGroup = async (id: number) => {
		setLoading(true)
		PopConfirm.open({
			title: "Excluir Grupo",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/custom-fields/group/${id}`)
					Notification.success({ message: "Grupo excluido com sucesso!" })
				} catch (error) {
					const codeMessages = getErrorCodeMessages(error as ErrorType)

					if (codeMessages?.custom_field_group_id === "custom_field_group_is_not_empty") {
						Notification.warning({ message: "O grupo deve estar vazio para ser excluido" })
					}
				}
				onDataChange()
				handleCloseDeleteDialogCustomField()
			}
		})
		setLoading(false)
	}
	const handleDeleteCustomField = async () => {
		setLoading(true)
		try {
			const { id } = deleteField
			await ApiService.delete(`/custom-fields/${id}`)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			setDeleteCustomFieldName("")
		}
		onDataChange()
		setLoading(false)
		handleCloseDeleteDialogCustomField()
	}
	const handleDeleteConfirmation = () => {
		if (deleteField.display_name === deleteCustomFieldName) {
			handleDeleteCustomField()
			setDeleteCustomFieldHelperText(false)
		} else {
			setDeleteCustomFieldHelperText(true)
		}
	}
	const customStyle = useStyles()

	return (<>
		{groupList.map((groups) => {
			return (
				<Grid item key={groups.display_name + groups.id} className={customStyle.gridSpacing}>
					<Grid container justify="flex-start" alignItems="center">
						<DialogTitle className={customStyle.groupName}>
							{groups.display_name}
						</DialogTitle>
						<Grid container direction="row" className={customStyle.buttonGroup}>
							<Grid item>
								<ManageCustomFieldGroup edit={true} groupData={groups} onDataChange={onDataChange}>
									<IconButton>
										<EditIcon />
									</IconButton>
								</ManageCustomFieldGroup>
							</Grid>

							<Grid item>
								<IconButton onClick={() => {
									handleDeleteCustomFieldGroup(groups.id)
								}}>
									<TrashIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
					<Paper className={customStyle.paper}>
						<TableContainer>
							<Table stickyHeader size="small">
								<TableHead>
									<TableRow>
										<TableCell width={400}>
											Nome do campo
										</TableCell>

										<TableCell width={400}>
											Variável
										</TableCell>

										<TableCell style={{ textAlign: "end", paddingRight: 44 }}>
											Ações
										</TableCell>

									</TableRow>
								</TableHead>

								<TableBody

								>
									{groups.custom_field_values.length > 0 ? groups.custom_field_values.map(customVariableInfo => {
										const { display_name, variable, id } = customVariableInfo
										const editField = {
											...customVariableInfo,
											group_name: groups.display_name,
											custom_field_group_id: groups.id
										}
										return (
											<TableRow
												key={id}
												tabIndex={-1}
											>
												<TableCell>
													{display_name}
												</TableCell>

												<TableCell>
													<p className={customStyle.personalVariableStyle}>
														{`{{${variable}}}`}
													</p>
												</TableCell>

												<TableCell>
													<Grid container justify="flex-end" alignItems="center">
														<Grid item>
															<ManageCustomField onDataChange={onDataChange} actualCustomFieldInfos={editField}
																customFieldGroupList={groupList}
																groupDisplayName={groups.display_name}
																edit={true}
															>
																<IconButton>
																	<EditIcon />
																</IconButton>
															</ManageCustomField>
														</Grid>

														<Grid item>
															<IconButton onClick={() => {
																setOpenDeleteCustomField(true)
																setDeleteCustomField(lastState => {
																	return {
																		...lastState,
																		...customVariableInfo,
																		group_id: groups.id
																	}
																})
															}}>
																<TrashIcon />
															</IconButton>

														</Grid>
													</Grid>
												</TableCell>
											</TableRow>
										)
									})
										: <Grid item style={{ margin: 20 }}>
											<Typography>
												Nenhum campo cadastrado para esse grupo.
											</Typography>
										</Grid>}
								</TableBody>

							</Table>
						</TableContainer>
					</Paper>
				</Grid>)
		})}
		<ActionDialog
			title={"EXCLUIR CAMPO PERSONALIZADO"}
			onSave={handleDeleteConfirmation}
			saveText={"SALVAR"}
			onClose={handleCloseDeleteDialogCustomField}
			openDialog={openDeleteCustomField}
			fullWidth
			loading={loading}
		>
			<Grid container>
				<Grid item xs={12}>
					<Grid
						item
						xs
					>
						<DialogContentText>
							Ao excluir o campo <strong>{deleteField.display_name}</strong> , todas as informações cadastradas nesse
							campo para o seu contato, serão excluidas permanentemente.
						</DialogContentText>
						<DialogContentText>
							Essa ação é irreversível, portanto para certificar que você está excluindo
							a opção correta, digite o nome do campo a ser detelado.
						</DialogContentText>
						<TextField
							name="variable"
							variant="outlined"
							placeholder="Digite nome do campo"
							fullWidth
							helperText={deleteCustomFieldHelperText && "Nome do campo incorreto"}
							error={deleteCustomFieldHelperText}
							onChange={(({ target }) => setDeleteCustomFieldName(target.value))}
						/>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	</>
	)
}

export default GroupListVariable
