import React from "react"
import { WABAChannelChatCommunicationPermissionStatus } from "@/@integrations/WABA/protocols/wabaChannelChatCommunicationPermission"
import useStyles from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionTooltip/styles"
import { Grid, Tooltip } from "@material-ui/core"
import clsx from "clsx"
import { useWABAChatCommunicationPermission } from "@/@integrations/WABA/hooks/useWABAChatCommunicationPermission"
import { fullDatetime } from "@/utils/time"

type WABAChatCommunicationPermissionTooltipProps = {
	status: WABAChannelChatCommunicationPermissionStatus
	scheduledPauseAt?: Date
}

const WABAChatCommunicationPermissionTooltip: React.FC<WABAChatCommunicationPermissionTooltipProps> = (props) => {
	const {
		status,
		scheduledPauseAt
	} = props

	const classes = useStyles()

	const {
		canShowCommunicationTimeoutWarning,
		formattedSchedulePausedDate,
		statusMessage
	} = useWABAChatCommunicationPermission({ status, scheduledPauseAt })

	const permissionStatusMessage = status === "active" ? `${statusMessage} até: ${fullDatetime(formattedSchedulePausedDate)}` : statusMessage
	const expirationMessage = canShowCommunicationTimeoutWarning ? `Permissão de comunicação da WABA expira em breve ${fullDatetime(formattedSchedulePausedDate)}` : permissionStatusMessage

	return (
		<Tooltip
			title={expirationMessage}
		>
			<Grid item>
				<Grid
					className={clsx({
						[classes.statusIcon]: true,
						[classes.statusIconActive]: status === "active",
						[classes.statusIconAlert]: canShowCommunicationTimeoutWarning
					})}
				/>
			</Grid>
		</Tooltip>
	)
}

export default WABAChatCommunicationPermissionTooltip
