import React, { useState, useEffect } from "react"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, ThemeProvider, Typography } from "@material-ui/core"
import { Check, Close } from "@material-ui/icons"
import { Notification } from "@/components"
import { renderComponent } from "@/utils/node"
import { letalkLinks } from "@/utils/link"
import { DAY_IN_MS } from "@/utils/time"

import AccountService from "@/services/Account"
import StorageService from "@/services/Storage"
import DateService from "@/services/Date"

import upsellDialogImg from "@/assets/images/upsell-dialog-img.png"

import theme from "@/styles/theme"
import useStyles from "@/components/UpsellDialog/UpsellHandlerDialog/styles"
import UpsellSuccessfullyCompleted from "@/components/UpsellDialog/UpsellHandlerDialog/UpsellSuccessfullyCompleted"
import { isDevEnv } from "@/utils/environment"

import { UpsellPermissionType } from "@/protocols/planAddons"

type UpsellHandlerDialogProps = {
	type: UpsellPermissionType
	ignoreUserPreferences?: boolean // This property is used to ignore the functionality of saving to localStorage and not show the pop-up again for 15 days.
}

type UpsellTypeToResourceInfo = {
	id: number
	name: string
	quantity: number
}

type UpsellTypeToUpsellHandlerDialogContentProps = Record<UpsellPermissionType, {
	title: string
	description: string
	descriptionLink?: string
	resourceDescription: string
	benefits: string[]
	resource: UpsellTypeToResourceInfo
}>

type UpsellHandlerDialogType = {
	open: (props: UpsellHandlerDialogProps) => void
}

export const UPSELL_HANDLER_DIALOG_CONTENT: UpsellTypeToUpsellHandlerDialogContentProps = {
	"ai-text-enhancement-limit-reached-admin": {
		title: "Continue otimizando o seu atendimento com IA",
		description: "O seu time atingiu o limite de 50 otimizações gratuitas. Continue aprimorando suas respostas com nossa IA, tornando-as mais eficazes e personalizadas.",
		descriptionLink: "https://letalk.crunch.help/pt/primeiros-passos/de-um-toque-especial-as-suas-conversas-com-a-ia-da-letalk",
		benefits: [
			"Aprimore mensagens com textos mais claros e impactantes.",
			"Personalize o tom das mensagens para se adequar ao seu público.",
			"Comunique sem erros para responder com mais assertividade.",
			"Aumente a produtividade do seu time com respostas rápidas personalizadas."
		],
		resourceDescription: "Adicione até 1.500 otimizações mensais de texto com IA por apenas R$ 94,00.",
		resource: {
			id: isDevEnv ? 51 : 27,
			name: "Otimizações de texto com IA",
			quantity: 1500
		}
	}
}

type UserPreference = {
	doNotShowPopupAgain: boolean
	preferenceExpiresAt: Date
}

const USER_PREFERENCE_EXPIRATION_IN_DAYS = 15
const USER_PREFERENCE_STORAGE_KEY = "inbox:show-admin-upsell-dialog"

const UpsellHandlerDialog: UpsellHandlerDialogType & React.FC<UpsellHandlerDialogProps> = (props) => {
	const [isDialogOpen, setIsDialogOpen] = useState(true)
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const classes = useStyles()

	const { type, ignoreUserPreferences } = props
	const dialogContent = UPSELL_HANDLER_DIALOG_CONTENT[type as UpsellPermissionType]

	const saveUserPreference = () => {
		const userPreferenceExpirationInMilliseconds = DAY_IN_MS * USER_PREFERENCE_EXPIRATION_IN_DAYS
		const preferenceExpiresAt = new Date(DateService.currentWorldDate().getTime() + userPreferenceExpirationInMilliseconds)

		StorageService.set<UserPreference>(USER_PREFERENCE_STORAGE_KEY, {
			doNotShowPopupAgain: true,
			preferenceExpiresAt
		})
	}

	const canShowDialog = () => {
		const userPreference = StorageService.get<UserPreference>(USER_PREFERENCE_STORAGE_KEY)
		const isUserPreferenceExpired = userPreference && (new Date(userPreference.preferenceExpiresAt) < DateService.currentWorldDate())

		if (userPreference?.doNotShowPopupAgain && !isUserPreferenceExpired && ignoreUserPreferences) {
			return false
		}

		return true
	}

	const handleCreatePlanAddonInAccount = async () => {
		setLoading(true)
		try {
			await AccountService.createPlanAddonsInAccount({
				planAddon: {
					id: UPSELL_HANDLER_DIALOG_CONTENT[type as UpsellPermissionType].resource.id,
					name: UPSELL_HANDLER_DIALOG_CONTENT[type as UpsellPermissionType].resource.name,
					priceInCents: 9400
				},
				createdBy: "user",
				isUserReachSubscriptionLimit: true,
				upsellType: "ai-text-enhancement-limit-reached-admin"
			})
			setSuccess(true)
			Notification.success({
				message: "Contratação realizada com sucesso!"
			})
		} catch (error) {
			Notification.error({
				message: "Houve um erro ao contratar o adicional!"
			})
			setSuccess(false)
		} finally {
			setLoading(false)
		}
	}

	const handleCloseDialog = () => {
		setIsDialogOpen(false)
	}

	const handleCloseDialogAndSaveUserPreference = () => {
		saveUserPreference()
		setIsDialogOpen(false)
	}

	const handleSaveDialog = async () => {
		await handleCreatePlanAddonInAccount()
	}

	useEffect(() => {
		if (!canShowDialog()) {
			setIsDialogOpen(false)
		}
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={isDialogOpen}
				onClose={handleCloseDialog}
				maxWidth="md"
				fullWidth
			>
				<DialogContent className={classes.dialogContent}>
					<Grid container>
						{success ? (
							<Grid item md={8}>
								<UpsellSuccessfullyCompleted upsellDescription={dialogContent.resource.name} upsellQuantity={dialogContent.resource.quantity} onClose={handleCloseDialog} />
							</Grid>
						) : (
							<Grid md={8} item>
								<Typography variant="h3" className={classes.dialogTitle}>
									{dialogContent.title}
								</Typography>
								<DialogContentText className={classes.dialogDescription}>
									{dialogContent.description}<a className={classes.dialogDescriptionLink} href={dialogContent.descriptionLink} target="_blank" rel="noopener noreferrer">Saiba mais!</a>
								</DialogContentText>
								<ul className={classes.benefitsList}>
									{dialogContent.benefits.map((benefit, index) => (
										<li key={index} className={classes.benefitItem}>
											<Check className={classes.benefitItemIcon} />
											<Typography variant="body1" className={classes.benefitItemText}>{benefit}</Typography>
										</li>
									))}
								</ul>
								<Typography variant="h6" className={classes.planAddonsInformationText}>
									{dialogContent.resourceDescription}
								</Typography>
								<DialogActions className={classes.dialogActions}>
									<Box>
										<Button variant="contained" endIcon={loading ? <CircularProgress size={20} color={"inherit"} /> : null} className={classes.dialogActionPrimaryButton} disabled={loading} onClick={handleSaveDialog}>
											Adicionar agora mesmo
										</Button>
										<Typography className={classes.termsOfUse} variant="caption" color="textSecondary">Ao clicar, você concorda com os <a className={classes.termsOfUseLink} href={letalkLinks.notionTermsOfService} target="_blank" rel="noopener noreferrer">termos de uso</a></Typography>
									</Box>
									<Button variant="text" className={classes.dialogActionSecondaryButton} onClick={ignoreUserPreferences ? handleCloseDialogAndSaveUserPreference : handleCloseDialog}>
										{ignoreUserPreferences ? "Ver novamente daqui 15 dias" : "Cancelar"}
									</Button>
								</DialogActions>
							</Grid>
						)}

						<Grid md={4} className={classes.imageContainer} item>
							<img
								alt="Mulher feliz segurando o celular na mão esquerda"
								className={classes.dialogImage}
								src={upsellDialogImg}
							/>
							<IconButton className={classes.closeButton} onClick={handleCloseDialog}>
								<Close />
							</IconButton>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</ThemeProvider>
	)
}

UpsellHandlerDialog.open = (props: UpsellHandlerDialogProps) => {
	renderComponent(
		props.type,
		<UpsellHandlerDialog
			{...props}
		/>
	)
}

export default UpsellHandlerDialog
