import { makeStyles } from "@material-ui/core"
import newColors from "@/styles/newColors"

const useStyles = makeStyles(() => ({
	statusIcon: {
		width: 12,
		height: 12,
		borderRadius: "100%",
		borderWidth: "2px",
		borderStyle: "solid",
		backgroundColor: newColors.grey[600],
		borderColor: newColors.grey[400]
	},
	statusIconActive: {
		backgroundColor: newColors.green[600],
		borderColor: newColors.green[400]
	},
	statusIconAlert: {
		backgroundColor: newColors.yellow[500],
		borderColor: newColors.yellow[300]
	}
}))

export default useStyles
