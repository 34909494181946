import React from "react"
import clsx from "clsx"
import { Grid, Typography, Chip } from "@material-ui/core"

import { WABAChannelChatCommunicationPermissionStatus } from "@/@integrations/WABA/protocols/wabaChannelChatCommunicationPermission"
import { fullDatetime } from "@/utils/time"

import useStyles from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionBar/styles"
import { useWABAChatCommunicationPermission } from "@/@integrations/WABA/hooks/useWABAChatCommunicationPermission"

type WABAChatCommunicationPermissionBarProps = {
	status: WABAChannelChatCommunicationPermissionStatus
	scheduledPauseAt?: Date
}

const WABAChatCommunicationPermissionBar: React.FC<WABAChatCommunicationPermissionBarProps> = (props) => {
	const {
		status,
		scheduledPauseAt
	} = props

	const classes = useStyles()

	const {
		canShowCommunicationTimeoutWarning,
		formattedSchedulePausedDate,
		canShowPauseDate,
		statusMessage
	} = useWABAChatCommunicationPermission({ status, scheduledPauseAt })

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={classes.container}
		>
			<Grid item>
				<Grid
					container
					spacing={1}
					alignItems="center"
				>
					<Grid item>
						<Grid
							className={clsx({
								[classes.statusIcon]: true,
								[classes.statusIconActive]: status === "active",
								[classes.statusIconAlert]: canShowCommunicationTimeoutWarning
							})}
						/>
					</Grid>
					<Grid item>
						<Typography
							variant="body1"
							className={classes.statusMessage}
						>
							{statusMessage}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{canShowPauseDate && (
				<Grid item>
					<Chip
						label={`${canShowCommunicationTimeoutWarning ? "Expira em breve: " + fullDatetime(formattedSchedulePausedDate) : "Ativa até " + fullDatetime(formattedSchedulePausedDate)}`}
						className={clsx({
							[classes.pauseDateChip]: true,
							[classes.alertDateChip]: canShowCommunicationTimeoutWarning
						})}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default WABAChatCommunicationPermissionBar
