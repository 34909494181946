import _ from "lodash"
import React, { useState } from "react"
import {
	Button,
	Chip,
	Grid,
	MenuItem,
	TextField,
	Typography,
	Checkbox,
	FormControlLabel,
	InputLabel,
	Tooltip,
	Divider as MuiDivider,
	Link
} from "@material-ui/core"

import {
	Divider,
	ActionDialog,
	Notification,
	InfoDialog,
	Loading,
	PopConfirm,
	UpsellDialog
} from "@/components"

import ApiService from "@/services/Api"
import TeamService from "@/services/Team"
import UserInInstanceService from "@/services/UserInInstance"
import HardCoded from "@/services/HardCoded"

import InvitePendingCard from "@/pages/Admin/Attendant/components/InvitePendingCard"
import UserInInstanceCard from "@/pages/Admin/Attendant/components/UserInInstanceCard"
import Breadcrumb from "@/components/BreadcrumbNew"
import UpsellQuantityDialog from "@/components/UpsellDialog/UpsellQuantityDialog"
import UpsellAttendantAlertContainer from "@/pages/Admin/Attendant/components/UpsellAttendantAlertContainer"

import AdminInvitePendingSkeleton from "@/skeletons/Admin/InvitePending"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useSubscriptionLimits from "@/hooks/useSubscriptionLimits"
import { useGlobalStateStore } from "@/store/GlobalState"

import { formatEmail } from "@/utils/mask"
import { getErrorName } from "@/utils/response"
import { onlyLettersNumbersAndAccents } from "@/utils/string"

import {
	Team
} from "@/protocols/team"

import {
	Add,
	Person as AttendantIcon
} from "@material-ui/icons"

import {
	UserInInstance,
	UserInInstanceRoleCode,
	UserInInstanceStatus
} from "@/protocols/userInInstance"

import colors from "@/styles/colors"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Attendant/styles"
import newColors from "@/styles/newColors"
import { letalkLinks } from "@/utils/link"

type InviteTokenDataProps = {
	openDialog: boolean
	invitation_link?: string
	name?: string
	email?: string
}

type TeamDataProps = Team & {
	instanceId?: number
	selected: boolean
}

type ChangeUserInInstanceData = Omit<UserInInstance, "teams" | "id" | "role_code" | "status"> & {
	id?: number
	role_code?: UserInInstanceRoleCode
	status?: UserInInstanceStatus
	teams: TeamDataProps[]
	isOwner: boolean
}

type UserInvited = ChangeUserInInstanceData & {
	invitation_link: string
}

export type SubscriptionUserQuantityStatusType = "user_reach_limit" | "user_is_reaching_the_limit"

type DialogActions = "can-block-create-user-in-waba-channel" | "can-block-create-user-in-whatsapp-channel" | "can-create-user"

const Attendant: React.FC = () => {
	const customClasses = useCustomStyles()
	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const globalStateStore = useGlobalStateStore()

	const classes = useStyles()
	const userQuantityLimit = useSubscriptionLimits("quantity", "user")

	const [inviteTokenData, setInviteTokenData] = useState<InviteTokenDataProps>({
		openDialog: false
	})

	const [usersInInstance, setUsersInInstance] = useState<ChangeUserInInstanceData[]>([])
	const [usersInInstanceLoading, setUsersInInstanceLoading] = useState<boolean>(false)

	const [usersInvited, setUsersInvited] = useState<UserInvited[]>([])
	const [usersInvitedLoading, setUsersInvitedLoading] = useState<boolean>(false)

	const totalAttendantsCreated = usersInvited?.length + usersInInstance?.length

	const canBlockUserCreation = userQuantityLimit.mustBlock && !HardCoded.checkFeatureFlag("canIgnoreAttendantQuantityLimit")

	const subscriptionUserLimits = globalStateStore.instance.subscriptionData?.limits?.quantity?.user || 0

	const getSubscriptionUserLimitStatus = (): SubscriptionUserQuantityStatusType | null => {
		if (userQuantityLimit.mustBlock) {
			return "user_reach_limit"
		}

		const diffBetweenSubscriptionUserLimitsAndCreatedUsers = subscriptionUserLimits - totalAttendantsCreated

		if (diffBetweenSubscriptionUserLimitsAndCreatedUsers === 1) {
			return "user_is_reaching_the_limit"
		}

		return null
	}

	const subscriptionUserLimitStatus = getSubscriptionUserLimitStatus()

	const isWhatsappChannel = globalStateStore.currentChannel?.channelType === "whatsapp"
	const canShowUpsellAlertForAttendant = Boolean(subscriptionUserLimits) && isWhatsappChannel

	const [userInInstance, setUserInInstance] = useState<ChangeUserInInstanceData>({
		name: "",
		email: "",
		teams: [],
		isOwner: false,
		type: "human"
	})

	const [openedUserInInstanceDialog, setOpenedUserInInstanceDialog] = useState<boolean>(false)
	const [userInInstanceLoading, setUserInInstanceLoading] = useState<boolean>(false)

	const [teams, setTeams] = useState<TeamDataProps[]>([])

	const inviteDialogOpen = (invitation_link: string, name: string, email: string) => {
		setInviteTokenData({
			openDialog: true,
			invitation_link,
			name,
			email
		})
		userQuantityLimit.resetLimit()
	}

	const openUserInInstanceDialog = (user: ChangeUserInInstanceData) => {
		setUserInInstance({
			...user,
			teams: teams.map(team => ({
				...team,
				selected: _.findIndex(user.teams, { id: team.id }) >= 0
			}))
		})

		setOpenedUserInInstanceDialog(true)
	}

	const handleUserInInstanceDataChange = (key: string, value: unknown) => {
		clearValidation(key)

		setUserInInstance({
			...userInInstance,
			[key]: value
		})
	}

	const getUsersInvited = async () => {
		setUsersInvitedLoading(true)

		try {
			const { data } = await ApiService.get("/users/invited")

			setUsersInvited(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setUsersInvitedLoading(false)
	}

	const getUsersInInstance = async () => {
		if (!usersInInstance) {
			setUsersInInstanceLoading(true)
		}

		const data = await UserInInstanceService.getUsersInInstance({
			status: ["active", "blocked"],
			type: ["human"]
		})

		if (data) {
			setUsersInInstance(data as ChangeUserInInstanceData[])
		}

		setUsersInInstanceLoading(false)
	}

	const handleUserInInstanceInvitationSave = async () => {
		setUserInInstanceLoading(true)

		try {
			const userInInstanceTeams = userInInstance.teams
			const userInInstanceTeamsFiltered = userInInstanceTeams.filter(team => team.selected)

			const { data } = await ApiService.post("/user", {
				name: userInInstance.name,
				email: userInInstance.email,
				role_code: userInInstance.role_code,
				teams: userInInstanceTeamsFiltered.map(team => team.id)
			})

			setOpenedUserInInstanceDialog(false)

			inviteDialogOpen(
				data.invitation_link,
				(userInInstance.name as string),
				(userInInstance.email as string)
			)

			getUsersInvited()
			getUsersInInstance()

			Notification.success({ message: "O convite foi gerado com sucesso!" })
		} catch (error) {
			const errorName = getErrorName(error as ErrorType)

			if (errorName === "invitation_request_already_exists_for_this_email") {
				Notification.warning({ message: "Já existe uma solicitação criada para esse email" })
				return
			} else {
				triggerValidation(error as ErrorType)
			}
		}

		setUserInInstanceLoading(false)
	}

	const getTeams = async () => {
		try {
			const teams = await TeamService.getTeams()

			setTeams(teams as TeamDataProps[])
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleTeamCheckboxSelected = (teamIndex: number) => {
		const userInInstanceTeams = userInInstance.teams

		userInInstanceTeams[teamIndex].selected = !userInInstanceTeams[teamIndex].selected

		setUserInInstance({
			...userInInstance,
			teams: userInInstanceTeams
		})
	}

	const handleUserInInstanceSave = async () => {
		setUserInInstanceLoading(true)

		try {
			const userInInstanceTeams = userInInstance.teams
			const userInInstanceTeamsFiltered = userInInstanceTeams.filter(team => team.selected)

			await ApiService
				.put(`/user/${userInInstance.id}`, {
					name: userInInstance.name,
					email: userInInstance.email,
					role_code: userInInstance.role_code,
					teams: userInInstanceTeamsFiltered.map(team => team.id)
				})

			setOpenedUserInInstanceDialog(false)

			getUsersInInstance()

			Notification.success({ message: "O usuário atualizado com sucesso!" })
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setUserInInstanceLoading(false)
	}

	const handleUserInInstanceClose = async () => {
		setOpenedUserInInstanceDialog(false)
	}

	const handleDeleteUser = async (user: ChangeUserInInstanceData) => {
		PopConfirm.open({
			title: `Excluir ${user.status === "invited" ? "convite" : "usuário"}`,
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/user/${user.id}`)
					getUsersInvited()
					getUsersInInstance()
					userQuantityLimit.resetLimit()
				} catch (error) {
					const errorName = getErrorName(error as ErrorType)

					if (errorName === "HaveAttendencesAssociateWithUser") {
						Notification.warning({ message: "Existe atendimentos vinculados a esse usuario." })
					} else if (errorName === "InstanceOwnerUserCanNotBeDeleted") {
						Notification.warning({ message: "Esse usuário é dono desse Inbox e não pode ser excluído." })
					} else if (errorName === "UserHaveTeams") {
						Notification.warning({ message: "Esse usuário possui uma ou mais equipes e por isso não pode ser excluído" })
					} else {
						triggerValidation(error as ErrorType)
					}
				}
			}
		})
	}

	const handleBlockUser = async (user: ChangeUserInInstanceData) => {
		PopConfirm.open({
			title: `${user.status === "blocked" ? "Desbloquear" : "Bloquear"} usuário`,
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: `${user.status === "blocked" ? "DESBLOQUEAR" : "BLOQUEAR"}`,
			onConfirm: async () => {
				try {
					await ApiService.put(`/user/${user.id}/block`)

					getUsersInInstance()
				} catch (error) {
					triggerValidation(error as ErrorType)
				}
			}
		})
	}

	const handleGetDialogUserCreation = () => {
		const dialogActionToDialogUserCreation: Record<DialogActions, () => void> = {
			"can-block-create-user-in-waba-channel": () => (
				UpsellDialog.open({
					dialogCode: userQuantityLimit.blockCode
				})
			),
			"can-block-create-user-in-whatsapp-channel": () => (
				UpsellQuantityDialog.open({
					type: "user",
					actualQuantity: subscriptionUserLimits,
					isUserReachSubscriptionLimit: true
				})
			),
			"can-create-user": () => (
				openUserInInstanceDialog({
					name: "",
					email: "",
					teams: [],
					isOwner: false,
					type: "human"
				})
			)
		}

		const canBlockUserCreationInWhatsappChannel = canBlockUserCreation && isWhatsappChannel
		const canBlockUserCreationInWABAChannel = canBlockUserCreation && !isWhatsappChannel

		let dialogAction: DialogActions

		if (canBlockUserCreationInWABAChannel) {
			dialogAction = "can-block-create-user-in-waba-channel"
		} else if (canBlockUserCreationInWhatsappChannel) {
			dialogAction = "can-block-create-user-in-whatsapp-channel"
		} else {
			dialogAction = "can-create-user"
		}

		return dialogActionToDialogUserCreation[dialogAction]()
	}

	useDidMount(() => {
		getUsersInvited()
		getUsersInInstance()
		getTeams()
	})

	return (
		<>
			<Breadcrumb
				data={[
					{ name: "Atendentes", pathname: "/admin/attendant" }
				]}
			/>

			<Divider orientation="horizontal" size={2} />

			<Grid container justifyContent="space-between" alignItems="center" spacing={2}>
				<Grid item>
					<Grid
						container
						alignItems="center"
						spacing={2}
					>
						<Grid
							item
						>
							<Typography
								variant="h5"
								className={classes.titleText}
							>
								Atendentes
							</Typography>
						</Grid>

						<Grid
							item
						>
							<Tooltip
								title="Quantidade de atendentes cadastrados"
								placement="right"
							>
								<Grid
									className={classes.attendantToggle}
									style={{
										backgroundColor: subscriptionUserLimitStatus === "user_reach_limit" ? colors.unrelated.FAC87D : newColors.purple[100]
									}}
								>
									<AttendantIcon fontSize="small" style={{ color: subscriptionUserLimitStatus === "user_reach_limit" ? newColors.yellow[500] : colors.palette.primary }} />
									<Typography className={classes.attendantToggleText}>
										{`${totalAttendantsCreated} de ${subscriptionUserLimits}`}
									</Typography>
								</Grid>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={handleGetDialogUserCreation}
						startIcon={<Add />}
					>
						Adicionar Novo Atendente
					</Button>
				</Grid>
			</Grid>

			<Link
				href={letalkLinks.wikiHowToRegisteringAttendants}
				underline="always"
				target="_blank"
				color="inherit"
			>
				Convide sua equipe para a Letalk agora mesmo
			</Link>

			<Divider size={6} orientation="horizontal" />

			{canShowUpsellAlertForAttendant && (
				<>
					<UpsellAttendantAlertContainer
						subscriptionUserStatus={subscriptionUserLimitStatus}
						actualAttendantsQuantity={subscriptionUserLimits}
					/>

					<Divider size={6} orientation="horizontal" />
				</>
			)}

			<Loading
				loading={usersInvitedLoading}
				customLoadingElement={<AdminInvitePendingSkeleton />}
			>
				<Grid container>
					{
						usersInvited.length
							? (
								<>
									{usersInvited.map((user, index) => (
										<Grid item xs={12} key={index}>
											<InvitePendingCard
												invitedUserId={Number(user.id)}
												name={user.name}
												email={user.email}
												inviteLink={user.invitation_link}
												handleDelete={() => handleDeleteUser(user)}
											/>

											<Divider size={2} orientation="horizontal" />
										</Grid>
									))}

									<Divider size={2} orientation="horizontal" />

									<Grid item xs={12}>
										<MuiDivider />
									</Grid>
								</>
							) : null
					}
				</Grid>
			</Loading>

			<Divider size={3} orientation="horizontal" />

			<Loading
				loading={usersInInstanceLoading}
				customLoadingElement={<AdminInvitePendingSkeleton />}>
				<Grid container>
					{
						usersInInstance.length
							? usersInInstance.map((userInInstance, index) => (
								<Grid item xs={12} key={index}>
									<UserInInstanceCard
										name={userInInstance.name}
										email={userInInstance.email}
										status={userInInstance.status}
										role_code={userInInstance.role_code}
										isOwner={userInInstance.isOwner}
										teams={userInInstance.teams}
										onClickEditButton={() => openUserInInstanceDialog(userInInstance)}
										onClickDeleteButton={() => handleDeleteUser(userInInstance)}
										onClickBlockButton={() => handleBlockUser(userInInstance)}
									/>

									<Divider size={2} orientation="horizontal" />
								</Grid>
							))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2">
									Nenhum membro cadastrado
								</Typography>
							</Grid>
					}
				</Grid>
			</Loading>

			<ActionDialog
				id="edit-or-invite-new-attendant"
				saveButtonId="edit-or-invite-new-attendant-save-button"
				title={userInInstance?.id ? "Editar Atendente" : "Convidar novo membro"}
				onSave={() => userInInstance?.id ? handleUserInInstanceSave() : handleUserInInstanceInvitationSave()}
				saveText={userInInstance?.id ? "SALVAR" : "CONVIDAR"}
				onClose={handleUserInInstanceClose}
				openDialog={openedUserInInstanceDialog}
				loading={userInInstanceLoading}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<InputLabel className={customClasses.inputLabel}>
							Nome
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<TextField
							id="input-name"
							name="name"
							value={userInInstance.name}
							onChange={
								({ target }) => handleUserInInstanceDataChange("name",
									onlyLettersNumbersAndAccents(target.value))
							}
							variant="outlined"
							placeholder="Ex: José Amilton"
							fullWidth
							helperText={validation.name}
							error={!!validation.name}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid item xs={12}>
						<InputLabel className={customClasses.inputLabel}>
							Email
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<TextField
							id="input-email"
							name="email"
							value={userInInstance.email}
							onChange={
								({ target }) => handleUserInInstanceDataChange("email", formatEmail(target.value))
							}
							variant="outlined"
							placeholder="example@email.com"
							fullWidth
							helperText={validation.email}
							error={!!validation.email}
							disabled={userInInstance.isOwner}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid item xs={12}>
						<InputLabel className={customClasses.inputLabel}>
							Tipo de Usuário
						</InputLabel>

						<Divider orientation="horizontal" size={1} />

						<TextField
							id="input-user-type"
							name="role_code"
							select
							placeholder="Tipo de usuário"
							value={userInInstance.role_code || "no-select"}
							onChange={
								({ target }) => target.value !== "no-select" && handleUserInInstanceDataChange("role_code", target.value)
							}
							variant="outlined"
							fullWidth
							helperText={validation.role_code}
							error={!!validation.role_code}
							disabled={userInInstance.isOwner}
						>
							<MenuItem value={"no-select"} disabled>
								Tipo de usuário
							</MenuItem>

							<MenuItem value={"admin"}>
								Gestor
							</MenuItem>

							<MenuItem value={"attendant"}>
								Atendente
							</MenuItem>
						</TextField>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12}>
						<Typography
							color="textPrimary"
							variant="h2"
							className={classes.nameText}
						>
							A quais equipes o atendente pertence?
						</Typography>

						<Grid container className={classes.teamsListContainer}>
							{
								userInInstance.teams.length
									? userInInstance.teams.map((team, index) => (
										<Grid item xs={12} key={index}>
											<FormControlLabel label={team.name} control={
												<Checkbox
													key={index}
													checked={team.selected}
													onChange={() => handleTeamCheckboxSelected(index)}
												/>
											} />
										</Grid>
									))
									: <Grid item xs={12}>
										<Typography align="center" variant="h2" className={classes.noTeamListed}>
											Nenhuma equipe cadastrada.
										</Typography>
									</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>

			<InfoDialog
				title={"Convite foi gerado!"}
				openDialog={inviteTokenData.openDialog}
			>
				<Grid
					container
					alignContent="center"
					className={classes.copyContainer}
				>
					<Grid item xs={12}>
						<Grid container justify="space-between" alignItems="center">
							<Grid item xs={6}>
								<Typography
									color="textPrimary"
									variant="h2"
								>
									{inviteTokenData.name}
								</Typography>

								<Typography
									color="textPrimary"
									variant="caption"
								>
									{inviteTokenData.email}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Grid
									container
									justify={"flex-end"}
								>
									<Chip label="Convite Pendente" className={classes.chipPending} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid
						item
						xs={12}
					>
						<Typography
							color="textPrimary"
							variant="body1"
						>
							O convite do usuário <strong>{inviteTokenData.name}</strong> foi enviado para o email <strong>{inviteTokenData.email?.toLowerCase()}</strong>, é necessário clicar no link enviado por email para acessar a plataforma
						</Typography>
					</Grid>
				</Grid>

			</InfoDialog>
		</>
	)
}

export default Attendant
