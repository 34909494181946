import React from "react"
import {
	Chip,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core"
import {
	Delete as DeleteIcon,
	Replay as ReplayIcon
} from "@material-ui/icons"

import {
	Divider,
	MoreOptionsMenu,
	Notification,
	Portlet,
	TextCopyNew
} from "@/components"

import useStyles from "@/pages/Admin/Attendant/styles"
import HardCoded from "@/services/HardCoded"
import ApiService from "@/services/Api"

type InvitePendingCardProps = {
	invitedUserId: number
	name: string
	email: string
	inviteLink: string

	handleDelete: () => void
}

const InvitePendingCard: React.FC<InvitePendingCardProps> = (props) => {
	const { name, email, inviteLink, invitedUserId, handleDelete } = props

	const classes = useStyles()
	const MuiTheme = useTheme()

	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	const handleResendAccess = async () => {
		try {
			await ApiService.post("/user-in-instances/invite/resend", {
				email,
				invitedUserId
			})

			Notification.success({ message: "O convite foi reenviado com sucesso!" })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao reenviar convite!" })
		}
	}

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center" spacing={6} className={classes.inviteCard}>
				<Grid
					item xs
					className={classes.textContainer}>
					<Typography
						color="textPrimary"
						variant="h2"
						className={classes.nameText}
					>
						{name}
					</Typography>
					<Divider size={1} orientation="horizontal" />
					<Typography
						color="textPrimary"
						variant="body2"
					>
						{email}
					</Typography>
				</Grid>

				<Grid item xs>
					<Grid
						container
						justify={isSmall ? "flex-end" : "flex-start"}
					>
						<Chip size="medium" label="Convite Pendente" className={classes.chipPending}/>
					</Grid>
				</Grid>

				{
					HardCoded.checkFeatureFlag("copyRawInviteLink") &&
					<Grid item xs={!isSmall ? 6 : 12}>
						<Grid container justify="flex-end" alignItems="center" style={{ height: "100%" }}>
							<Grid item xs={12} sm={10} md={11}>
								<Grid
									container
									alignContent="center"
									className={classes.copyContainer}
								>
									<TextCopyNew
										text={inviteLink}
										onSuccessMessage="Link copiado com sucesso!"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				}

				<Divider size={4} orientation='vertical'/>

				<Grid item>
					<Grid container justify="flex-end">
						<Grid container className={classes.iconButton} justifyContent="center" alignItems="center">
							<MoreOptionsMenu
								options={[
									{
										title: "Reenviar acesso",
										icon: <ReplayIcon />,
										showIcon: true,
										onClick: handleResendAccess
									},
									{
										title: "Excluir",
										icon: <DeleteIcon />,
										showIcon: true,
										color: "danger",
										onClick: async () => { handleDelete?.() }
									}
								]}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>

	)
}

export default InvitePendingCard
