import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	}
})

const ScheduledSnoozeContentSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={8}>
								<Skeleton className={classes.skeleton} height={20} />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<Skeleton className={classes.skeleton} height={100} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ScheduledSnoozeContentSkeleton
