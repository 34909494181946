import React, { ReactNode } from "react"
import { Tooltip, Typography, Link } from "@material-ui/core"

import useStyles from "@/components/ProductReportChatbot/styles"

import useCustomStyles from "@/styles/custom"

type Chatbot = {
	id: number
	name: string
	deleted_at: string | null
}

type ProductReportChatbotInternalStatus = "default" | "existent-chatbot" | "deleted-chatbot" | "inexistent-chatbot"

type ProductReportInfo = {
	content: ReactNode,
	textComponentClasses?: string
	helpText?: string
	linkURL?: string,
	linkClasses?: string,
	onLinkClick?: () => void,
}

type ProductReportChatbotInternalStatusToInfo = Record<ProductReportChatbotInternalStatus, ProductReportInfo>

export type ProductReportChatbotProps = {
	chatbot: Chatbot | null
	status?: ProductReportChatbotInternalStatus
}

const ProductReportChatbot: React.FC<ProductReportChatbotProps> = (props) => {
	const { chatbot } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleLinkClick = (path: string) => {
		const baseUrl = window.location.origin

		const fullLink = `${baseUrl}${path}`

		window.open(fullLink, "_blank")
	}

	const productReportChatbotInternalStatusToInfo: ProductReportChatbotInternalStatusToInfo = {
		default: {
			content: "Status desconhecido",
			helpText: "Não foi possível determinar esse status",
			linkClasses: customClasses.noAnchorStyle
		},
		"existent-chatbot": {
			content: chatbot?.name,
			textComponentClasses: `${customClasses.hyperlink} ${classes.chatBotName}`,
			linkClasses: customClasses.noUnderline,
			onLinkClick: () => handleLinkClick(`/admin/flow/${chatbot?.id}`)
		},
		"deleted-chatbot": {
			content: String(chatbot?.id),
			helpText: "Esse bot foi excluído",
			textComponentClasses: classes.unknown,
			linkClasses: customClasses.noAnchorStyle
		},
		"inexistent-chatbot": {
			content: "-",
			helpText: "Dado não presente",
			textComponentClasses: classes.unknown,
			linkClasses: customClasses.noAnchorStyle
		}
	}

	const chatbotRecordExists = Boolean(chatbot)
	const chatbotHasDeletedFlag = Boolean(chatbot?.deleted_at)

	let status: ProductReportChatbotInternalStatus = "default"

	const handleGetProductReportInfo = () => {
		const chatbotExists = chatbotRecordExists && !chatbotHasDeletedFlag
		const isChatbotDeleted = chatbotRecordExists && chatbotHasDeletedFlag
		const isChatbotInexistent = !chatbotRecordExists

		if (chatbotExists) {
			status = "existent-chatbot"
		} else if (isChatbotDeleted) {
			status = "deleted-chatbot"
		} else if (isChatbotInexistent) {
			status = "inexistent-chatbot"
		} else {
			status = "default"
		}

		return productReportChatbotInternalStatusToInfo[status]
	}

	const {
		content,
		helpText = "",
		textComponentClasses,
		linkClasses,
		onLinkClick
	} = handleGetProductReportInfo()

	return <>
		<Tooltip title={helpText}>
			<Link
				target="_blank"
				variant="body1"
				color="textPrimary"
				className={linkClasses}
				onClick={onLinkClick}
			>
				<Typography
					className={textComponentClasses}
				>
					{content}
				</Typography>
			</Link>
		</Tooltip>

	</>
}

export default ProductReportChatbot
