import React, { useState } from "react"
import slugify from "slugify"
import copy from "copy-to-clipboard"
import {
	Grid,
	Switch,
	Typography,
	IconButton,
	Tooltip,
	Chip
} from "@material-ui/core"
import {
	Edit as EditContentIcon,
	Delete as DeleteIcon,
	InfoOutlined as InfoIcon
} from "@material-ui/icons"
import clsx from "clsx"

import PhoneInputComponent, { OnChangePhoneInputParams } from "@/components/PhoneInput"
import {
	Portlet,
	Divider,
	Notification,
	PopConfirm,
	ActionDialog
} from "@/components"

import { variablesFromText } from "@/utils/payloadFactory"

import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

import useStyles from "@/pages/Admin/Integration/Management/Messages/MessageListItem/styles"
import useCustomStyles from "@/styles/custom"
import useManagementStyles from "@/pages/Admin/Integration/Management/styles"

import { integrationInfo } from "@/utils/integration"

import {
	IntegrationPlatformVariable,
	IntegrationType,
	IntegrationPlatformEvent,
	IntegrationCustomWebhook
} from "@/protocols/integration"

import axios from "axios"
import buildPayload from "@/pages/Admin/Integration/Management/Messages/MessageListItem/payloadBuilder"

type MessageListItemProps = {
	integrationType: IntegrationType
	webhook: IntegrationCustomWebhook
	platformVariables: IntegrationPlatformVariable[]
	platformEvents: IntegrationPlatformEvent[]
	onToggleActive: (value: boolean) => void
	onDelete: () => Promise<void>
	onEdit: () => void
}

const MessageListItem: React.FC<MessageListItemProps> = (props) => {
	const {
		children,
		onToggleActive,
		onDelete,
		onEdit,
		platformEvents,
		integrationType,
		webhook
	} = props

	const [openTestDialog, setOpenTestDialog] = useState(false)
	const [testPhone, setTestPhone] = useState("55")
	const [sendingTestMessage, setSendingTestMessage] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const managementClasses = useManagementStyles()

	const currentContent = webhook.content
	const active = webhook.isActive
	const webhookUrl = webhook.url
	const triggerType = webhook.triggerType
	const title = webhook.title
	const platformEvent = platformEvents.find(event => event.id === webhook.integrationPlatformEventId)

	const handleCopyWebhookUrl = (url: string): void => {
		copy(url)

		Notification.success({ message: "Link copiado com sucesso!" })
	}

	const mountWebhookUrl = (url: string, title = "") => `${url}/${slugify(title.toLowerCase(), {
		remove: /[*+~.()'"!:@?]/g
	}).replace(/[^a-zA-Z0-9-]/g, "")}`

	const handleToggleActive = (value: boolean) => {
		onToggleActive(value)
	}

	const handleDelete = async () => {
		PopConfirm.open({
			title: "Excluir Mensagem",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => await onDelete()
		})
	}

	const handleEdit = () => {
		onEdit()
	}

	const callTestWebhook = async () => {
		setSendingTestMessage(true)

		const variables = variablesFromText(
			currentContent.messages?.map(msg => msg.content) as string[]
		)
		const payload = buildPayload(testPhone, variables, integrationType, platformEvent)
		try {
			await axios.post(webhookUrl, payload)
			Notification.success({ message: "Messagem de teste enviada. Verifique o resultado no relatório" })
			setOpenTestDialog(false)
		} catch (err) {
			console.error(err)
			Notification.error({ message: "Ocorreu um erro ao enviar ao enviar a mensagem" })
		}

		setSendingTestMessage(false)
	}

	const handleChangePhoneInput = (phoneData: OnChangePhoneInputParams) => {
		setTestPhone(phoneData.rawPhone)
	}

	/**
	 * - Obs: We decided to disable switch since it looks like to be useless to the
	 * end customer, so we are hiding it to test it is really useless or not.
	 */
	const showSwitchButtonTemporary = !active

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						container
						alignItems="center"
						className={clsx({
							[classes.portletInfoContainer]: true,
							[classes.portletLimitedSize]: Boolean(children)
						})}
					>
						{showSwitchButtonTemporary && (
							<>
								<Switch
									size="small"
									checked={active}
									onChange={({ target }) => handleToggleActive(target.checked)}
								/>

								<Divider orientation="vertical" size={2} />
							</>
						)}

						<Typography
							variant="body1"
							className={clsx({
								[customClasses.inlineText]: Boolean(children)
							})}
						>
							<b>{title}</b>
						</Typography>
					</Grid>
					<IconButton
						onClick={handleEdit}
					>
						<EditContentIcon />
					</IconButton>

					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid
					container
					direction="column"
					alignItems="flex-start"
					justify="flex-start"
					spacing={1}
				>
					{currentContent?.messages?.map(message => (
						<Grid
							item
							xs={12}
							key={message.id}
						>
							<Grid
								container
							>
								<MessageItem
									type={message.type}
									content={message.content}
									mediaName={message.mediaName}
								/>
							</Grid>
						</Grid>
					))}
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					container
				>
					<Typography
						variant="body1"
					>
						<b>Quando enviar a mensagem:</b>

						{" "}

						{triggerType === "platform-event" && (
							`Quando ocorrer o evento “${platformEvent?.title}” no ${integrationInfo[integrationType]?.title}`
						)}

						{triggerType === "custom-url" && (
							"Quando acessar especificamente esse link abaixo:"
						)}
					</Typography>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<Grid
					container
				>
					<Grid
						container
						alignItems="center"
					>
						<Typography
							variant="body1"
							className={managementClasses.categoryTitle}
						>
							{mountWebhookUrl(webhookUrl, title)}
						</Typography>

						<Divider orientation="vertical" size={2} />

						<Tooltip
							title="Use esse link para disparar mensagens apenas através da chamada dele pela plataforma"
						>
							<InfoIcon />
						</Tooltip>

						<Divider orientation="vertical" size={2} />

						<Chip
							label="Copiar link"
							onClick={() => handleCopyWebhookUrl(mountWebhookUrl(webhookUrl, title))}
							className={classes.copyWebhookUrlChip}
						/>

						<Divider orientation="vertical" size={2} />

						<ActionDialog
							saveButtonId="send-integration-test-message-save-button"
							title="Envie uma mensagem de teste"
							openDialog={openTestDialog}
							onClose={() => { setOpenTestDialog(false) }}
							onSave={callTestWebhook}
							loading={sendingTestMessage}
							saveText="ENVIAR"
							fullWidth
						>
							<PhoneInputComponent
								id="send-integration-test-message"
								value={testPhone}
								specialLabel="WhatsApp"
								country={"br"}
								preferredCountries={["br", "pt", "us"]}
								onChange={handleChangePhoneInput}
							/>
						</ActionDialog>

						<Chip
							label="Testar"
							className={classes.testWebhookUrlChip}
							onClick={() => { setOpenTestDialog(true) }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default MessageListItem
