import { Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core"
import { MoreVert as MoreVertIcon } from "@material-ui/icons"
import React from "react"
import useStyles from "@/components/MoreOptionsMenu/styles"
import colors from "@/styles/colors"
import DisableComponentByChannel from "@/components/DisableComponentByChannel"

type MoreOptionsMenuOptionData = {
	title: string
	icon?: JSX.Element
	showIcon?: boolean
	color?: "danger"
	onClick?: () => Promise<void> | void
}

export interface MoreOptionsMenuProps {
	options: MoreOptionsMenuOptionData[]
	iconSize?: "small" | "medium"
	enableBackground?: boolean
	closeOnClick?: boolean
}

const MoreOptionsMenu = (props: MoreOptionsMenuProps) => {
	const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const moreOptionsMenuIsOpen = Boolean(moreOptionsMenuAnchorEl)

	const { options, iconSize, enableBackground, closeOnClick } = props

	const classes = useStyles()

	const handleMoreOptionsMenuClose = () => {
		setMoreOptionsMenuAnchorEl(null)
	}

	const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreOptionsMenuAnchorEl(event.currentTarget)
	}

	const customStyleByColor = {
		danger: classes.danger
	}

	return <>
		<IconButton
			aria-label="more"
			aria-controls="long-menu"
			aria-haspopup="true"
			size={iconSize || "medium"}
			className={classes.iconButton}
			style={enableBackground ? {
				borderRadius: 3,
				backgroundColor: colors.unrelated["4950570D"]
			} : undefined}
			onClick={handleMoreOptionsMenuClick}
		>
			<MoreVertIcon />
		</IconButton>
		<Popover
			open={moreOptionsMenuIsOpen}
			anchorEl={moreOptionsMenuAnchorEl}
			onClose={handleMoreOptionsMenuClose}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
		>
			<List>
				{options.map((option, index) => (
					<Grid key={`${index}-${new Date()}`} justifyContent="center" alignItems="center" style={{ display: "flex" }}>
						<DisableComponentByChannel
							variant="with-icon"
							otherRulesToDisabled={option.title === "Configurações" || option.title === "Exportar Fluxo"}
						>
							<ListItem
								button
								onClick={() => {
									if (closeOnClick) {
										handleMoreOptionsMenuClose()
									}
									option?.onClick?.()
								}}
								key={option.title}
							>
								{
									option.showIcon && <ListItemIcon
										style={{ minWidth: "30px" }}
										className={option.color && customStyleByColor[option.color]}>
										{option.icon}
									</ListItemIcon>
								}
								<ListItemText
									primary={option.title}
									classes={{
										primary: option.color && customStyleByColor[option.color]
									}}
								/>
							</ListItem>
						</DisableComponentByChannel>
					</Grid>
				))}
			</List>
		</Popover>
	</>
}

export default MoreOptionsMenu
