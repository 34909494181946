import React, { useEffect, useState } from "react"
import {
	ListItem,
	ListItemAvatar,
	Badge,
	Grid,
	ListItemText,
	Typography,
	Avatar,
	Box,
	Popover,
	List,
	Fab,
	Divider as MaterialDivider,
	Tooltip,
	ListItemSecondaryAction
} from "@material-ui/core"
import {
	PersonOutline as AttendantIcon,
	ExpandMore as ChatOptionsIcon,
	Snooze as SnoozeIcon,
	ShoppingCart
} from "@material-ui/icons"

import { Divider, PopConfirm } from "@/components"

import {
	isSameDate,
	SECOND_IN_MS,
	formatDateInHours,
	formatDateInBrazilianDate
} from "@/utils/time"
import { channelInfo } from "@/utils/channel"
import { keepOnlyDigits } from "@/utils/phone"
import { convertRGBAObjectToString } from "@/utils/color"
import { getChatMessageSketch } from "@/utils/chat"
import { getPhoneNumber } from "@/utils/contact"

import { IMessage } from "@/protocols/channel"

import MessageContentPlaceholder from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageContentPlaceholder"
import CounterChip from "@/pages/Attendance/Chat/ChatListPanel/CounterChip"
import MessageStatusIcon from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageStatusIcon"
import MessageDeletedContent from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageDeletedContent"
import ChatAttendanceStatusChip from "@/pages/Attendance/Chat/ChatListPanel/ChatAttendanceStatusChip"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"
import colors from "@/styles/colors"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatList/ChatListItem/styles"
import useCustomStyles from "@/styles/custom"
import WABAChatCommunicationPermissionTooltip from "@/@integrations/WABA/components/Chat/WABAChatCommunicationPermissionTooltip"

type ChatListItemProps = {
	chatId: number
	onClick: () => void
	showChatAttendanceStatus?: boolean
}

type IconsStatusChatListItem = "snooze" | "unreadMessages" | "attendanceStatus"

const ChatListItem = (props: ChatListItemProps) => {
	const {
		chatId,
		onClick,
		showChatAttendanceStatus
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const socket = useSocket()

	const chat = chatGlobalStateStore.chat.getById(chatId)
	const client = chatGlobalStateStore.client.getById(chat.client.id)
	const clientTags = client?.tagIds?.map(tagId => chatGlobalStateStore.tag.getById(tagId))?.filter(tag => tag) || []

	const active = chat.id === chatGlobalStateStore.chat.currentOpenedId
	const title = client?.nickname
	const titleName = client?.name
	const clientPhoneNumber = getPhoneNumber(client)
	const unreadMessagesCount = chat.unreadMessagesCount
	const markedUnread = !!chat.consolidatedData.markedUnread
	const channelType = chat.channelType
	const pictureUrl = client?.picture_url
	const lastMessage = chat.lastMessage as IMessage
	const attendantName = chat.attendance?.userName
	const chatStatus = chat.status
	const isAwakeningSnooze = chat.extraData?.awakeningSnooze

	const isWabaChatChannel = channelType === "waba"

	const [chatOptionsRef, setChatOptionsRef] = useState<HTMLElement | null>(null)
	const [optionsIconVisible, setOptionsIconVisible] = useState<boolean>(false)
	const [showMessageSketch, setShowMessageSketch] = useState<boolean>(false)

	const handleShowOptionsIcon = () => {
		setOptionsIconVisible(true)
	}

	const handleHideOptionsIcon = () => {
		setOptionsIconVisible(false)
	}

	const handleOpenChatOptionsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setChatOptionsRef(event.currentTarget)
	}

	const handleCloseChatOptionsPopover = () => {
		setChatOptionsRef(null)
		handleHideOptionsIcon()
	}

	const formatChatDate = (createdAt: Date) => {
		const createdAtDate = new Date(createdAt || "")
		const todayDate = new Date()

		const isTodayCreatedAt = isSameDate(createdAtDate, todayDate)

		if (isTodayCreatedAt) {
			return formatDateInHours(createdAtDate)
		} else {
			return formatDateInBrazilianDate(createdAtDate)
		}
	}

	const handleShowMessageSketch = () => {
		const messageSketch = getChatMessageSketch(chatId)
		const isCurrentChat = chat.id === chatGlobalStateStore.chat.current?.id

		const isShowMessageSketch = !!messageSketch && !isCurrentChat

		setShowMessageSketch(isShowMessageSketch)
	}

	const getChatDescription = () => {
		if (!lastMessage) {
			return "..."
		}

		if (lastMessage.extraData?.catalogueOrderMessage) {
			return <>
				<ShoppingCart />
				<Typography
					className={`${classes.chatDescription} ${customClasses.inlineText}`}
					{...({
						variant: "caption",
						color: "textPrimary"
					})}>
					Itens do catálogo
				</Typography>
			</>
		}
		const messageType = lastMessage.type

		const isNotification = messageType?.includes("notification")
		const isRawText = messageType === "text"
		const isCustomLink = messageType === "custom-link"
		const isCiphertext = messageType === "ciphertext"
		const wasMessageSentByCustomer = lastMessage.sentByCustomer

		if (isCiphertext) {
			return <>
				<Typography
					className={`${classes.chatDescription} ${customClasses.inlineText}`}
					{...({
						variant: "caption",
						color: "textPrimary"
					})}>
					Aguardando essa mensagem.
					Isso pode demorar um pouco
				</Typography>
			</>
		}

		if (lastMessage.deletedAt) {
			return (
				<MessageDeletedContent
					sentByCustomer={lastMessage.sentByCustomer}
				/>
			)
		}

		return (
			<>
				{showMessageSketch ? (
					<>
						<Box
							component={Typography}
							title={getChatMessageSketch(chatId) as string}
							className={`${classes.chatSketch} ${customClasses.inlineText}`}
							{...({
								variant: "caption",
								color: "textPrimary"
							})}
						>
							Rascunho: <span className={classes.chatDescription}>{getChatMessageSketch(chatId)}</span>
						</Box>
					</>
				) : (
					<>
						{wasMessageSentByCustomer && (
							<>
								<MessageStatusIcon
									className={classes.messageStatus}
									status={lastMessage.status}
								/>

								<Divider orientation="vertical" size={0.5} />
							</>
						)}

						{(isNotification || isRawText || isCustomLink) ? (
							<Box
								component={Typography}
								title={lastMessage?.content}
								className={`${classes.chatDescription} ${customClasses.inlineText}`}
								{...({
									variant: "caption",
									color: "textPrimary"
								})}
							>
								{lastMessage?.content}
							</Box>
						) : (
							<MessageContentPlaceholder
								type={messageType}
								caption={lastMessage?.caption}
								className={classes.chatDescriptionContainer}
								extraData={lastMessage?.extraData}
								content={lastMessage?.content}
							/>
						)}
					</>
				)}
			</>
		)
	}

	const setChatMarkedUnread = async (chatId: number, unread: boolean) => {
		chatGlobalStateStore.chat.updateById(chatId, {
			consolidatedData: {
				markedUnread: unread
			}
		})
	}

	const handleSetChatUnread = async () => {
		setChatMarkedUnread(chatId, true)

		try {
			await socket.setChatUnread({
				inboxChannelChatId: chatId,
				channelType
			})
		} catch (error) {
			setChatMarkedUnread(chatId, false)
		}

		handleCloseChatOptionsPopover()
		handleHideOptionsIcon()
	}

	const handleFinishAttendance = async () => {
		await chatGlobalStateStore.attendance.finishByChatId(chat.id)
	}

	const deleteChat = async () => {
		await chatGlobalStateStore.chat.deleteById(chat.id)
	}

	const handleDeleteChat = async () => {
		const props = {
			title: "Deseja remover chat?",
			confirmButtonText: "REMOVER",
			onConfirm: () => deleteChat()
		}
		PopConfirm.open(props)
	}

	const counterChipColor = (chatStatus === "on-going" || chatStatus === "snooze" || markedUnread) ? (
		colors.palette.activeAttendance
	) : (
		colors.palette.inactiveAttendance
	)

	const showOptionsButton = !!optionsIconVisible
	const showTitleName = (keepOnlyDigits(title) === clientPhoneNumber) && (chat?.type !== "group")

	const renderChatStatusIcons = () => {
		const icons: Record<IconsStatusChatListItem, React.ReactElement> = {
			snooze: (
				<SnoozeIcon className={isAwakeningSnooze ? classes.snoozeAwakening : classes.snoozeDefined} fontSize="small" />

			),
			unreadMessages: (
				<CounterChip
					active={unreadMessagesCount > 0 || markedUnread}
					counter={unreadMessagesCount > 0 ? unreadMessagesCount : -1}
					backgroundColor={counterChipColor}
					fontColor={colors.grayScale[11]}
					size="medium"
				/>
			),
			attendanceStatus: (
				<ChatAttendanceStatusChip
					chatStatus={chatStatus}
					backgroundColor={colors.palette.inactiveAttendance}
					fontColor={colors.grayScale[11]}
					size={24}
				/>
			)
		}

		return (
			<Grid container justifyContent="flex-end" alignItems="center">

				{showChatAttendanceStatus && icons.attendanceStatus}

				{!showChatAttendanceStatus && (chatStatus === "snooze" || isAwakeningSnooze) ? icons.snooze : null}

				{(!showChatAttendanceStatus && unreadMessagesCount > 0) || markedUnread ? icons.unreadMessages : null}

			</Grid>
		)
	}

	useEffect(() => {
		handleShowMessageSketch()
	}, [chatGlobalStateStore.chat.current?.id])

	return useCustomMemo(() => (
		<Grid
			direction="column"
			className={classes.chatListItemContainer}
			container
		>
			<ListItem
				button
				onClick={onClick}
				className={`${active ? `${classes.selectedChatListItem}` : ""} ${classes.chatListItem}`}
				onMouseEnter={() => handleShowOptionsIcon()}
				onMouseLeave={() => handleHideOptionsIcon()}
			>
				<ListItemAvatar>
					<Badge
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						badgeContent={(
							<Grid
								container
								className={classes.badgeContainer}
							>
								{channelInfo[channelType]?.icon || null}
							</Grid>
						)}
					>
						<Avatar src={pictureUrl} />
					</Badge>
				</ListItemAvatar>

				<ListItemText
					primary={(
						<Grid container direction="row" alignItems="center">
							{
								isWabaChatChannel && (
									<Grid item style={{ marginRight: 4 }}>
										<WABAChatCommunicationPermissionTooltip status={chat.wabaChannelChatCommunicationPermission?.status ?? "paused"} scheduledPauseAt={chat.wabaChannelChatCommunicationPermission?.scheduledPauseAt} />
									</Grid>
								)
							}
							<Tooltip
								title={`${title}${showTitleName ? ` ~${titleName}` : ""}`}
								enterDelay={1 * SECOND_IN_MS}
							>
								<Typography
									variant="h2"
									color="textPrimary"
									className={classes.chatTitle}
								>
									{title}
									{
										showTitleName && (
											<Typography
												variant="caption"
												color="textPrimary"
												className={classes.chatTitleName}
											>
											~{titleName}
											</Typography>
										)
									}
									<Typography
										variant="caption"
										color="textPrimary"
										className={classes.chatTitleName}
									>
											~{titleName}
									</Typography>
								</Typography>
							</Tooltip>
						</Grid>
					)}
					secondary={(
						<Grid
							container
							direction="column"
						>
							<Grid
								container
								alignItems="center"
								className={classes.chatDescriptionContainer}
							>
								{getChatDescription()}
							</Grid>

							<Grid
								container
								alignItems="center"
							>
								<AttendantIcon
									className={classes.chatAttendantIcon}
								/>

								<Divider orientation="vertical" size={0.5} />

								<Tooltip
									title={attendantName || "Sem atendente"}
									enterDelay={1 * SECOND_IN_MS}
								>
									<Typography
										className={classes.chatAttendantName}
									>
										{attendantName || "Sem atendente"}
									</Typography>
								</Tooltip>

								<Divider orientation="vertical" size={0.5} />

								{clientTags.map(tag => (
									<>
										<Grid
											style={{
												backgroundColor: convertRGBAObjectToString(tag.color)
											}}
											className={classes.chatClientTag}
										/>

										<Divider orientation="vertical" size={0.2} />
									</>
								))}
							</Grid>
						</Grid>
					)}
				/>

				<ListItemSecondaryAction
					onMouseEnter={() => handleShowOptionsIcon()}
					onMouseLeave={() => handleHideOptionsIcon()}
				>
					<Grid
						container
						direction="column"
						alignItems="flex-end"
						justifyContent="center"
						className={classes.chatInfoContainer}
					>
						<Typography
							variant="caption"
							className={classes.chatUnreadMessagesText}
							color="textPrimary"
						>
							{lastMessage ? (
								formatChatDate(new Date(lastMessage.createdAt))
							) : (
								""
							)}
						</Typography>

						{renderChatStatusIcons()}

						<Fab
							onClick={(event) => handleOpenChatOptionsPopover(event)}
							className={classes.chatOptionsButton}
						>
							{
								showOptionsButton && <ChatOptionsIcon />
							}
						</Fab>

						<Popover
							open={!!chatOptionsRef}
							anchorEl={chatOptionsRef}
							onClose={handleCloseChatOptionsPopover}
							anchorOrigin={{ vertical: "top", horizontal: "left" }}
							transformOrigin={{ vertical: "top", horizontal: "left" }}
						>
							<List>
								<ListItem
									button
									onClick={handleSetChatUnread}
								>
									<ListItemText primary="Marcar como não lido" />
								</ListItem>

								{chatStatus === "on-going" && (
									<ListItem
										button
										onClick={handleFinishAttendance}
									>
										<ListItemText primary="Finalizar atendimento" />
									</ListItem>
								)}

								<ListItem
									button
									onClick={handleDeleteChat}
								>
									<ListItemText primary="Remover chat" />
								</ListItem>
							</List>
						</Popover>
					</Grid>
				</ListItemSecondaryAction>
			</ListItem>

			<MaterialDivider variant="inset" component="li" />
		</Grid>
	), [
		active,
		unreadMessagesCount,
		JSON.stringify(lastMessage),
		title,
		lastMessage?.status,
		optionsIconVisible,
		chatOptionsRef,
		lastMessage?.deletedAt,
		pictureUrl,
		JSON.stringify(client?.tagIds),
		showMessageSketch,
		markedUnread
	])
}

export default ChatListItem
