import React, { useEffect } from "react"
import {
	Grid,
	IconButton
} from "@material-ui/core"

import { ActionDialog, Notification } from "@/components"
import { AccessAlarm as SnoozeIcon, Delete as TrashIcon } from "@material-ui/icons"
import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import { ErrorType } from "@/hooks/useValidation"
import { SchedulesInInboxBase } from "@/protocols/schedule"
import { Snooze, SnoozeTime } from "@/protocols/snooze"
import { defaultSnoozeTime, getSnoozeTime, handleInputSnoozeToMilliSecond, snoozeMenuOptions } from "@/utils/snooze"
import { saveSnooze } from "@/utils/snoozePersistence"
import { BuildedMessage } from "@/protocols/messages"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import { AttendanceStatus } from "@/protocols/channel"

import CreateNewSnoozeContent from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/CreateNewSnoozeContent"
import ScheduledSnoozeContent from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/ScheduledSnoozeContent"

import colors from "@/styles/colors"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/Snooze/SnoozeDialog/styles"

type SnoozeDialogProps = {
	handleCancelSnooze: () => void
}

const SnoozeDialog: React.FC<SnoozeDialogProps> = (props) => {
	const { handleCancelSnooze } = props
	const classes = useStyles()

	const [open, setOpen] = React.useState<boolean>(false)
	const [sendMessageOnWakeUp, setSendMessageOnWakeUp] = React.useState<boolean>(false)
	const [snoozeTime, setSnoozeTime] = React.useState<SnoozeTime>(defaultSnoozeTime)
	const [messages, setMessages] = React.useState<BuildedMessage[]>([])
	const [loading, setLoading] = React.useState<boolean>(false)
	const [snooze, setSnooze] = React.useState<Snooze>({} as Snooze)

	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()

	const inboxChannelChat = chatGlobalStateStore.chat.current
	const inboxChannelChatId = chatGlobalStateStore.chat?.current?.id as number

	const currentChatAttendanceStatus = inboxChannelChat?.attendance?.status as AttendanceStatus
	const isAttendanceStatusSnooze = inboxChannelChat?.status === "snooze"
	const isAttendanceStatusActive = currentChatAttendanceStatus === "active"
	const isScheduledSnooze = isAttendanceStatusActive && isAttendanceStatusSnooze
	const scheduleSnoozeId = chatGlobalStateStore.chat.current?.schedule?.snoozeId

	const instanceId = globalStateStore.instance.instance_id
	const userId = globalStateStore.user.id

	const saveButtonDisabled = sendMessageOnWakeUp && messages.length === 0

	const handleOpenDialog = () => {
		setOpen(true)
	}

	const handleCloseDialog = () => {
		setOpen(false)
	}

	const handleSetSnoozeTime = (data: SnoozeTime) => {
		setSnoozeTime(data)
	}

	const handleSetMessages = (data: BuildedMessage[]) => {
		setMessages(data)
	}

	const cancelSnooze = () => {
		handleCancelSnooze()
		handleCloseDialog()
	}

	const handleCreateSnooze = async () => {
		try {
			const snoozeTimeInMilliseconds = handleInputSnoozeToMilliSecond(snoozeTime.time * snoozeMenuOptions[snoozeTime.timeOption.type].timeInMinutes)

			const { data } = await ApiService.post("/snoozes", {
				inboxChannelChatId,
				content: { messages }
			})

			const result = await ApiService.post("/schedules/snooze/handle", {
				callback: {
					type: "webhook"
				},
				payload: {
					inboxChannelChatId,
					snoozeId: data.snooze.id,
					userId
				},
				time: String(snoozeTimeInMilliseconds),
				timeType: "date-by-now-in-milliseconds"
			})

			const newSchedule = result.data as SchedulesInInboxBase

			await chatGlobalStateStore.chat.updateById(inboxChannelChatId, {
				status: "snooze",
				schedule: {
					id: newSchedule.id,
					time: new Date(newSchedule.time),
					snoozeId: data.snooze.id
				}
			})

			setMessages([])
			setSendMessageOnWakeUp(false)
			handleCloseDialog()
			Notification.success({ message: "Soneca definida com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar definir a soneca." })
			ErrorHandler.handle(error as ErrorType)
		}

		saveSnooze({
			userId,
			instanceId,
			snooze: {
				snoozeTimeOption: snoozeTime.timeOption.type,
				value: snoozeTime.time as number
			}
		})
	}

	const handleGetSnooze = async () => {
		setLoading(true)
		try {
			const { data } = await ApiService.get(`/snoozes/${scheduleSnoozeId}`)
			setSnooze(data.snooze)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			ErrorHandler.handle(error as ErrorType)
		}
	}

	useEffect(() => {
		const snoozeTime = getSnoozeTime(userId, instanceId)

		handleSetSnoozeTime(snoozeTime)

		if (scheduleSnoozeId) {
			handleGetSnooze()
		}
	}, [instanceId, scheduleSnoozeId])

	return (
		<>
			<IconButton
				onClick={handleOpenDialog}
			>
				<SnoozeIcon style={{ color: `${isAttendanceStatusSnooze ? colors.unrelated.F5A46B : ""}` }} />
			</IconButton>

			<ActionDialog
				onClose={handleCloseDialog}
				onSave={isScheduledSnooze ? cancelSnooze : handleCreateSnooze}
				openDialog={open}
				title={isScheduledSnooze ? "Soneca programado para:" : "Novo soneca"}
				saveText={isScheduledSnooze ? "CANCELAR SONECA" : "DEFINIR SONECA"}
				saveButtonIcon={isScheduledSnooze ? <TrashIcon /> : undefined}
				saveButtonProps={{
					className: isScheduledSnooze ? classes.deleteSnoozeButton : ""
				}}
				hideCloseButton={isScheduledSnooze}
				saveButtonDisabled={saveButtonDisabled}
				loading={loading}
				maxWidth="sm"
			>
				<Grid container spacing={2} style={{ minWidth: "400px" }}>
					{isAttendanceStatusActive && !isAttendanceStatusSnooze
						? <CreateNewSnoozeContent
							messages={messages}
							handleSetMessages={handleSetMessages}
							sendMessageOnWakeUp={sendMessageOnWakeUp}
							handleSendMessageOnWakeUp={setSendMessageOnWakeUp}
							snoozeTime={snoozeTime}
							handleSetSnoozeTime={handleSetSnoozeTime}
						/>
						: <ScheduledSnoozeContent snooze={snooze} loading={loading} />
					}
				</Grid>
			</ActionDialog>
		</>
	)
}

export default SnoozeDialog
