import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { ActionDialog, Notification } from "@/components"

import {
	Grid,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	MenuItem
} from "@material-ui/core"

import { ChatBotFlowTriggerType } from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import FlowPreview from "@/pages/Admin/Flow/FlowConstructor/FlowPreview"

import { ErrorType } from "@/hooks/useValidation"

import useStyles from "@/pages/Admin/Flow/ChatBotCreation/ChatBotTemplate/styles"

type ChatBotTemplate = {
	id: number
	name: string
	category?: ChatBotFlowTriggerType | "no-triggers"
	webhook_template?: {
		type: WebhookTemplateType | "custom",
		display_title?: string
	}
}

type ChatBotTemplateProps = {
	children?: React.ReactNode,
	isDisabled?: boolean
}

const ChatBotTemplate: React.FC<ChatBotTemplateProps> = (props) => {
	const {
		children,
		isDisabled
	} = props

	const classes = useStyles()
	const history = useHistory()

	const [opened, setOpened] = useState(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [templates, setTemplates] = useState<ChatBotTemplate[]>([])
	const [currentTemplateOption, setCurrentTemplateOption] = useState<ChatBotTemplate>({} as ChatBotTemplate)

	const handleClose = () => {
		setOpened(false)
	}

	const handleChangeCurrentTemplateOption = (templateId: number) => {
		const updateCurrentTemplateOption = templates.find(template => template.id === templateId)
		setCurrentTemplateOption(updateCurrentTemplateOption as ChatBotTemplate)
	}

	const getTemplates = async () => {
		setLoading(true)
		try {
			const result = await ApiService.get("/chat-bot/templates", {
				params: {
					type: "flow"
				}
			})
			setTemplates(result.data.chatBotFlows)
			setCurrentTemplateOption(result.data.chatBotFlows[0])
		} catch (error) {
			Notification.error({ message: "Não foi possível listar os templates." })
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}

	const handleNewChatBotByTemplate = async (templateId: number) => {
		try {
			const result = await ApiService.post("/chat-bot/templates", {
				templateId
			})

			Notification.success({ message: "Bot criado com sucesso!" })
			history.push(`/admin/flow/${result.data.id}`)
		} catch (error) {
			Notification.error({ message: "Não foi possível criar o Bot a partir desse template." })
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handlePreviewChatBot = async (templateId: number) => {
		try {
			const result = await ApiService.get(`/chat-bot/templates/${templateId}`)
			FlowPreview.open({
				flowData: result.data.template,
				onSave: (templateId) => handleNewChatBotByTemplate(templateId)
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível visualizar o template." })
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const mountTemplateLabel = (text: string, index: number): string => {
		const templateName = text[0].toUpperCase() + text.substring(1)

		return `Template ${index + 1}: ${templateName}`
	}

	useEffect(() => {
		getTemplates()
	}, [opened])

	return (
		<Grid>
			<MenuItem
				disabled={isDisabled}
				onClick={() => {
					setOpened(true)
				}}
			>
				{ children }
			</MenuItem>
			<ActionDialog
				title="Criar o bot a partir de um template:"
				onClose={handleClose}
				openDialog={opened}
				fullWidth
				saveText="Usar esse template"
				onSave={() => handleNewChatBotByTemplate(currentTemplateOption.id)}
				loading={loading}
			>
				<Grid
					container
				>
					<Grid
						xs={12}
					>
						<Grid
							item
							xs
						>
							<RadioGroup
								value={currentTemplateOption.id}
								onChange={({ target }) => handleChangeCurrentTemplateOption(Number(target.value))}
							>
								{templates.map((template, index) => (
									<Grid
										key={index}
										container
										alignItems="center"
									>
										<FormControlLabel
											value={template.id}
											control={<Radio />}
											label={mountTemplateLabel(template.name, index)}
											classes={{
												label: classes.optionsText
											}}
										/>
										<Typography
											className={classes.previewText}
											onClick={() => handlePreviewChatBot(template.id)}
										>
											Visualizar
										</Typography>
									</Grid>
								))}
							</RadioGroup>

						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</Grid>
	)
}

export default ChatBotTemplate
