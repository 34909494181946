import Storage from "@/services/Storage"
import ErrorHandlerService from "@/services/ErrorHandler"
import { SaveSnooze } from "@/protocols/snooze"
import { ErrorType } from "@/hooks/useValidation"

type GetSavedSnoozeInput = {
	userId: number
	instanceId: number
}

type SaveSnoozeInput = GetSavedSnoozeInput & {
	snooze: SaveSnooze
}

const mountDataSnoozeKey = (where: GetSavedSnoozeInput) => `snooze-input:user-id-${where.userId}:instance-${where.instanceId}`

export const getSavedSnoozeInput = (input: GetSavedSnoozeInput): SaveSnooze | null => {
	try {
		const snoozeKey = mountDataSnoozeKey(input)

		const snooze = Storage.get<SaveSnooze>(snoozeKey)

		if (!snooze) {
			return null
		}

		return snooze
	} catch (error) {
		ErrorHandlerService.handle(error as ErrorType)
		return null
	}
}

export const saveSnooze = (input: SaveSnoozeInput): void => {
	try {
		const {
			snooze
		} = input

		const snoozeKey = mountDataSnoozeKey(input)

		Storage.set<SaveSnooze>(snoozeKey, {
			snoozeTimeOption: snooze.snoozeTimeOption,
			value: snooze.value
		})
	} catch (error) {
		ErrorHandlerService.handle(error as ErrorType)
	}
}
