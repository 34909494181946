import ApiService from "@/services/Api"
import {
	CreatePlanAddonsInAccountInput,
	CreatePlanAddonsInAccountResponse,
	PlanAddons,
	UpsellQuantityType
} from "@/protocols/planAddons"
import { InboxLimits } from "@/protocols/instance"

class AccountService {
	async createPlanAddonsInAccount (
		data: CreatePlanAddonsInAccountInput
	): Promise<{planAddonInAccountCreated: CreatePlanAddonsInAccountResponse}> {
		const response = await ApiService.post("/account/plan-addons-in-account", {
			...data
		})

		return response.data
	}

	async retrievelAllPlanAddonsByUpsellType<Type extends keyof InboxLimits> (upsellType: UpsellQuantityType, limitsType: Type): Promise<PlanAddons<Type>> {
		const response = await ApiService.get("/account/plan-addons", {
			params: {
				upsellType,
				limitsType
			}
		})

		return response.data.planAddons
	}
}

export default new AccountService()
